import axios from 'axios';

const getPlates = async (id) => {
  try {
    const request = await axios.get(`/pmng/parkings/${id}/whitelist`);
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const addPlate = async (id, plate) => {
  try {
    const request = await axios.post(`/pmng/parkings/${id}/whitelist`, {
      licensePlate: plate
    });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const removePlate = async (id, plate) => {
  try {
    const request = await axios.delete(`/pmng/parkings/${id}/whitelist`, {
      data: { licensePlate: plate }
    });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

export {
  getPlates,
  addPlate,
  removePlate
};
