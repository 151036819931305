<template>
  <button
    :class="[
      'refresh',
      { 'refresh--loading': loading }
    ]"
    @click="onClick"
  >
    <template v-if="time">
      <!-- Last refresh at {{ time.display }} -->
      {{ $t('components.refresh', { time: time.display }) }}
    </template>
    <Sprite
      :type="'refresh'"
      :width="20"
      :height="20"
    />
  </button>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import moment from 'moment-timezone';

export default {
  components: {
    Sprite
  },
  data() {
    return {
      interval: 600000,
      // interval: 10000,
      timeout: null
    }
  },
  computed: {
    time() {
      const { updated } = this.$store.state.dashboard;
      if (!updated) return null;
      const date = moment(updated);
      return {
        value: updated,
        display: date.format('H:mma')
      };
    },
    loading() {
      return this.$store.state.dashboard.requests !== 0;
    }
  },
  watch: {
    time(newVal, oldVal) {
      if (newVal) this.setTimer();
    }
  },
  beforeUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
    this.$store.commit('dashboard/updateUpdated', null);
  },
  methods: {
    onClick() {
      // const id = this.$store.state.parkings.selected;
      const { isIntegrated, selected } = this.$store.state.parkings;
      const type = isIntegrated ? 'Integrated' : 'Stack';
      this.$store.dispatch(`dashboard/set${type}`, { ParkingId: selected });

      // this.$store.dispatch('dashboard/setStack', { ParkingId: id });
    },
    setTimer() {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.onClick();
      }, this.interval);
    }
  }
}
</script>

<style lang="scss" scoped>
.refresh {
  display: flex;
  align-items: center;
  transition: color .3s $ease-out-quart;
  font-family: $secondary;
  font-size: rem(16px);
  cursor: pointer;

  &:not(.refresh--loading):hover {
    color: $mediumgreen;
  }

  &--loading {
    pointer-events: none;

    @keyframes loading {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    .svg {
      animation: loading 1s infinite linear;
    }
  }

  .svg {
    margin-left: 8px;
    fill: $mediumgreen;
  }
}
</style>