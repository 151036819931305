<template>
  <popup
    v-if="popup.visible"
    :classes="'popup-detail'"
  >
    <template v-slot:content>
      <template v-if="popup.booking">
        <table class="popup-table">
          <tbody>
            <tr
              v-for="row in table"
              :key="`payPopup-${row.property}`"
            >
              <td>{{ row.label }}</td>
              <td>{{ row.property(popup.booking) ? row.property(popup.booking) : '-' }}</td>
            </tr>
          </tbody>
        </table>

        <div class="popup-actions popup-actions--center">
          <button
            class="button"
            @click="close"
          >
            {{ $t('globals.close') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';

export default {
  name: 'Detail',
  components: {
    Loader,
    Popup
  },
  computed: {
     table() {
       return [
        {
          label: `${this.$t('bookings.popups.detail.nickname')} :`,
          property: (booking) => booking.driverName
        },
        {
          label: `${this.$t('bookings.popups.detail.phone')} :`,
          property: (booking) => booking.route?.driver?.user?.phoneNumber
        },
        {
          label: `${this.$t('bookings.popups.detail.fleet_phone')} :`,
          property: (booking) => {
            const fleet = booking?.route?.fleet;
            if (!fleet || !fleet.mobile) return null;
            return fleet.mobile;
          }
        },
        {
          label: `${this.$t('bookings.popups.detail.fleet_firm')} :`,
          property: (booking) => {
            const fleet = booking?.route?.fleet;
            if (!fleet || !fleet.name) return null;
            return fleet.name;
          }
        },
        {
          label: `${this.$t('bookings.popups.detail.language')} :`,
          property: (booking) => {
            const user = booking.route?.driver?.user;
            if (!user || !user.locale) return null;
            return user.locale.toUpperCase();
          }
        },
        {
          label: `${this.$t('bookings.popups.detail.truck_type')} :`,
          property: (booking) => {
            const slot = booking?.package?.slot;
            if (!slot || !slot.translation) return null;
            return slot.translation.name;
          }
        },
      ]
    },
    popup() {
      return this.$store.state.popups.detail;
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/updatePopup', {
        type: 'detail', property: 'visible', value: false
      });
      this.$store.commit('popups/updatePopup', {
        type: 'detail', property: 'booking', value: {}
      });
    }
  }
}
</script>