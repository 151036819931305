<template>
  <!-- <aside
    class="notifications"
  > -->
  <aside 
    v-if="notifications"
    class="modal"
    :class="isVisible ? 'modal--visible' : ''"
  >
    <div class="modal-container">
      <h1 class="modal__title">{{ $t(`notifications.modal.title.${notifications.type}`) }}!</h1>
      <Sprite
          :type="'notification-'+notifications.type"
          :width="141"
          :height="140"
        />
      <span class="small modal__text" v-html="notifications.text" />
      <button
        v-if="!notifications.signout"
        type="button"
        class="button modal__button"
        @click="close(notifications)"
      >
        {{ $t('globals.ok_maj') }}
      </button>
      <button
        v-else
        type="button"
        class="button modal__button"
        @click="signOut(notifications)"
      >
        {{ $t('globals.signout') }}
      </button>
    </div>
  </aside>
</template>

<script>
import { Auth } from 'aws-amplify';
import Sprite from '@/components/elements/Sprite.vue';

export default {
  components: {
    Sprite
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.items[0];
    },
    isVisible() {
      return this.$store.state.notifications.isVisible;
    }
  },
  methods: {
    close(item) {
      this.$store.commit('notifications/remove', item);
    },
    async signOut(item) {
      const requestAuthentification = Auth.signOut()
      requestAuthentification.then(promise => {
        this.$store.commit('updateAuthState', null);
        this.$router.push('/');
        // this.$store.commit('notifications/remove', item);
        this.$store.commit('notifications/reset');
        this.$store.commit('settings/updateContext', null);

         setTimeout(() => {
          window.location.reload();
        }, 50);
        // window.location.reload();
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.modal__title {
  font-family: $primary;
  font-size:rem(34px);
  font-weight:700;
  text-transform: capitalize;
  letter-spacing: 0;
  color: $snap-primary;
  margin-bottom: 30px;
}
.modal__text {
  margin: 30px 0;
  color: $grey;
}
.modal__button {
  padding: 20px !important;
  width: 100% !important;
}
</style>
