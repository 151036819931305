<template>
  <header class="header">
    <div class="header--left">
      <Navigation />
      <Sprite 
        :type="'logo'"
        :width="101"
        :height="49"
      />

      <div class="header-title">
        <h1 class="title">{{ $t('globals.title') }}</h1>
        <h2
          v-if="company && company.name"
          class="subtitle"
        >
          {{ company.name }}
          <button
            v-if="context && context.isAdmin"
            class="button"
            @click="openCompanies"
          >
            {{ $t('globals.change') }}
          </button>
        </h2>
      </div>
    </div>
    <Topbar />
    <!-- <div class="header--right">
      <Tabs />
    </div> -->
    <!-- <Subbar v-if="$route.meta.parent === 'parking'" /> -->
  </header>
</template>

<script>
/* eslint-disable */
import Topbar from '@/components/partials/Topbar.vue';
// import Tabs from '@/components/partials/Tabs.vue';
import Subbar from '@/components/partials/Subbar.vue';
import Navigation from '@/components/modules/Header/Navigation.vue';

import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: 'Header',
  components: {
    Topbar,
    // Tabs,
    Subbar,
    Navigation,
    Sprite,
  },
  computed: {
    context() {
      return this.$store.state.settings.context;
    },
    company() {
      return this.$store.state.user.company;
    },
  },
  methods: {
    openCompanies() {
      this.$store.commit('companies/updateProperty', {
        property: 'visible', value: true
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  grid-area: header;

  // background-color: red;
  background-color: $snap-appbar-background;;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  box-shadow: $snap-elevation-4;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  /* Left */
  &--left {
    /* width: 250px; */
    display: flex;
    padding: 20px 32px;

    .svg {
      margin-left: 30px;
    }
  }

  /* Right */
  .topbar {
    /* width: 100%; */
    height: 100%;
    flex: 2;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }

  &-title {
    margin-left: 24px;
    margin-top: -4px;

    .title,
    .subtitle {
      color: $white;
      font-family: $primary;
      font-weight: $medium;
      font-size: rem(24px);
      line-height: 110%;
      letter-spacing: 0;
    }

    .title {
      text-transform: uppercase;
    }

    .subtitle {
      margin-top: 3px;
      font-size: rem(20px);
      text-transform: none;

      .button {
        display: inline-block;
        width: auto;
        padding: 6px 12px 4px;
        margin: 0;
        margin-left: 10px;
        font-size: rem(14px);
      }
    }
  }
}
</style>