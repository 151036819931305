// import { useI18n } from 'vue-i18n';
import i18n from '../i18n';
import router from '../router';
import moment from 'moment';

import hours from '@/data/hours.json';

import {
  getSubscription,
  postSubscription,
  patchSubscription
} from '@/calls/subscriptions';

const parkings = {
  namespaced: true,
  state: {
    id: null,
    data: null,
    form: {
      name: null,
      startAt: null,
      endAt: null,
      maxVacancy: null,
      status: null,
      licensePlateList: [],
      weekDays: [],
      startTimeAt: '00:00:00',
      endTimeAt: '23:45:00',
    },
    requests: 0
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateData(state, data) {
      state.data = data;
    },
    updateForm(state, form) {
      state.form = form;
    },
    resetForm(state) {
      state.form = {
        name: null,
        startAt: moment().format('YYYY[-]MM[-]DD'),
        endAt: moment().add(50, 'years').format('YYYY[-]MM[-]DD'),
        maxVacancy: 0,
        status: false,
        licensePlateList: [],
        weekDays: [],
        startTimeAt: '00:00:00',
        endTimeAt: '23:45:00',
      };
    },
    updateFormData(state, { field, value }) {
      state.form[field] = value;
    },
    removePlate(state, plate) {
      state.form.licensePlateList = state.form.licensePlateList.filter(p => p !== plate);
    },
    removePlates(state) {
      state.form.licensePlateList = [];
    },
    addPlates(state, plates) {
      state.form.licensePlateList = [
        ...state.form.licensePlateList,
        ...plates
      ];
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
  },
  actions: {
    updateFormFromData({ state, commit }) {
      const datas = [
        'name',
        'startAt',
        'endAt',
        'status',
        'weekDays',
        'startTimeAt',
        'endTimeAt',
        'maxVacancy',
        'licensePlateList'
      ];

      for (let i = 0; i < datas.length; i++) {
        const data = datas[i];
        switch (data) {
          case 'endAt':
          case 'startAt':
            const value = moment(state.data[data]).format('YYYY[-]MM[-]DD');
            commit('updateFormData', { field: data, value });
            break;
          case 'endTimeAt':
          case 'startTimeAt':
            const reg = /^\d{2}\:\d{2}/;
            const v = parseInt(state.data[data].match(reg)[0].replace(':', ''));
            const hrs = hours.map(h => Number(h.replace(':', '')));
            const closest = hrs.reduce((prev, curr) => (Math.abs(curr - v) < Math.abs(prev - v) ? curr : prev));
            const closestString = `${closest < 1000 ? 0 : ''}${closest}`;
            const valueString = closestString.slice(0, 2) + ':' + closestString.slice(2) + ':00';
            commit('updateFormData', { field: data, value: valueString });
            break;
          case 'weekDays':
            const values = {
              monday: 1,
              tuesday: 2,
              wednesday: 3,
              thursday: 4,
              friday: 5,
              saturday: 6,
              sunday: 0,
            };
            const days = [];
            for (let i = 0; i < state.data[data].length; i++) {
              const d = state.data[data][i];
              days.push(values[d.toLowerCase()]);
            }
            commit('updateFormData', { field: data, value: days });
            break;
          case 'status':
            commit('updateFormData', { field: data, value: state.data[data] === 'Active' });
            break;
          default:
            commit('updateFormData', { field: data, value: state.data[data] });
            break;
        }
      }
    },
    async getSubscription({ commit, dispatch, state }) {
      commit('increaseRequests');

      const { id } = state;
      const request = await getSubscription(id);

      if (!request.hasError) {
        commit('updateData', request);
      }

      dispatch('updateFormFromData');
      commit('decreaseRequests');
    },
    async createSubscription({ commit, dispatch, state, rootState }) {
      commit('increaseRequests');

      const params = {
        ...state.form,
        parkingId: rootState.parkings.selected,
        status: state.form.status ? 'Active' : 'Undefined'
      };

      const request = await postSubscription(params);
      const { t } = i18n.global;

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.subscriptions.create'),
          type: 'success'
        }], { root: true });
        // dispatch('getSubscription');
        router.push({ name: 'EditSubscription', params: { id: request.id } });
      } else {
        const error = request.error?.response?.data?.length ? request.error.response.data.join(', ') : request.error;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.subscriptions.create', { message: error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
      return true;
    },
    async updateSubscription({ commit, dispatch, state }) {
      commit('increaseRequests');

      const params = {
        ...state.form,
        status: state.form.status ? 'Active' : 'Undefined'
      };

      const request = await patchSubscription(state.id, params);
      const { t } = i18n.global;

      if (!request.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.subscriptions.update'),
          type: 'success'
        }], { root: true });
        dispatch('getSubscription');
      } else {
        const error = request.error?.response?.data?.length ? request.error.response.data.join(', ') : request.error;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.subscriptions.update', { message : error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
      return true;
    },
    async removePlates() {

    }
  },
};

export default parkings;
