<template>
  <section class="two-columns">
    <div class="container">
      <div class="column" :key="index" v-for="(prop, index) in props">
        <h2 class="h4 column-title">{{prop.title}}</h2>
        <p class="small column-legend">{{prop.subtitle}}</p>
        <p class="column-content regular" v-html="prop.content" />

        <div class="column__footer">
          <!-- <a class="link" href="tel:{{prop.phone}}">
            <Sprite :type="'phone-2'" :width="11" :heigth="11" />
            {{prop.phone}}
          </a> -->
          <a class="link" :href="`mailto:${prop.mail}`">
            <Sprite :type="'email'"  :width="12" :heigth="9"/>
            {{prop.mail}}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
export default {
  components: {
    Sprite
  },
  props: ['props'],
}
</script>

<style lang="scss">
  .two-columns {
    // display: flex;
    margin: 44px 0;

    .container {
      display: flex;
      // flex-wrap: wrap;
    }
  }

  .column {
    width: 33.33%;
    padding: 0 24px;
    border-right: 1px solid $grey-background;
    border-left: 1px solid $grey-background;

    &:first-child {
      padding-left: 0;
      border: 0;
    }

    &:last-child {
      padding-right: 0;
      border: 0;
    }

    // width: 50%;
    // &:nth-child(odd) {
    //   padding-right: 90px;
    //   border-right: 1px solid $grey-background;
    // }

    // &:nth-child(even) {
    //   padding-left: 90px;
    // }
  }

  .column {
    color: $snap-primary;
    &-title {
      max-width: 345px;
      margin-bottom: 10px;
    }
    &-content {
      margin-top: 30px;
      color: $mediumgrey;
    }
    &__footer {
      margin-top: 40px
    }
  }
  .link {
    @extend %p;
    color: $snap-primary;

    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transition: color .3s $ease-out-quad;
    &:hover {
      color: $mediumgreen;
    }
    + .link {
      margin-left: 20px;
    }

    .svg {
      display: block;
      margin-right: 10px;
    }
  }
</style>
