<template>
  <div class="quicksearch">
    <input 
      ref="input"
      type="search" 
      :placeholder="placeholder()"
      @input="onInput"
      @search="onClear"
      v-bind:title="tooltip()" 
    >
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'QuickSearch',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    $route() {
      this.$refs.input.value = '';
    }
  },
  methods: {
    onInput(evt) {
      const { value } = evt.target;

      if(value !== '') {
        if (!this.$el.classList.contains('onInput')) {
          this.$el.classList.add('onInput');
        }
      } else {
        if (this.$el.classList.contains('onInput')) {
          this.$el.classList.remove('onInput');
        }
      }

      const { name } = this.$route;
      switch (name) {
        case ('LicenseChecker'):        
          
          break
        default:
          this.debouncedUpdate(value);
          break;
      }

      this.debouncedUpdate(value);
    },
    debouncedUpdate: _debounce(function(value) {
      this.$emit('search', value);
    }, 300),
    onClear() {
      const { name } = this.$route;
      switch (name) {
        case ('LicenseChecker'):
          this.$emit('search', this.$refs.input.value);   
          //this.$refs.input.value = '';
          
          break
        default:
          this.$refs.input.value = '';
          this.$emit('search', '');  
          break;
      }

      
    },
    pleaseClear() {
      this.$refs.input.value = '';
    },
    placeholder() {
      const { name } = this.$route;
      switch (name) {
        case ('LicenseChecker'):
          return this.$t('components.quicksearch.license_checker.placeholder');      
        default:
          return this.$t('components.quicksearch.placeholder');        
      }
    },
    tooltip() {
      const { name } = this.$route;
      switch (name) {
        case ('LicenseChecker'):
          return this.$t('components.quicksearch.license_checker.tooltip');      
        default:
          return ''        
      }
    }
  },
}
</script>
