<template>
  <div class="service-detail hide-scrollbar">
    <template v-if="!loading && service">
      <div v-if="service" class="service-detail-grid">
        <address class="service-detail-header">
          <h3 class="h4">{{ service.name }}</h3>
          <p>{{ service.address.street }} {{ service.address.zipCode }} {{ service.address.city }}, {{service.address.country.name}}</p>
          <p>{{ $t('labels.gps_coordinates') }} {{ service.address.geolocationLatitude }}, {{ service.address.geolocationLongitude }}</p>
        </address>

        <!-- Aside -->
        <div class="service-detail-aside">
          <!-- Informations -->
          <div
            v-if="infos.options.length > 0"
            class="service-detail-section infos"
          >
            <h4 class="service-detail-title">{{ $t('services.detail.titles.options') }}</h4>
            <ul>
              <li
                v-for="(info, index) in infos.options"
                :key="`option-${index}-${info.id}`"
              >
                <Sprite
                  :type="'check'"
                  :width="18"
                  :height="12"
                />
                {{ $t(`services.options.${info.id}`) }}
                <span class="price">{{ info.price }}€</span>
              </li>
            </ul>
          </div>

          <!-- Hours -->
          <div class="service-detail-section">
            <h4 class="service-detail-title">{{ $t('services.detail.titles.opening-hours') }}</h4>

            <div class="service-detail-section-content">
              <Hours :days="hours"/>
            </div>
          </div>
        </div>

        <!-- Content -->
        <div class="service-detail-content">
          <div class="service-detail-section">
            <h4 class="service-detail-title">{{ $t('services.detail.titles.vehicles') }}</h4>

            <!-- Vehicles -->
            <div class="service-detail-section-content">
              <Vehicles :vehicles="vehicles"/>
            </div>
          </div>
        </div>
      </div>

      <div class="service-detail-section">
        <h4 class="service-detail-title">{{ $t('services.detail.titles.map') }}</h4>
        <Map 
          v-if="service"
          :lat="service.latitude" 
          :lng="service.longitude" 
        />
      </div>
    </template>
    <div class="service-detail-loader" v-else>
      <Loader/>
    </div>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import Loader from '@/components/elements/Loader';
import Map from '@/components/modules/Detail/Map';
import Hours from '@/components/modules/Detail/Hours';
import Vehicles from '@/components/modules/Detail/Vehicles';

export default {
  name: 'ServiceDetail',
  components: {
    Sprite,
    Map,
    Loader, 
    Hours,
    Vehicles
    // Sprite,
  },
  props: {
    type: {
      type: String,
      required: true
    },
    service: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    vehicles() {
      if (!this.service) return [];
      const packages = this.service[this.type].packages;
      const vehicles = [];

      packages.map(p => {
        let index = -1;

        for (let i = 0; i < vehicles.length; i++) {
          const vehicle = vehicles[i];

          if (vehicle.id === p.vehicle) {
            index = i;
            break;
          }
        }

        if (index < 0) {
          vehicles.push({
            id: p.vehicle,
            type: this.type,
            packages: [p],
            // cleaning: p.cleaning,
            // product: p.product,
            // inside: p.inside,
            // outside: p.outside,
            // selfWash: p.selfWash,
            // disinfection: p.disinfection,
          });
        } else {
          vehicles[index].packages.push(p);
          // if (p.inside) vehicles[index].inside = true;
          // if (p.outside) vehicles[index].outside = true;
          // if (p.selfWash) vehicles[index].selfWash = true;
          // if (p.cleaning) vehicles[index].cleaning = true;
          // if (p.product) vehicles[index].product = true;
          // if (p.disinfection) vehicles[index].disinfection = true;
        }
      });

      return vehicles;
    },
    infos() {
      if (!this.service) return {};
      const packages = this.service[this.type].packages;
      const infos = {
        selfWash: false,
        disinfection: false,
        options: this.service[this.type].options
      };

      packages.map(p => {
        if (p.selfWash && !infos.selfWash) infos.selfWash = true;
        if (p.disinfection && !infos.disinfection) infos.disinfection = true;
      });

      return infos;
    },
    hours() {
      const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      const days = this.service.openingHours
        .map(d => {
          d.label = this.$t(`globals.days.${weekDays.indexOf(d.day) + 1}`);
          d.value = d.hours;
          d.open = true;
          return d;
        })
        .sort((a, b) => {
          if(weekDays.indexOf(a.day) < weekDays.indexOf(b.day)) return -1;
          if(weekDays.indexOf(a.day) > weekDays.indexOf(b.day)) return 1;
          return 0;
        });

      return days;
    }
  }
}
</script>

<style lang="scss" scoped>
.service-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  position: relative;
  /* padding: 32px; */
  overflow: auto;
  /* border-top: 1px solid $lightestgrey; */

  &-header {
    position: relative;
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $lightestgrey;
    /* max-width: 506px; */
    color: $grey;
    @extend %p;
    font-size: rem(16px);

    .h4 {
      font-size: rem(24px);
      line-height: 1.4;
    }

    p {
      margin-top: 10px;

      + p {
        margin-top: 0;
      }
    }
  }

  .infos {
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style: none;
      
      li {
        display: flex;
        width: 100%;
        align-items: flex-start;
        position: relative;
        padding-left: 24px;
        color: $snap-primary;
        font-size: rem(18px);
        font-family: $secondary;
        font-weight: 400;
        line-height: 1.3;

        &:not(:first-child) {
          margin-top: 10px;
        }

        .svg {
          position: absolute;
          top: 6px;
          left: 0;
        }

        .price {
          margin-left: auto;
        }
      }
    }
  }

  &-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    z-index: 12;
    width: 100%;
    height: 100%;
    background-color: rgba($white, .7);
  }
}
</style>

<style lang="scss">
.service-detail {
  // Grid
  .service-detail-grid {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
  }

  .service-detail-aside {
    width: 300px;
    margin-right: 30px;
    position: sticky;
    top: 0;
  }

  .service-detail-content {
    width: calc(100% - 330px);
    /* height: 4000px; */
  }

  // Section
  .service-detail-section {
    width: 100%;
    margin-bottom: 30px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .service-detail-title {
    color: $snap-primary;
    font-family: $secondary;
    font-size: rem(24px);
    font-style: normal;
    font-weight: 500;
    line-height: (26.4 / 24);

    &:not(:first-child) {
      margin-top: 30px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .service-detail-text {
    width: calc(100% - 45px);
    max-width: 1048px;
    // max-width: 715px;
    color: rgba($mediumgrey, .8);
    font-family: $secondary;
    font-size: rem(18px);
    font-weight: 500;
    line-height: (25.2 / 18);
  }
}
</style>