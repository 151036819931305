<template>
  <section
    ref="container"
    class="main-container"
    :class="{ 'no-booking': plates && plates.length === 0 , 'main-container--loading': loading }"
  >
    <!-- :class="tmpBooking && tmpBooking.length === 0 ? 'no-booking' : ''" -->
    <table class="table" cellspacing="0" v-if="plates && plates.length">
      <!-- TABLE HEAD -->
      <Thead :head="theadPlates" />
      
      <!-- TABLE BODY -->
      <tbody>
        <tr
          v-for="plate in plates"
          :key="plate.plate"
        >
          <TBody 
            v-for="(b, index) in tbodyPlates"
            :key="index"
            :body="b" 
            :el="plate" 
          />
        </tr>
      </tbody>
    </table>
    <!-- NO RESULTS -->
    <NoResult v-else>
      <slot>{{message}}</slot>
    </NoResult>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>

    <AddPlate @onSuccess="reload"/>
    <RemovePlate @onSuccess="reload"/>
  </section>
</template>

<script>
import moment from 'moment';
import { formatDate } from '@/helpers/helpers';
import NoResult from '@/components/partials/Bookings/NoResult';
import Thead from '@/components/elements/THead';
import TBody from '@/components/elements/TBody';
import Loader from '@/components/elements/Loader';
import AddPlate from '@/components/partials/Popups/AddPlate';
import RemovePlate from '@/components/partials/Popups/RemovePlate';

export default {
  name: 'List',
  components: {
    NoResult,
    Thead,
    TBody,
    Loader,
    AddPlate,
    RemovePlate
  },
  data() {
    return {
      tbodyPlates: [
        // Names
        {
          title: (plate) => plate.licensePlate,
          class: 'nickname'
        },
        // Arrival
        {
          title: (plate) => {
            const d = formatDate(plate.addedAt, this.timeZone);

            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
          }
        },
        // Action
        { 
          title: plate => '',
          buttons: plate => {
            const buttons = [
              {
                label: this.$t('globals.remove'),
                id: plate.licensePlate,
                // classes: 'button-border',
                action: (plate) => {
                  this.remove(plate);
                }
              }
            ];

            return buttons;
          },
          class: 'align-right no-break'
        },
      ]
    }
  },
  computed: {
    timeZone() {
      if (!this.$store.state.parkings?.current?.timeZoneId) return 'Europe/Brussels';
      return this.$store.state.parkings.current.timeZoneId;
    },
    plates() {
      return this.$store.state.plates.items;
    },
    loading() {
      return this.$store.state.plates.requests !== 0;
    },
    message() {
      return this.$t('parkings.plates.list.no_result');
    },
    theadPlates() {
      return [
        { title: this.$t('parkings.plates.list.thead.plate'), class: "small head" },
        { title: this.$t('parkings.plates.list.thead.date'), class: "small head" },
        { title: '', class: "small small-col head-hidden" },
      ]
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  mounted() {
  },
  methods: {
    remove(plate) {
      
      this.$store.commit('popups/updatePopup', { type: 'removePlate', property: 'success', value: false });
      this.$store.commit('popups/updatePopup', { type: 'removePlate', property: 'value', value: plate.licensePlate });
      this.$store.commit('popups/updatePopup', { type: 'removePlate', property: 'visible', value: true });
    },
    reload() {
      this.$store.dispatch('plates/getPlates', { id: this.$store.state.parkings.selected });
    }
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  .table {
    td {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
}
</style>
