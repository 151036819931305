import i18n from '../i18n';
import { getReporting, getServiceReporting, getBalanceReport } from '@/calls/accounting';

const accounting = {
  namespaced: true,
  state: {
    page: 0,
    requests: 0,
    data: null,
    download: null
  },
  mutations: {
    updateData(state, data) {
      state.data = data;
    },
    updatePage(state, page) {
      state.page = page;
    },
    updateDownload(state, download) {
      state.download = download;
    },
    toggleOpen(state, id) {
      state.data[id].open = !state.data[id].open;
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    }
  },
  actions: {
    // async getReporting(context) {
    async getReporting({ commit, dispatch }) {
      commit('increaseRequest');
      const { t } = i18n.global;
      const reporting = await getReporting();

      if (reporting.hasError) {
        const status = reporting.error?.response?.status;

        if (status && status === 401) {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.reporting.permission'),
            type: 'error'
          }], { root: true });
        } else {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.reporting.getting', { message: reporting.error }),
            type: 'error'
          }], { root: true });
        }
      } else {
        if (reporting && Object.keys(reporting).length === 0) {
          commit('updateData', null);
        } else {
          commit('updateData', reporting);
        }
      }

      setTimeout(() => {
        commit('decreaseRequest');
      }, 500);
    },
    async getServiceReporting({ commit, dispatch }, type) {
      commit('increaseRequest');
      const { t } = i18n.global;
      const reporting = await getServiceReporting(type);

      if (reporting.hasError) {
        const status = reporting.error?.response?.status;

        if (status && status === 401) {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.reporting.permission'),
            type: 'error'
          }], { root: true });
        } else {
          dispatch('notifications/addNotifs', [{
            text: t('notifications.errors.reporting.getting', { message: reporting.error }),
            type: 'error'
          }], { root: true });
        }
      } else {
        if (reporting && Object.keys(reporting).length === 0) {
          commit('updateData', null);
        } else {
          commit('updateData', reporting);
        }
      }

      setTimeout(() => {
        commit('decreaseRequest');
      }, 500);
    },
    async downloadReport({ commit, state, dispatch }, parkingId) {
      commit('increaseRequest');

      const response = await getBalanceReport(parkingId);

      if (!response.hasError) {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `pmng-activity-balance-report-${new Date().getTime()}.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        const { t } = i18n.global;
        
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.reporting.getting', { message: response.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequest');
    }
  }
};

export default accounting;
