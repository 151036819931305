import moment from 'moment-timezone';
import { getReservations } from '@/calls/services';

const tw = {
  namespaced: true,
  state: {
    selected: null,
    reservations: [],
    reservation: null,
    requests: {
      reservation: 0,
      reservations: 0,
    },
    filters: {
      search: null,
      dates: {
        start: moment().format("YYYY[-]MM[-]DD[T]00:00:00"),
        end: moment().format("YYYY[-]MM[-]DD[T]23:59:59"),
      }
    },
  },
  mutations: {
    updateSelected(state, selected) {
      state.selected = selected;
    },
    updateReservation(state, reservation) {
      state.reservation = reservation;
    },
    updateReservations(state, reservations) {
      state.reservations = reservations;
    },
    updateFilters(state, { property, value }) {
      state.filters[property] = value;
    },
    increaseRequests(state, type) {
      state.requests[type]++;
    },
    decreaseRequests(state, type) {
      state.requests[type]--;
    },
  },
  actions: {
    async getReservations({ commit, state }) {
      commit('increaseRequests', 'reservations');

      const { start, end } = state.filters.dates;
      const request = await getReservations({
        serviceDetail: state.selected,
        startAt: start,
        endAt: end,
        type: 1
      });

      if (!request.hasError) {
        const reservations = request.map(r => {
          try { r.description = JSON.parse(r.description); }
          catch (e) { r.description = null; }
          return r;
        });

        commit('updateReservations', reservations);
      }

      commit('decreaseRequests', 'reservations');
    }
  }
};

export default tw;
