<template>
  <div class="loader">
    <div class="loader-anim"></div>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  display: block;
  width: 60px;
  height: 60px;
  min-height: 60px;

  @keyframes loading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  &-anim {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid rgba($snap-primary-lighten, .3);
    border-top: 6px solid $snap-primary;
    animation: loading 1s infinite linear;
  }
}
</style>
