<template>
  <div class="vehicles">
    <div
      v-for="vehicle in formattedVehicles"
      :key="vehicle"
      :class="['vehicle', { 'selected': selected === vehicle }]"
    >
      <!-- @click.prevent="$emit('onChange', vehicle)" -->
      <div class="vehicle-icon">
        <VehiclesSprite
          :type="vehicle.id"
          :width="162"
          :height="50"
        />
      </div>

      <div class="vehicle-content">
        <span class="h4">{{ $t(`services.vehicles.${vehicle.id}`) }}</span>

        <ul class="vehicle-packages">
          <li
            v-for="(pack, index) in vehicle.packages"
            :key="`vehicle-package-${index}`"
            class="vehicle-package"
          >
            <span class="vehicle-package-label">{{ pack.options }}</span>
            <span class="vehicle-package-price">{{ pack.price }}€</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VehiclesSprite from '@/components/elements/VehiclesSprite';

export default {
  components: {
    VehiclesSprite
  },
  props: {
    vehicles: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: null
    }
  },
  computed: {
    formattedVehicles() {
      const vehicles = this.vehicles.map(v => {
        v.packages.map(p => {
          const options = [];

          if (v.type === 'tankCleaning') {
            options.push(this.$t(`services.packages.${p.cleaning.toLowerCase()}`));
            options.push(this.$t(`services.packages.${p.product.toLowerCase()}`));
          } else {
            if (p.inside && p.outside) {
              options.push(this.$t('services.packages.inside-outside'));
            } else {
              if (p.inside) options.push(this.$t('services.packages.inside'));
              if (p.outside) options.push(this.$t('services.packages.outside'));
            }
            if (p.selfWash) options.push(this.$t('services.packages.self-wash'));
            if (p.disinfection) options.push(this.$t('services.packages.disinfection-load-compartment'));
          }

          p.options = options.join(', ');
          return p;
        });

        v.packages = v.packages.sort((a, b) => {
          if(a.options.length < b.options.length) return -1;
          if(a.options.length > b.options.length) return 1;
          return 0;
        });

        return v;
      });

      return vehicles;
    }
  }
}
</script>

<style lang="scss" scoped>
.vehicles {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  width: 100%;

  .vehicle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px 12px;
    border: 1px solid $lightestgrey;
    transition: background-color .3s $ease-out-quart;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &-icon {
      display: flex;
      justify-content: center;
      width: 200px;
      margin-right: 24px;

      .svg {
        fill: $snap-primary;
      }
    }

    &-content {
      width: calc(100% - 224px);

      .h4 {
        display: block;
        margin-bottom: 12px;
        transition: color .4s $ease-out-quart;
      }
    }

    &-packages {
      list-style-type: none;
    }

    &-package {
      display: flex;
      justify-content: space-between;
      color: $snap-primary;
      font-size: rem(16px);
      font-family: $secondary;
      font-weight: 400;
      line-height: 1.2;

      &:not(:first-child) {
        margin-top: 5px;
      }

      &-price {
        flex-shrink: 0;
        margin-left: 20px;
      }
    }
  }
}
</style>