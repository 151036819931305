<template>
  <strong  class="h4"><span class="available">{{ total }}</span> {{ $tc('globals.available', total) }}</strong>
</template>

<script>
export default {
  computed: {
    total() {
      const parkingSlots = this.$store.state.parkings?.current?.parkingSlots;
      if (!parkingSlots || !parkingSlots.length) return 0;

      let availables = 0;
      parkingSlots.map(slot => {
        availables += slot.otraSlotNumber;
      });

      return availables;
    }
  }
}
</script>
