<template>
  <main class="authentification">
    <header class="authentification-header">
      <Sprite 
        :type="'logo'"
        :width="76"
        :height="72"
      />
      <h1 v-html="$t('authentication.title')" class="authentification__title"/>
    </header>
    <section class="authentification-container">
      <LostPassword v-if="this.$route.name === 'LostPassword'" />
      <ResetPassword v-else-if="this.$route.name === 'ResetPassword'" />
      <Login v-else />
    </section>
  </main>
</template>


<script>
import Login from '@/views/authentication/Login.vue';
import LostPassword from '@/views/authentication/LostPassword.vue';
import ResetPassword from '@/views/authentication/ResetPassword.vue';
import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: 'AuthStateApp',
  components: {
    Login,
    LostPassword,
    ResetPassword,
    Sprite
  },
  computed: {
    authState() {
      return this.$store.state.authentication.authState;
    },
    user() {
      return this.$store.state.authentication.tokens;
    }
  },
}
</script>

<style lang="scss">
  .authentification {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $darkgrey;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 100vh;
    overflow: auto;

    &-container {
      width: 380px;
      margin-bottom: auto;
    }

    &-header {
      width: 380px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      color: $white;
      margin-top: auto;
    }
  
    &-link {
      transition: color .3s $ease-out-quart;
      color: $grey;
      font-family: $secondary;
      font-size: rem(14px);
      line-height: 1;
      cursor: pointer;

      &:hover {
        color: $mediumgreen;
      }

      &--green {
        color: $mediumgreen;
      }

      &--blue {
        color: $snap-primary;
      }
    }

    &-registration {
      transition: color .3s $ease-out-quart;
      font-family: $secondary;
      font-size: rem(16px);
      color: $grey;
      cursor: pointer;

      &:hover {
        color: $mediumgreen;
      }
    }

    &-form {
      background: $white;
      border-radius: 3px;
      padding: 30px;
      box-shadow: $snap-elevation-2;

      text-align: center;
      color: $snap-text-primary;
    }

    &__title {
      font-size: rem(34px);
      letter-spacing: 0;
      text-transform: none;
    }
    &__titleForm {
      margin-bottom: 30px;
    }

    &-footer {
      margin-top: 16px;
      display: flex; 
      justify-content: center;
      align-items: center;

      &__link {
        transition: color .3s $ease-out-quart;
        color: $mediumgrey;
        font-family: $secondary;
        font-size: rem(12px);
        line-height: 1;
        cursor: pointer;

        &:hover {
          color: $mediumgreen;
        }
      }

      a + a:before {
        content:'';
        width:3px;
        height:3px;
        border-radius: 50%;
        display: inline-block;
        background: $grey;
        margin: 0 20px;
        margin-bottom: 2px;
      }
    }

    // .fieldset-input__container {
    //   margin-bottom: 15px;
    // }

    .fieldset-input__container {
      .svg-eye-of-tiger {
        top: 23px;
        bottom: auto;
      }
    }

    .input {
      border: 1px solid $lightestgrey;
      border-radius: 3px;

      padding: 15px 16px;

      width: 100%;
      transition: border .3s $ease-out-quart;

      @extend %p;
      font-size: rem(18px);
      color: $snap-primary;
      
      &::placeholder {
        color: $mediumgrey;
      }
      .error & {
        border: 1px solid $error !important;
      }
    }

    .button {
      width: 100% !important;
      font-size: rem(16px);
      margin-top: 30px;
      margin-bottom: 30px;
      text-transform: uppercase;
    }
  }

  .lost-password {
    margin-top: 10px;
    display: block;
    text-align: center;
  }

  .button {
    width: 100%;
    font-size: rem(16px);
    margin-top: 30px;
    margin-bottom: 30px;
    text-transform: uppercase;
    padding: 15px 30px;
  }
</style>
