// import { useI18n } from 'vue-i18n';
import i18n from '../i18n';
import {
  getParking,
  getParkings,
  getParkingsSelect,
  getFilters,
  getSlots,
  getCommodities,
  patchSlots,
  patchPrices,
  patchParking,
  patchCommodities,
  uploadParkingMedia,
  deleteParkingMedia,
  saveParkingMedias,
  getMedias
} from '@/calls/parkings';

const parkings = {
  namespaced: true,
  state: {
    current: [],
    currentCommodities: [],
    commoditiesLoading: false,
    currentMedias: [],
    mediasLoading: false,
    filters: [],
    slots: [],
    allSlots: [],
    requests: {
      parking: 0,
      slotsAndPrices: 0
    },
    // Data for select parkings
    parkings: [],
    selected: null,
    isStack: true,
    isIntegrated: false,
    subscribable: false,
    whitelistable: false,
    popups: {
      instructions: {
        visible: false,
        requests: 0,
        parking: null,
        value: null,
        valueTextOnly: null,
        startValue: null,
        startValueTextOnly: null,
        rules: null,
        errors: {
          description: null
        }
      },
      rules: {
        visible: false,
        requests: 0,
        parking: null,
        value: null,
        valueTextOnly: null,
        startValue: null,
        startValueTextOnly: null,
        instructions: null,
        errors: {
          description: null
        }
      }
    },
    companies: {
      items: [],
      current: null,
      requests: 0,
      visible: false
    }
  },
  mutations: {
    update(state, { property, value }) {
      state[property] = value;
    },
    updateSelected(state, selected) {
      state.selected = selected;
    },
    updateIsIntegrated(state, flag) {
      state.isIntegrated = flag;
    },
    updateCurrent(state, parking) {
      state.current = parking;
    },
    updateParkings(state, parkings) {
      state.parkings = parkings;
    },
    updateFilters(state, filters) {
      state.filters = filters;
    },
    updateSlotById(state, slots) {
      state.slots = slots;
    },
    updateSlots(state, slots) {
      state.allSlots = slots;
    },
    updateCommodities(state, commodities) {
      state.currentCommodities = commodities;
    },
    updateCommodity(state, { groupId, commodityId }) {
      const commodityGroup = state.currentCommodities.filter(c => c.id === groupId)[0];

      for (let i = 0; i < commodityGroup.commodities.length; i++) {
        const commodity = commodityGroup.commodities[i];
        if (commodity.id === commodityId) {
          commodity.isActive = !commodity.isActive;
          break;
        }
      }
    },
    updateCommodityScore(state, { groupId, score }) {
      const commodityGroup = state.currentCommodities.filter(c => c.id === groupId)[0];
      commodityGroup.score = score;
    },
    removeMedia(state, index) {
      state.currentMedias.splice(index, 1);
    },
    addMedia(state, file) {
      state.currentMedias.push({
        id: state.currentMedias.length + 1,
        url: null,
        file: file ? file : null,
        img: null
      });
    },
    updateMediasOrder(state, { index, current }) {
      state.currentMedias.splice(index, 0, state.currentMedias.splice(current, 1)[0]);
    },
    updateMediaFile(state, { index, file }) {
      state.currentMedias[index].file = file;
    },
    updateMediaImage(state, { index, img }) {
      state.currentMedias[index].img = img;
    },
    updateMedias(state, medias) {
      state.currentMedias = medias;
    },
    updateLoading(state, { type, flag }) {
      if (type === 'commodities') {
        state.commoditiesLoading = flag;
      }

      if (type === 'medias') {
        state.mediasLoading = flag;
      }
    },
    updateContent(state, value) {
      state.current.translation.content = value;
    },
    updateDescription(state, value) {
      state.current.translation.description = value;
    },
    increaseRequest(state, type) {
      state.requests[type]++;
    },
    decreaseRequest(state, type) {
      state.requests[type]--;
    },
    updatePopup(state, { type, property, value }) {
      state.popups[type][property] = value;
    },
    updateCompaniesProperty(state, { property, value }) {
      state.companies[property] = value;
    }
  },
  actions: {
    setCurrent({ commit, dispatch }, id) {
      let parking = this.state.parkings.parkings.filter(p => Number(p.id) === Number(id));
      parking = parking.shift()
      dispatch('settings/setCountry', parking.address.country, { root : true });
      return commit('updateCurrent', parking);
    },
    setParkings({ commit, state }) {
      const parking = getParkingsSelect();
      return parking.then((promise) => {
        commit('updateParkings', promise);
        return promise;
      });
    },
    setParking({ commit, state }, id) {
      commit('increaseRequest', 'parking');
      commit('increaseRequest', 'slotsAndPrices');
      const parking = getParking(state.selected);
      // const parking = getParking(id);

      parking.then((promise) => {
        commit('decreaseRequest', 'parking');
        commit('decreaseRequest', 'slotsAndPrices');

        if(promise) {
          promise.parking.slots = promise.slots;
          promise.parking.packages = promise.packages;
          if (!promise.parking.translation) {
            promise.parking.translation = {
              content: '',
              description: '',
              language: "en"
            }
          }
        }

        return commit('updateCurrent', promise.parking);
      });
    },
    setFilters({ commit }) {
      const filters = getFilters();
      return filters.then((promise) => {
        commit('updateFilters', promise.filters);
        return promise;
      });
    },
    setSlots({ commit }) {
      const slots = getSlots();
      const arr = [];
      return slots.then((promise) => {
        promise.forEach(element => {
          arr[element.id] = element.translation.name
        });
        commit('updateSlots', promise);
        commit('updateSlotById', arr);
        return promise;
      });
    }, 
    setSlotsValues({ commit, dispatch, state }, item) {
      commit('increaseRequest', 'slotsAndPrices');
      const slots = patchSlots(state.selected, item);
      slots.then(promise => {
        dispatch('setParking', state.selected);
        dispatch('dashboard/getStats', { id: state.current.id, force: true }, { root: true });
        commit('decreaseRequest', 'slotsAndPrices');
      });
    },
    setSlotsPrices({ commit, dispatch, state }, item) {
      commit('increaseRequest', 'slotsAndPrices');
      const slots = patchPrices(state.selected, item);
      slots.then(promise => {
        dispatch('setParking', state.selected);
        commit('decreaseRequest', 'slotsAndPrices');
      });
    },
    setParkingInformation({ commit, dispatch, state }, item) {
      commit('increaseRequest', 'parking');
      const { t } = i18n.global;

      const parking = patchParking(state.selected, item);
      return parking.then(promise => {
        dispatch('setParking');
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.parkings.updated'),
          type: 'success'
        }], { root: true });
        commit('decreaseRequest', 'parking');
        return promise
      });
    },
    async getInstructions({ commit, dispatch, state }) {
      commit('updatePopup', { type: 'instructions', property: 'requests', value: state.popups.instructions.requests + 1 });
      const parking = await getParking(state.selected);
      if (parking && !parking.hasError) {
        const { rules, instructions } = parking.parking.translations.filter(p => p.language === 'en')[0] || { rules: '', instructions: '' };
        // if (instructions) {
        commit('updatePopup', { type: 'instructions', property: 'value', value: instructions ? instructions : '' });
        commit('updatePopup', { type: 'instructions', property: 'startValue', value: instructions ? instructions : '' });
        commit('updatePopup', { type: 'instructions', property: 'value', value: instructions ? instructions : '' });
        commit('updatePopup', { type: 'instructions', property: 'startValue', value: instructions ? instructions : '' });
        // }
        if (rules) commit('updatePopup', { type: 'instructions', property: 'rules', value: rules });
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parkings.instructions.get', { message: parking.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'instructions', property: 'requests', value: state.popups.instructions.requests - 1 });
    },
    async saveInstructions({ state, commit, dispatch }) {
      commit('updatePopup', { type: 'instructions', property: 'requests', value: state.popups.instructions.requests + 1 });

      const instructions = await patchParking(state.selected, {
        instructions: state.popups.instructions.value?.trim() || '',
        rules: state.popups.instructions.rules
      });

      const { t } = i18n.global;
      if (instructions && !instructions.hasError) {
        commit('updatePopup', { type: 'instructions', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.error.parkings.instructions.update'),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'instructions', property: 'requests', value: state.popups.instructions.requests - 1 });
    },
    async getRules({ commit, dispatch, state }) {
      commit('updatePopup', { type: 'rules', property: 'requests', value: state.popups.rules.requests + 1 });
      const parking = await getParking(state.selected);
      if (parking && !parking.hasError) {
        const enTranslation = parking.parking.translations.filter(p => p.language === 'en');
        const { rules, rulesHTML, instructions } = enTranslation.length > 0 ? enTranslation[0] : {};
        // if (rules) {
        commit('updatePopup', { type: 'rules', property: 'value', value: rulesHTML ? rulesHTML : '' });
        commit('updatePopup', { type: 'rules', property: 'startValue', value: rulesHTML ? rulesHTML : '' });
        commit('updatePopup', { type: 'rules', property: 'valueTextOnly', value: rules ? rules : '' });
        commit('updatePopup', { type: 'rules', property: 'startValueTextOnly', value: rules ? rules : '' });
        // }
        if (instructions) commit('updatePopup', { type: 'rules', property: 'instructions', value: instructions });
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parkings.rules.get', { message: parking.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'rules', property: 'requests', value: state.popups.rules.requests - 1 });
    },
    async saveRules({ state, commit, dispatch }) {
      commit('updatePopup', { type: 'rules', property: 'requests', value: state.popups.rules.requests + 1 });

      const rules = await patchParking(state.selected, {
        instructions: state.popups.rules.instructions,
        rules: state.popups.rules.valueTextOnly?.trim() || '',
        rulesHTML: state.popups.rules.value?.trim() || '',
      });

      const { t } = i18n.global;
      if (rules && !rules.hasError) {
        commit('updatePopup', { type: 'rules', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.error.parkings.rules.update'),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'rules', property: 'requests', value: state.popups.rules.requests - 1 });
    },
    async getCommodities({ commit, state }) {
      commit('updateLoading', { type: 'commodities', flag: true });
      const commodities = await getCommodities(state.selected);
      commit('updateCommodities', commodities);
      commit('updateLoading', { type: 'commodities', flag: false });
      return true;
    },
    async setCommodities({ commit, state, dispatch }) {
      commit('increaseRequest', 'parking');
      const commodityTypes = {};
      const commodities = {};
      const { currentCommodities } = state;

      currentCommodities.forEach(type => {
        commodityTypes[type.id] = type.score;

        type.commodities.forEach(com => {
          commodities[com.id] = com.isActive;
        });
      });

      const { t } = i18n.global;

      try {
        // const update = await patchCommodities(id, { commodityTypes, commodities });
        const update = await patchCommodities(state.selected, { commodityTypes, commodities });
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.parkings.features'),
          type: 'success'
        }], { root: true });

      } catch (error) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parkings.features', { message: error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequest', 'parking');
      return true;
    },
    async saveDescription({ commit, state, dispatch }) {
      commit('increaseRequest', 'parking');

      const description = await patchParking(state.selected, {
        description: state.current.translation.description.trim()
      });

      const { t } = i18n.global;
      if (description.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parkings.description', { message: description.error }),
          type: 'error'
        }], { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.parkings.description'),
          type: 'success'
        }], { root: true });
      }
      
      commit('decreaseRequest', 'parking');
      return description;
    },
    async getCompanies({ commit, state, dispatch }) {
      commit('updateCompaniesProperty', {
        property: 'requests', value: state.companies.request + 1
      });

      const request = await getCompanies();

      if (!request.hasError) {
        commit('updateCompaniesProperty', {
          property: 'items', value: request
        });
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parkings.companies', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updateCompaniesProperty', {
        property: 'requests', value: state.companies.request - 1
      });
    }
  }
};

export default parkings;
