<template>
  <section class="main-container accounting">
    <div class="table">
      <template v-if="reporting">
        <div class="table-head">
          <div class="table-cell table-cell--name">{{ $t('accounting.thead.month') }}</div>
          <div class="table-cell table-cell--bookings">{{ $t('accounting.thead.bookings') }}</div>
          <div class="table-cell table-cell--revenue">{{ $t('accounting.thead.revenue') }}</div>
          <div class="table-cell table-cell--actions"></div>
        </div>
        <div
          v-for="(item) in reporting"
          :key="`account-item-${item.id}`"
          :class="['table-row', { 'is-open': item.open }]"
        >
          <!-- :class="['table-row']" -->
          <div class="table-cell table-cell--name">{{ $t(`components.calendar.months.${item.label}`) }}</div>
          <div class="table-cell table-cell--bookings">{{ item.bookings !== 0 ? item.bookings : $t('accounting.no_activity') }}</div>
          <div class="table-cell table-cell--revenue">
            {{ item.bookings !== 0 ? setRevenue(item.revenue) : '-' }}
          </div>
          <div class="table-cell table-cell--actions">
            <button
              v-if="item.parkings.length"
              class="table-cell-toggle"
              @click="toggle(item.id)"
            >
              <sprite
                :type="'chevron'"
                :width="23"
                :height="14"
              />
            </button>
          </div>

          <div
            v-if="item.open"
            class="table-cell table-cell--full"
          >
            <div class="table">
              <div
                v-for="parking in item.parkings"
                :key="`account-item-${item.id}-${parking.parkingId}`"
                class="table-row"
              >
                <div class="table-cell table-cell--name">{{ parking.parkingName || parking.name }}</div>
                <div class="table-cell table-cell--bookings">{{ parking.bookings }}</div>
                <div class="table-cell table-cell--revenue">
                  {{ setRevenue(parking.revenue) }}
                </div>
                <div class="table-cell table-cell--actions"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="!reporting && !loading"
        class="no-result small"
      >
        <p>{{ $t('accounting.no_result') }}</p>
      </div>
    </div>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import Loader from '@/components/elements/Loader';

export default {
  name: 'Accounting',
  components: {
    Sprite, Loader
  },
  computed: {
    reporting() {
      let reporting = this.$store.state.accounting?.data;
      // if (this.$route.name === 'TWReporting') reporting = this.$store.state.tw?.reporting;
      // if (this.$route.name === 'TCReporting') reporting = this.$store.state.tc?.reporting;
      if (!reporting) return null;
      const formated = [];

      for (const key in reporting) {
        const monthObj = reporting[key];
        const index = formated.indexOf(monthObj);

        if (index < 0) {
          formated.push(monthObj);
        }
      }

      formated.sort((a, b) => {
        if(a.id < b.id) return 1;
        if(a.id > b.id) return -1;
        return 0;
      });

      return formated;
    },
    loading() {
      return this.$store.state.accounting.requests !== 0;
    }
  },
  beforeMount() {
    this.addTools();
  },
  unmounted() {
    this.$store.commit('accounting/updateData', null);
    this.$store.commit('accounting/updateDownload', null);
  },
  methods: {
    addTools() {
      this.$store.commit('accounting/updateDownload', {
        label: this.$t('accounting.download_report'),
        onClick: (evt) => {
          if (evt) {
            evt.preventDefault();

            const parkingId = this.$store.state.parkings.selected;

            this.$store.dispatch('accounting/downloadReport', parkingId);
          }
        }
      });
    },
    toggle(id) {
      this.$store.commit('accounting/toggleOpen', id);
    },
    setRevenue(number) {
      return new Intl.NumberFormat('fr-FR', { 
        style: 'currency', 
        currency: 'EUR',
        minimumFractionDigits:0
      }).format(number)
    }
  }
};
</script>

<style lang="scss" scoped>
.accounting {
  .table {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $lightestgrey;

    .table {
      .table-row {
        border-top: 0;
      }

      .table-cell {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: rem(14px);

        &--name {
          color: $snap-text-primary;
          align-items: flex-start;
        }
      }
    }

    &-head {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-bottom: 1px solid $lightestgrey;

      + .table-row {
        border-top: 0;
      }

      .table-cell {
        color: rgba($black, .8);
        font-family: $primary;
        text-transform: uppercase;
      }
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-top: 1px solid $lightestgrey;

      &:last-child {
        border-bottom: 1px solid $lightestgrey;
      }

      &.is-open {
        .table-cell--actions {
          .table-cell-toggle {
            .svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &-cell {
      position: relative;
      padding-top: 25px;
      padding-bottom: 25px;
      color: $mediumgrey;
      font-family: $secondary;
      font-size: rem(16px);
      line-height: 1.4;
      text-align: left;

      .table-row:last-child {
        border-bottom: 0;
      }

      small {
        display: block;
        font-size: rem(14px);
      }

      &--name {
        display: flex;
        align-items: center;
        width: 30%;
        padding-left: 25px;
        color: $black;
      }

      &--bookings {
        width: 30%;
        padding-left: 25px;
      }

      &--revenue {
        width: 15%;
        text-align: right;
      }

      &--actions {
        width: 25%;
        padding-right: 37px;
        text-align: right;
      }

      &--full {
        width: 100%;
        border-top: 1px solid $lightestgrey;
      }

      &-toggle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 12px;
        width: auto;
        height: 100%;
        padding: 0 25px;
        cursor: pointer;
      }
    }
  }
}
</style>
