<template>
  <popup
    v-if="popup.visible"
    :classes="'popup-detail'"
  >
    <template v-slot:content>
      <template v-if="popup.booking && popup.booking.priceDetails">
        <div class="popup-header">
          <h2 class="h4">{{ popup.booking.driverName ? popup.booking.driverName : $t('globals.no_name') }}</h2>
        </div>

        <table class="popup-table">
          <tbody>
            <tr
              v-for="row in table"
              :key="`payPopup-${row.property}`"
            >
              <td>{{ row.label(popup.booking) }}</td>
              <td>{{ row.property(popup.booking) ? row.property(popup.booking) : '-' }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('globals.total') }} :</td>
              <td>{{ popup.booking.priceDetails.totalPrice }}€</td>
              <!-- <td>{{ popup.booking.totalPrice }}€</td> -->
            </tr>
          </tfoot>
        </table>

        <div class="popup-actions popup-actions--center">
          <button
            class="button"
            @click="close"
          >
            {{ $t('globals.close') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';

export default {
  name: 'Detail',
  components: {
    Loader,
    Popup
  },
  data() {
    return {
      table: [
        {
          label: (booking) => this.$t('bookings.popups.pay.duration'),
          property: (booking) => `${Math.floor(booking.totalDurationStay / 60)}${this.$t('globals.hour_abbr')}${Math.abs(booking.totalDurationStay) % 60}`
        },
        {
          label: (booking) => this.$t('bookings.popups.pay.extra_hour'),
          property: (booking) => `${Math.floor(booking.extraDurationStay / 60)}${this.$t('globals.hour_abbr')}${Math.abs(booking.extraDurationStay) % 60}`
        },
        {
          label: (booking) => this.$t('bookings.popups.pay.price'),
          property: (booking) => `${booking.priceDetails.packagePriceWithoutFee}€`
        },
        {
          label: (booking) => {
            if (booking.priceDetails.extraDurationType === 'Package') return this.$t('bookings.popups.pay.extra_package');
            return this.$t('bookings.popups.pay.extra', { price: booking.packageExtensionPrice });
          },
          property: (booking) => `${booking.priceDetails.extraDurationPriceWithoutFee}€`
        },
        {
          label: (booking) => this.$t('bookings.popups.pay.parking_charge'),
          property: (booking) => `${booking.priceDetails.payAtParkingPriceWithoutFee}€`
        },
        {
          label: (booking) => this.$t('bookings.popups.pay.service_fee', { percent: booking.priceDetails.feePercent }),
          property: (booking) => {
            const { priceDetails } = booking;
            return `${(priceDetails.packageFee + priceDetails.extraDurationFee + priceDetails.payAtParkingFee)}€`
          }
        }
      ]
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.ticket;
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/updatePopup', {
        type: 'ticket', property: 'visible', value: false
      });
      this.$store.commit('popups/updatePopup', {
        type: 'ticket', property: 'booking', value: {}
      });
    }
  }
}
</script>