<template>
  <Popup
    v-if="companies.visible"
    :classes="'popup-companies'"
  >
    <template v-slot:content>
      <template v-if="companies.items && companies.items.length">
        <h2 class="popup-title">{{ $t('globals.layout.companies.title') }}</h2>
        <p class="popup-main-text">{{ $t('globals.layout.companies.text') }}</p>
        <form
          class="popup-form"
          novalidate
          @submit.prevent="onSubmit"
        >
          <NewSelect
            ref="companies"
            :name="'companies'"
            :options="companiesOptions"
            :placeholder="companies.selected ? companies.selected.name :  $t('globals.layout.companies.placeholder')"
            :required="true"
            :hasSearch="true"
            :value="company"
            :class="'select-input classic'"
            @onChange="companyChange"
          />
        </form>
        <div class="popup-actions popup-actions--center">
          <button
            :disabled="!companies.current"
            class="button button-ghost button-big"
            @click="closeCompanies"
          >
            {{ $t('globals.close') }}
          </button>
          <button
            :disabled="!companies.selected"
            class="button button-big"
            @click="applyCompany"
          >
            {{ $t('globals.apply') }}
          </button>
        </div>
      </template>

      <div
        v-show="companies.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </Popup>
</template>

<script>
import Loader from '@/components/elements/Loader';
import NewSelect from '@/components/elements/NewSelect';
import Popup from '@/components/partials/Popup';

export default {
  name: 'CompaniesPopup',
  components: {
    Loader, Popup, NewSelect
  },
  computed: {
    companies() {
      return this.$store.state.companies;
    },
    companiesOptions() {
      if (!this.$store.state.companies.items || !this.$store.state.companies.items.length) return [];
      const companies = [...this.$store.state.companies.items.map(c => {
        let disabledLabel = !c.hasOwner ? this.$t('globals.layout.companies.select.no_owner') : null;
        if (!disabledLabel) disabledLabel = !c.parkings.length ? this.$t('globals.layout.companies.select.no_parking') : null;

        return {
          label: c.name,
          value: c.id,
          disabled: !c.hasOwner || !c.parkings.length,
          disabledLabel
        }
      })];
      return companies.sort((x, y) => {
        return (x.disabled === y.disabled)? 0 : y.disabled? -1 : 1;
      });
    },
    company() {
      return this.$store.state.companies?.selected?.id;
    }
  },
  methods: {
    closeCompanies() {
      this.$store.commit('companies/updateProperty', {
        property: 'visible', value: false
      });
    },
    companyChange(e) {
      const selected = [...this.companies.items].filter(c => c.id === e);
      this.$store.commit('companies/updateProperty', {
        property: 'selected', value: selected[0]
      });
    },
    applyCompany() {
      // if (!this.$route.meta.namespace.match(/tc|tw/)) {
        this.$store.dispatch('companies/applyCompany');
      // }

      this.$store.dispatch('services/getServicesList', true)
      // if (this.$route.name.match(/TC|TW/g)) {}

      this.closeCompanies();
    },
    onSubmit() {
      
    }
  }
};
</script>