<template>
  <!-- Popup Instruction -->
  <popup
    v-if="popup.visible"
    :classes="'popup-rules'"
  >
    <template v-slot:content>
      <!-- Success -->
      <template v-if="popup.success">
        <h2 class="popup-title">{{ $t('globals.success') }}</h2>
        <div class="popup-main">
          <img class="popup-main-img" src="/img/success.png"/>
          <p class="popup-main-text">{{ $t('components.popups.rules.success') }}</p>
        </div>

        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup"
            @click.prevent="close"
          >
            {{ $t('globals.ok') }}
          </button>
        </div>
      </template>

      <!-- Form -->
      <template v-else>
        <div class="popup-header">
          <img class="popup-header-img" src="/img/rules.svg"/>
          <h2 class="popup-title h4">{{ $t('components.popups.rules.title') }}</h2>
        </div>

        <form class="popup-form">  
          <Inputs
          ref="description"
          type="textarea-no-scroll"
          :required="true"
          :value="popup.valueTextOnly"
          :disabled="true"
          @input="updateRulesText"
        />      
          <editor
            :api-key="apiKey"            
            v-model="mceValue"
            @update:modelValue="updateRules"
            :init="editorConfig"
          />
          
        </form>

        <div class="popup-actions">
          <button
            class="button button-ghost"
            @click="close"
          >
            {{ $t('globals.cancel') }}
          </button>
          <button
            class="button"
            @click="saveRules"
            :disabled="disabled"
          >
            {{ $t('globals.update') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';
import Editor from '@tinymce/tinymce-vue'
import Inputs from '@/components/elements/Inputs';
//import FileReader from 'filereader'

export default {
  components: {   
    Loader,
    Popup,
    Editor,
    Inputs
  },
  data() {
    return {
      type: 'rules',
      apiKey: process.env.VUE_APP_TINYMCE_APIKEY,
      mceValue : '',
      editorConfig: {
        height: 500,
        menubar: false,
        plugins: [
          'lists link image',                
        ],
        toolbar:
          'undo redo | formatselect | image bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help',
        
        paste_data_images : true,
        file_picker_types: 'image',
        file_picker_callback: this.handleImageUpload
      }
    }
  },
  computed: {
    popup() {
      return this.$store.state.parkings.popups.rules;
    },
    disabled() {
      // if both tinyMCE and textarea are not modified, then disabled will be true, otherwise false
      let isTinyMceNotModified = this.popup.startValue === this.popup.value || (!this.popup.startValue && !this.popup.value);
      let isTextAreaNotModified = this.popup.startValueTextOnly === this.popup.valueTextOnly || (!this.popup.startValueTextOnly && !this.popup.valueTextOnly);
      return isTinyMceNotModified && isTextAreaNotModified;
    }
  }, 
  watch: {
    popup: {
      deep: true,
      handler({ value }) {
        this.mceValue = value
      }
    }
  },
  methods: {
    updateRules(e) {   
      this.$store.commit('parkings/updatePopup', {
        type: 'rules', property: 'value',value : e
      });
    },
    updateRulesText(e) {  
      if(!e) return;
      const { target } = e;
      const { value } = target;
      this.$store.commit('parkings/updatePopup', {
        type: 'rules', property: 'valueTextOnly', value
      });
    },
    saveRules() {     
      this.$store.dispatch('parkings/saveRules');
    },
    close(popup) {
      this.$store.commit('parkings/updatePopup', { type: this.type, property: 'visible', value: false });
      this.$store.commit('parkings/updatePopup', { type: this.type, property: 'success', value: false });
      this.$store.commit('parkings/updatePopup', { type: this.type, property: 'startValue', value: null });
      this.$store.commit('parkings/updatePopup', { type: this.type, property: 'value', value: null });
    },
    handleImageUpload(cb, value, meta) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

    /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

    input.onchange = function () {
      const file = this.files[0];
      const reader = new FileReader();
      reader.onload = function () {
        /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        */
        const id = 'blobid' + (new Date()).getTime();
        const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        /* call the callback and populate the Title field with the file name */
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
    }
  }
}
</script>