<template>
  <!-- Popup Checkout -->
  <popup
    v-if="popup.visible"
    :classes="'popup-checkout'"
  >
    <template v-slot:content>
      <!-- Success -->
      <template v-if="popup.success">
        <h2 class="popup-title">{{ $t('globals.success') }}</h2>
        <div class="popup-main">
          <img class="popup-main-img" src="/img/success.png"/>
          <p class="popup-main-text">{{ $t('parkings.plates.popups.add.success') }}</p>
        </div>

        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup"
            @click.prevent="close"
          >
            {{ $t('globals.ok') }}
          </button>
        </div>
      </template>

      <!-- Infos -->
      <template v-else>
        <div class="popup-header">
          <h2 class="popup-title h4">{{ $t('parkings.plates.popups.add.title') }}</h2>
        </div>

        <form
          class="popup-form"
          @submit.prevent="add"
        >
          <Inputs
            type="text" 
            :value="popup.value"
            :label="$t('parkings.plates.popups.add.label')"
            :placeholder="$t('parkings.plates.popups.add.placeholder')"
            @input="onInput"
          />
        </form>

        <div class="popup-actions">
          <button
            class="button button-border"
            @click="close('addPlate')"
          >
            {{ $t('globals.close') }}
          </button>
          <button
            class="button"
            @click="add"
            :disabled="!popup.value.length"
          >
            {{ $t('globals.add') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';

export default {
  components: {
    Loader,
    Inputs,
    Popup,
  },
  data() {
    return {
      type: 'addPlate'
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.addPlate;
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'success', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'value', value: '' });
    },
    onInput(e) {
      const { value } = e.target;
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'value', value });
    },
    async add() {
      const add = await this.$store.dispatch('popups/addPlate', this.$store.state.parkings.selected);
      if (!add.hasError) this.$emit('onSuccess');
    }
  }
}
</script>