<template>
  <div 
    class="input"
    :class="error ? 'error' : '' "
  >
    <label
      class="label small"
      :class="noLabel ? 'no-label' : ''"
    >
      {{ label }}{{ asterisk ? '*' : null }}

      <span
        v-if="counting"
        :class="[
          'input-count',
          { 'has-warning': count <= 10 && count > 5 },
          { 'has-error': count <= 5 }
        ]"
      >
        <span class="input-count-number">{{ count }}</span>
        <!-- characters left -->
      </span>
    </label>

    <textarea
      v-if="type === 'textarea'"
      ref="input"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      @input="$emit('input')"
    ></textarea>

    <textarea
      v-else-if="type === 'textarea-no-scroll'"
      ref="input"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      @input="$emit('input')"
      class="no-scroll"
    ></textarea>

    <input 
      v-else
      ref="input"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      :min="min"
    />
      <!-- @input="onInput" -->
      <!-- @input="$emit('input')" -->
    <strong  class="small error-msg">
      <span v-if="error">{{ error }}</span>
    </strong>
  </div>
</template>

<script>
export default {
  name: 'Inputs',
  props: {
    label: {
      type: String,
      default: null
    },  
    type: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },  
    error: {
      type: String,
      default: null
    },
    validation: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    name: {
      type: String,
      // default: null
    },
    min: {
      type: Number,
      default: 0
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    counting: {
      type: [Number, Boolean],
      default: false
    },
    asterisk: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    count() {
      if (!this.counting) return 0;
      if(!this.value) return this.counting;

      return this.counting - this.value.length;
    }
  },
  watch: {
    value(val) {
      return val;
    }
  },
  // methods: {
  //   onInput() {
  //     this.$emit('input');
  //   }
  // }
}
</script>


<style lang="scss" scoped>
.small {
  color: $mediumgrey;
  font-size:  rem(14px);
  margin-bottom: 8px;
  transition: color .3s $ease-out-quart;

  .error & {
    color: $error;
  }
}

.no-label {
  opacity: 0;
}

label {
  display: flex;
  width: 100%;
  position: relative;

  > span {
    margin-left: auto;
    color: $success;

    &.has-warning {
      color: $warning;
    }

    &.has-error {
      color: $error;
    }
  }
}

// Folowing count's style doesn't apply,
// WTF ????
.input-count {
  color: red;
  margin-left: auto;
}

textarea,
input {
  border: 1px solid $lightestgrey;
  border-radius: 3px;

  padding: 20px 16px 18px;

  width: 100%;
  transition: border .3s $ease-out-quart;

  @extend %p;
  color: $snap-text-primary;

  &::placeholder {
    color: $mediumgrey;
  }

  .error & {
    border: 1px solid $error !important;
  }
}

textarea {
  min-height: 200px;
  resize: none;
}

.error-msg {
  color: $error;
  margin-top: 4px;

  height: 20px;
  opacity: 0;
  transition: opacity .3s $ease-out-quart;

  .error & {
    opacity: 1;
  }
}

.no-scroll{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}
</style>
