// import { useI18n } from 'vue-i18n';
import i18n from '../i18n';
import {
  getSubscriptions
} from '@/calls/subscriptions';

const subscriptions = {
  namespaced: true,
  state: {
    items: [],
    requests: 0
  },
  mutations: {
    updateParkingId(state, id) {
      state.parkingId = id;
    },
    updateItems(state, items) {
      state.items = items;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
  },
  actions: {
    async getSubscriptions ({ commit, dispatch, state }, id) {
      commit('increaseRequests');

      const request = await getSubscriptions(id);

      if (!request.hasError) {
        commit('updateItems', request.subscriptions);
      }

      commit('decreaseRequests');
    }
  }
};

export default subscriptions;
