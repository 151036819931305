<template>
  <!-- Popup Checkout -->
  <popup
    v-if="popup.visible"
    :classes="'popup-checkout'"
  >
    <template v-slot:content>
      <!-- Success -->
      <template v-if="popup.success">
        <h2 class="popup-title">{{ $t('globals.success') }}</h2>
        <div class="popup-main">
          <img class="popup-main-img" src="/img/success.png"/>
          <p class="popup-main-text">{{ $t('components.stack.popups.checkout.success') }}</p>
        </div>

        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup"
            @click.prevent="close('checkout')"
          >
            {{ $t('globals.ok') }}
          </button>
        </div>
      </template>

      <!-- Infos -->
      <template v-else-if="popup.booking">
        <div class="popup-header">
          <h2 class="popup-title h4">{{ $t('components.stack.popups.checkout.title') }}</h2>
          <p class="popup-main-text">{{ $t('components.stack.popups.checkout.text') }}</p>
        </div>

        <form class="popup-form">
          <Calendar 
            :value="popup.fields.date"
            :label="$t('components.stack.popups.checkout.labels.date')"
            @close="toggleCalendar"
            @onChange="updateDate"
          />

          <Time
            :label="$t('components.stack.popups.checkout.labels.time')"
            :value="popup.fields.time"
            @onChange="updateTime"
          />

          <div 
            v-if="popup.error"
            class="popup-form-error"
          >
            {{ popup.error }}
          </div>
        </form>

          <table class="popup-table">
          <tbody>
            <tr
              v-for="row in table"
              :key="`payPopup-${row.property}`"
            >
              <td>{{ row.label(popup.booking) }}</td>
              <td>{{ row.property(popup.booking) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('globals.total') }} :</td>
              <td>{{ popup.booking.priceDetails.totalPrice }}€</td>
            </tr>
          </tfoot>
        </table>

        <div class="popup-actions">
          <button
            class="button button-ghost"
            @click="close('checkout')"
          >
            {{ $t('globals.cancel') }}
          </button>
          <button
            class="button"
            @click="checkout"
            :disabled="popup.error"
          >
            {{ $t('globals.confirm') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import moment from 'moment';
import Popup from '@/components/partials/Popup';
import Time from '@/components/elements/Time';
import Loader from '@/components/elements/Loader';
import Calendar from '@/components/elements/Calendar';

export default {
  components: {
    Time,
    Calendar,
    Loader,
    Popup,
  },
  data() {
    return {
      type: 'checkout',
      calendarVisibility: true,
      table: [
        {
          label: (booking) => this.$t('components.stack.popups.checkout.table.price'),
          property: (booking) => `${booking.priceDetails.packagePriceWithoutFee}€`
        },
        {
          label: (booking) => {
            if (booking.priceDetails.extraDurationType === 'Package') return this.$t('components.stack.popups.checkout.table.extra_package');
            // if (booking.priceDetails.extraDurationType === 'Package') return this.$t('components.stack.popups.checkout.table.extra_package', { price: booking.priceDetails.extraDurationPackageUnitPrice });
            return this.$t('components.stack.popups.checkout.table.extra', { price: booking.packageExtensionPrice });
          },
          property: (booking) => `${booking.priceDetails.extraDurationPriceWithoutFee}€`
        },
        {
          label: (booking) => this.$t('components.stack.popups.checkout.table.parking_charge'),
          property: (booking) => `${booking.priceDetails.payAtParkingPriceWithoutFee}€`
        },
        {
          label: (booking) => this.$t('components.stack.popups.checkout.table.service_fee', { percent: booking.priceDetails.feePercent }),
          property: (booking) => {
            const { priceDetails } = booking;
            const total = priceDetails.packageFee + priceDetails.extraDurationFee + priceDetails.payAtParkingFee;
            const totalRound = Math.round(total * 100) / 100;
            return `${totalRound}€`;
          }
        }
      ]
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.checkout;
    },
    isAdmin() {
      const user = this.$store.state.user?.userProfile?.user;
      if (!user) return false;
      const { roles } = user;
      if (!roles) return false;
      const index = roles.indexOf('otra-admin');
      return index >= 0;
    }
  },
  methods: {
    // ON
    toggleCalendar() {
      this.calendarVisibility = !this.calendarVisibility;
    },
    close() {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'success', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'booking', value: null });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: null });
    },
    checkout(booking) {
      
     const isBefore = this.validCheckoutDate(this.popup.fields.date, this.popup.fields.time);
     if (isBefore) {
       this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: this.$t('components.stack.popups.checkout.errors.date-before') });
       return;
     }
     
      const request = this.$store.dispatch('popups/setCheckout');
      if (!request.hasError) this.$emit('onSuccess');
    },
    updateDate(date) {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: null });
      const formatedDate = moment(date).format('YYYY[-]MM[-]DD')
      const isBefore = this.validCheckoutDate(formatedDate, this.popup.fields.time);

      this.$store.commit('popups/updatePopupField', { type: this.type, field: 'date', value: formatedDate });

      if (isBefore) {
        this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: this.$t('components.stack.popups.checkout.errors.date-before') });
        return;
      }

      this.$store.dispatch('popups/getCheckoutBooking', { popup: 'checkout', booking: this.popup.booking });
    },
    updateTime(time) {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: null });
      const isBefore = this.validCheckoutDate(this.popup.fields.date, time);
      this.$store.commit('popups/updatePopupField', { type: this.type, field: 'time', value: time });

      if (isBefore) {
        this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: this.$t('components.stack.popups.checkout.errors.date-before') });
        return;
      }
      
      this.$store.dispatch('popups/getCheckoutBooking', { popup: 'checkout', booking: this.popup.booking });
    },
    validCheckoutDate(date, time) {
      
      const { checkinAt, startAt } = this.popup.booking;
      console.log('tzId',tzId)
      const tzId = this.$store.state.parkings.current.timeZoneId;
      const checkoutDate = moment(`${date} ${time}`).tz(tzId);

      //past date validation
      //const dateTime = moment.tz(moment(), tzId).format('YYYY[-]MM[-]DD[T]HH[:]mm[:00]');
     
      // var isBeforeNow = checkoutDate.isBefore(dateTime)      
      // console.log('checkinAt', checkinAt)
      // if (isBeforeNow && !checkinAt) {        
      //   return isBeforeNow;
      // }

      if (this.isAdmin && !checkinAt) 
      { 
        //when check in is null 
        //validate start at
        const matchStart = startAt.match(/(\+|\-)[0-9]{2}:[0-9]{2}$/g);
        const dateStringStart = matchStart.length > 0 ? startAt.replace(matchStart[0], '') : startAt;
        const endDateStart = moment(dateStringStart).tz(tzId);
        
        return checkoutDate.isBefore(endDateStart);
      }      
     
      const match = checkinAt.match(/(\+|\-)[0-9]{2}:[0-9]{2}$/g);
      const dateString = match.length > 0 ? checkinAt.replace(match[0], '') : checkinAt;
      const endDate = moment(dateString).tz(tzId);
      const isBefore = checkoutDate.isBefore(endDate);

      return isBefore;     
     
    },

  }
}
</script>