<template>
  <div
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <section>
      <Breadcrumbs
        :breadcrumbs="breadcrumbs"
        :current="0"
      />

      <form
        v-if="booking"
        novalidate
        @submit.prevent="onNext"
      >
        <fieldset class="container-row container-3-3-3">
          <Inputs
            ref="plate"
            type="text"
            :label="$t('labels.plate_number')"
            :value="form.plate"
            :required="true"
            :asterisk="true"
            @input="onInput($event, 'plate')"
          />
        </fieldset>

        <fieldset class="container-row container-3-3-3">
          <Inputs
            ref="phone"
            type="text"
            :label="$t('labels.phone_number')"
            :value="form.phone"
            @input.prevent="onInput($event, 'phone')"
          />
        </fieldset>

        <div class="main-container__form-buttons">
          <button
            type=""
            class="button"
            :disabled="disableNext"
          >
            {{ $t('globals.next') }}
          </button>
        </div>
      </form>

      <div
        v-show="loading"
        class="main-container-loader"
      >
        <loader />
      </div>
    </section>
  </div>
</template>

<script>
import Inputs from '@/components/elements/Inputs';
import Breadcrumbs from '@/components/modules/Breadcrumbs';
import Loader from '@/components/elements/Loader';
import _debounce from 'lodash/debounce';

export default {
  components: {
    Inputs,
    Breadcrumbs,
    Loader
  },
  computed: {
    form() {
      const form = this.$store.state.booking.form;
      return form;
    },
    booking() {
      const booking = this.$store.state.booking.data;
      return booking;
    },
    disableNext() {
      return !this.form.plate || !this.form.plate.length;
    },
    loading() {
      return this.$store.state.booking.requests.booking !== 0 || this.$store.state.booking.requests.pckgs !== 0;
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  data() {
    return {
      breadcrumbs: [
        { label: this.$t('components.breadcrumbs.details') },
        { label: this.$t('components.breadcrumbs.duration') },
        { label: this.$t('components.breadcrumbs.review') },
      ]
    }
  },
  methods: {
    onNext() {
      this.$router.push({
        path: `/update/${this.$route.params.id}/duration`
      });
    },
    onInput(event, type) {
      let { value } = event.target;
      if (type === 'phone' && value.match(/([^0-9\+])+/g)) {
        event.target.value = this.form.phone;
        return;
      }
      this.$store.commit('booking/updateForm', { field: type, value });

      if (type === 'plate') {
        this.debounceFleetCheck();
      }
    },
    debounceFleetCheck: _debounce(function(payload) {
      this.$store.dispatch('booking/checkForFleet');
    }, 300)
  }
}
</script>

<style lang="scss">
.main-container {
  padding: 32px;
}

.button {
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
}
</style>