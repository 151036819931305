<template>
  <div
    ref="container"
    :class="[
      'select',
      { 'select--open': open },
      { 'error': error },
    ]"
  >
    <label v-if="label" class="label">{{ label }}</label>
    <div 
      class="custom-select"
      :class="{
        'custom-select--open': open,
        'custom-select--search': hasSearch,
      }"
    >
      <strong 
        @click="onToggle"
        class="selected"
      > 
        <span class="selected-text">{{ placeholderText }}</span>
        <span class="selected-icon">
          <Sprite :type="'chevron'" />
        </span>
      </strong>
      <input
        v-show="hasSearch && open"
        ref="search"
        class="custom-select-search"
        type="text"
        :placeholder="$t('components.select.search')"
        :value="search"
        @input="onSearch"
      />
      <ul
        ref="ul"
        class="items hide-scrollbar"
      >
        <li 
          v-if="allowNull && (!search || !search.length)"
          :class="[
            'item',
            { 'item--selected': !selected }
          ]"
          @click="onClick({ value: '' })"
        >
          {{ placeholder }}
        </li>
        <!-- v-for="option in options" -->
        <li
          v-for="option in displayedOptions"
          :key="option.value"
          :rel="option.value"
          :class="[
            'item',
            { 'item--selected': selected && selected.value === option.value },
            { 'item--disabled': option.disabled }
          ]"
          @click="() => onClick(option)"
        >
          {{ option.label ? option.label : option.value }}
          <small v-if="option.disabled && option.disabledLabel">{{ option.disabledLabel }}</small>
        </li>
        <li
          v-show="search && search.length && !displayedOptions.length"
          class="item item--no-result"
        >
          {{ $t('components.select.no_result') }}
        </li>
      </ul>
    </div>
    <select
      ref="input"
      :name="name"
      :required="required"
      :value="value"
      autocomplete="off"
      @change="onChange"
    >
      <option 
        v-if="allowNull"
        :selected="!selected"
        value=""
      >
        {{ placeholder }}
      </option>
      <option 
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="value === option.value"
      >
        {{ option.label ? option.label : option.value }}
      </option>
    </select>
    <strong 
      v-if="error"
      class="small error-msg"
    >
      <span>{{ error }}</span>
    </strong>
  </div>
</template>

<script>
/* eslint-disable */
import Sprite from './Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    hasSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      search: null,
      _documentClick: this.onDocumentClick.bind(this)
    }
  },
  computed: {
    selected() {
      if (!this.value) return null;
      const selected = this.options.filter(o => o.value === this.value);
      return selected[0];
    },
    placeholderText() {
      if (this.selected) {
        if (this.selected.label) return this.selected.label;
        return this.selected.value;
      }

      if (this.hasSearch) return this.$t('components.select.search');

      return this.placeholder;
    },
    displayedOptions() {
      if (!this.search || !this.search.length) return this.options;

      const options = this.options.filter(o => {
        if (o.label) {
          const lower = o.label.toLowerCase();
          const index = lower.indexOf(this.search);
          return index >= 0;
        }

        return false;
      });

      return options;
    }
  },
  methods: {
    onClick(option) {
      this.open = false;
      this.search = null;
      document.removeEventListener('click', this._documentClick);
      this.$emit('onChange', option.value);
    },
    onSearch(e) {
      const { target } = e;
      const { value } = target;
      this.search = value;
    },
    onToggle() {
      this.open = !this.open;
      this.search = null;

      if (this.open) {
        document.addEventListener('click', this._documentClick);

        if (this.hasSearch) {
          setTimeout(() => {
            this.$refs.search.focus();
          }, 100);
        }
      } else {
        document.removeEventListener('click', this._documentClick);
      }
    },
    onDocumentClick(e) {
      const { target } = e;
      const isDescendant = this.isDescendant(this.$refs.container, target);
      if (!isDescendant && this.open) {
        this.onToggle();
      }
    },
    isDescendant(parent, child) {
      let node = child.parentNode;
      while (node) {
        if (node === parent) return true;
        node = node.parentNode;
      }

      return false;
    }
  }
}
</script>$

<style lang="scss" scoped>
.select {
  display: inline-block;
  height: 100%;
}
select {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none;
}
.custom-select {
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
  align-items: center;
  
  padding: 8px 10px;
  border: 1px solid transparent;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;

  .selected {
    min-width: 400px;
    display: block;

    font-family: $primary;
    font-weight: $medium;
    font-size: rem(34px);

    line-height: 110%;

    color: $snap-primary;

    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    z-index: 1;

    transition: border .3s $ease-out-quad
  }

  &-search {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 65%;
    border-radius: 3px;
    background-color: $white;
    color: $snap-primary;
    font-family: $primary;
    font-weight: $medium;
    font-size: rem(34px);
    line-height: 110%;
    border: 0;
  }
  
  &--open {
    border-color:$lightestgrey;
  }

  &--open .svg {
    transform: rotate(-180deg);
  }
}

.selected-icon {
  border: 1px solid $lightestgrey;
  padding: 5px;
  border-radius: 3px;

  margin-left: 20px;
  margin-top: 8px;

  display: flex;
  align-items: center;
}
.svg {
  fill: $mediumgreen;
  transition: transform .3s $ease-out-quad;
}

.items {
  width: calc(100% + 2px);
  min-width: max-content;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  top: 100%;
  left: -1px;

  z-index: -1;

  background: $white;
  border: 1px solid $lightestgrey;
  border-top: 0px solid $lightestgrey;
  border-radius: 0 0 3px 3px;
  // border-radius: 0 0 20px 20px ;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  list-style: none;

  padding: 5px 0 15px;
  // padding: 15px 0;

  opacity: 0;
  transform: translateY(-10px);
  transition: opacity .3s $ease-out-quad, transform .3s $ease-out-quad;
  pointer-events: none;

  .custom-select--open & {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}

.item {
  padding: 10px 0;
  font-family: $primary;
  font-weight: $medium;
  font-size: rem(34px);

  line-height: 110%;

  color: $snap-primary;

  position: relative;

  cursor: pointer;
  padding: 15px 17px;

  &::before{
    content: '';
    display: block;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: $grey-background;

    transform: scaleX(0);
    transform-origin: left;

    transition: transform .3s $ease-out-quad;
  }
  /* border-radius: 0 30px 30px 0; */
  &:hover:before {
    transform: scaleX(1);
  }

  &--disabled {
    opacity: .4;
    pointer-events: none;
  }
}
.label {
  font-family: $secondary;
  color: $mediumgrey;
  font-size:  rem(14px);
  display: block;

  &.full {
    width: 100%;
  }

  margin-bottom: 8px;

  transition: color .3s $ease-out-quart;
  .error & {
    color: $error;
  }
} 

.select-input {
  width: 100%;

  .custom-select {
    &--search {
      .selected {
        color: $mediumgrey;
      }
    }

    &-search {
      padding: 20px 16px 18px;
      @extend %p;
      
  font-size: rem(18px);

      &::placeholder {
        color: $mediumgrey;
      }
    }
  }
}

.select.classic {
  label {
    line-height: 1.4;
  }
  .custom-select {
    padding: 20px 16px 18px;
    border: 1px solid $lightestgrey;
    border-radius: 3px;
    transition: border .3s $ease-out-quart;
  }
  .selected, .item {
    width: 100%;
    @extend %p;
    color: $snap-action-default;
    min-width: 0;
  }
  .items {
    min-width: 0;
    top: calc(100% - 10px);
  }
  .item {
    padding: 6px 16px 4px;
    &--selected {
      display: block;
      color: $mediumgreen;
    }
  }
  .selected-icon {
    border: none;
    margin: 0;
    margin-left: 5px;
  }
  svg {
    width: 10px;
    height: 10px;
  }
  .error-msg {
    color: $error;
    margin-top: 4px;

    height: 20px;
    opacity: 0;
    transition: opacity .3s $ease-out-quart;
    .error & {
      opacity: 1;
    }
  }
}

</style>