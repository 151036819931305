import axios from 'axios';

import { accounting } from '@/formaters/accounting';

const getReporting = async () => {
  // const data = [
  //   { bookings: 1, month: 5, open: false, parkingId: 6, parkingName: "Q8 Sprimont", revenue: 65.1, year: 2021, }
  // ];

  try {
    const request = await axios.get('/pmng/bookings/parkingcompanybalance');
    return accounting.from(request.data);
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const getServiceReporting = async (type) => {
  try {
    const request = await axios.get('/pmng/cleaning-service-bookings/company-balance', { params: { type } });
    const data = request.data.map(d => {
      d.name = d.serviceName;
      d.id = d.serviceId;
      d.bookings = d.serviceBookings;
      return d;
    });
    return accounting.from(data);
    // return accounting.from(request.data);
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const getBalanceReport = async (parkingId) => {
  return await axios.get(`/pmng/parkings/${parkingId}/download-balance`, {responseType: "blob"})
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

export {
  getReporting,
  getServiceReporting,
  getBalanceReport
};
