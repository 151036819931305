<template>
  <router-view  v-if="$route.name !== 'Subscriptions' " />
  <section
    v-else
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <table
      v-if="subscriptions.length"
      class="table"
      cellspacing="0"
    >
      <!-- TABLE HEAD -->
      <Thead :head="theadSubscriptions" />
      
      <!-- TABLE BODY -->
      <tbody>
        <tr
          v-for="subscription in subscriptions"
          :key="subscription.id"
        >
          <TBody 
            v-for="(b, index) in tbodySubscriptions"
            :key="index"
            :body="b" 
            :el="subscription" 
          />
        </tr>
      </tbody>
    </table>
    <NoResult v-else>
      <slot>{{ $t('subscriptions.list.no_results') }}</slot>
    </NoResult>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>

    <RemoveSubscription @onSuccess="onRemoveSuccess"/>
  </section>
</template>

<script>
import Thead from '@/components/elements/THead';
import TBody from '@/components/elements/TBody';
import Loader from '@/components/elements/Loader';
import NoResult from '@/components/partials/Bookings/NoResult';
import RemoveSubscription from '@/components/partials/Popups/RemoveSubscription';
import { formatDate } from '@/helpers/helpers';

export default {
  name: 'List',
  components: {
    NoResult,
    Thead,
    TBody,
    Loader,
    RemoveSubscription
  },
  data() {
    return {
      tbodySubscriptions: [
        // Names
        {
          title: (sub) =>  sub.name,
          class: 'nickname'
        },
        { title: (sub) => sub.licensePlateCount },
        { title: (sub) => sub.maxVacancy },
        {
          title: (sub) => {
            const status = sub.status.toLowerCase();
            if (status === 'active') return `<span class="pill pill--success">${this.$t('subscriptions.list.status.active')}</span>`;
            return `<span class="pill pill--warning">${this.$t('subscriptions.list.status.inactive')}</span>`;
          }
        },
        // From
        // {
        //   title: (sub) => {
        //     const d = formatDate(sub.startAt, this.timeZone);
        //     return `${d.date}`;
        //   }
        // },
        // To
        // { 
        //   title: (sub) => {
        //     const d = formatDate(sub.endAt, this.timeZone);
        //     return `${d.date}`;
        //   }
        // },
        // Edit
       { 
          title: sub => '',
          buttons: sub => {
            const buttons = [
              {
                label: this.$t('globals.edit'),
                id: sub.id,
                action: (sub) => {
                  this.edit(sub);
                }
              },
              {
                label: this.$t('globals.remove'),
                id: sub.id,
                action: (sub) => {
                  this.remove(sub);
                }
              }
            ];

            return buttons;
          },
          class: 'align-right no-break'
        },
      ]
    }
  },
  computed: {
    timeZone() {
      if (!this.$store.state.parkings?.current?.timeZoneId) return 'Europe/Brussels';
      return this.$store.state.parkings.current.timeZoneId;
    },
    subscriptions() {
      return this.$store.state.subscriptions.items;
    },
    loading() {
      return this.$store.state.subscriptions.requests !== 0;
    },
    theadSubscriptions() {
      return [
        { title: this.$t('subscriptions.list.head.name'), class: "small head" },
        { title: this.$t('subscriptions.list.head.plates_count'), class: "small head" },
        { title: this.$t('subscriptions.list.head.parking_slots'), class: "small head" },
        { title: this.$t('subscriptions.list.head.status'), class: "small head" },
        // { title: 'From', class: "small head" },
        // { title: 'To', class: "small head" },
        { title: this.$t('labels.actions'), class: "small head head-hidden" },
      ]
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    edit(sub) {
      this.$router.push({ name: 'EditSubscription', params: { id: sub.id  } });
    },
    remove(sub) {
      this.$store.commit('popups/updatePopup', { type: 'removeSubscription', property: 'subscription', value: sub });
      this.$store.commit('popups/updatePopup', { type: 'removeSubscription', property: 'visible', value: true });
    },
    onRemoveSuccess() {
      this.$store.dispatch('subscriptions/getSubscriptions');
    }
  }
}
</script>