<template>
  <section class="content-wrapper">
    <div class="container">
      <div class="content-chapters">
        <div class="content-chapter" :key="index" v-for="(prop, index) in props">
          <h2 class="h4 chapter-title">{{prop.title}}</h2>
          <p class="chapter-content regular" v-html="prop.content" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
  },
  props: ['props'],
}
</script>

<style lang="scss">
  .content {
    
    &-wrapper {

      .container {
        display: flex;
        // flex-wrap: wrap;
      }
    }

    

    &-chapters {
      counter-reset: chapterCounter;
    }

    &-chapter {
      counter-increment: chapterCounter;
      margin-bottom: 30px;

      h2 {
        margin: 0 0 20px;
      }

      h2:first-child::before {
        content: counter(chapterCounter, upper-roman) ". ";
        display: inline-block;
        width: 60px;
      }

      p {
        font-size: rem(16px);
        margin-bottom: 10px;

        u {
          text-decoration: underline;
        }
      }

      ol {
        list-style-type: lower-alpha;
        padding: 0 0 0 35px;
        margin-bottom: 10px;
      }

      ul {
        list-style-type: circle;
        padding: 0 0 0 35px;
        margin-bottom: 10px;
      }
    }
  }
</style>
