<template>
  <popup
    v-if="popup.visible"
    :classes="'popup-removebooking'"
  >
    <template v-slot:content>
      <!-- Success -->
      <template v-if="popup.success">
        <h2 class="popup-title">{{ $t('globals.success') }}</h2>
        <div class="popup-main">
          <img class="popup-main-img" src="/img/success.png"/>
          <p class="popup-main-text">{{ $t('bookings.popups.remove.success') }}</p>
        </div>

        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup"
            @click.prevent="close"
          >
            {{ $t('globals.ok') }}
          </button>
        </div>
      </template>

      <!-- Infos -->
      <template v-else>
        <div class="popup-header">
          <h2 class="popup-title h4">{{ $t('bookings.popups.remove.title') }}</h2>
          <p class="popup-main-text" v-html="$t('bookings.popups.remove.text')"></p>
        </div>

        <div class="popup-actions">
          <button
            class="button button-border"
            @click="close"
          >
            {{ $t('globals.close') }}
          </button>
          <button
            class="button"
            @click="remove"
          >
            <!-- :disabled="!popup.value.length" -->
            {{ $t('globals.delete') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Loader,
    Popup,
  },
  data() {
    return {
      type: 'removeBooking'
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.removeBooking;
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'success', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'value', value: null });
    },
    async remove() {
      const booking = this.$store.state.popups.removeBooking.booking;
      let bookingId = 0;

      if (booking) {
        bookingId = booking.id;
      }

      const remove = await this.$store.dispatch('popups/removeBooking', bookingId);
      if (!remove.hasError) this.$emit('onSuccess');
    }
  }
}
</script>