import {
  getServicesList,
} from '@/calls/services';

import router from '../router';

const services = {
  namespaced: true,
  state: {
    tc: [],
    tw: [],
    requests: 0
  },
  mutations: {
    updateItems(state, { type, items }) {
      state[type] = items;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
  },
  actions: {
    async getServicesList({ dispatch }, redirection = false) {
      const currentRoute = router.currentRoute._rawValue;
      await dispatch('getTCList', currentRoute.name.match(/TC/g) && redirection);
      await dispatch('getTWList', currentRoute.name.match(/TW/g) && redirection);
      return true;
    },
    async getTCList({ commit, dispatch }, redirection = false) {
      commit('increaseRequests');

      const request = await getServicesList(2);

      if (!request.hasError) {
        commit('updateItems', { type: 'tc', items: request });
      }

      if (redirection) {
        dispatch('handleRedirect', 'tc');
      }

      commit('decreaseRequests');
      return true;
    },
    async getTWList({ commit, dispatch }, redirection = false) {
      commit('increaseRequests');

      const request = await getServicesList(1);

      if (!request.hasError) {
        commit('updateItems', { type: 'tw', items: request });
      }

      if (redirection) {
        dispatch('handleRedirect', 'tw');
      }

      commit('decreaseRequests');
      return true;
    },
    handleRedirect({ commit, state, rootState }, type) {
      if (!state[type].length) {
        router.push('/');
      } else {
        const selected = rootState[type].selected;
        const first = state[type][0];
        if (!selected || selected !== first.value) {
          commit(`${type}/updateSelected`, first.value, { root: true });
          router.push({ name: router.currentRoute._rawValue.name, params: { serviceId: first.value } });
        }
      }
    }
  }
};

export default services;
