<template>
  <div>
    <table
      v-if="bookings.length > 0"
      class="table"
      cellspacing="0"
    >

      <!-- TABLE HEAD -->
      <Thead
        :head="thead"
        :order="order"
        @onOrder="updateOrder"
      />
      
      <!-- TABLE BODY -->
      <tbody>
        <tr 
          v-for="(booking, ind) in bookings"
          :key="booking.id"
        > 
          <TBody 
            v-for="(b, index) in tbody"
            :key="index"
            :body="b" 
            :el="booking"
            :last="ind === bookings.length - 1"
          />
        </tr>
      </tbody>
    </table>
    <!-- NO RESULTS -->
    <NoResult v-else >
      <slot>{{message}}</slot>
    </NoResult>
  </div>
</template>

<script>
import moment from 'moment';
import {
  formatDate,
  utcTo
} from '@/helpers/helpers';

import NoResult from '@/components/partials/Bookings/NoResult';
import Thead from '@/components/elements/THead';
import TBody from '@/components/elements/TBody';

export default {
  components: {
    NoResult, Thead, TBody
  },
  data() {
    return {
      today: moment().format("DD[.]MM[.]YYYY"),
      tbody: [
        // ID
        {
          title: (booking) => booking.id,
          class: 'id-col'
        },
        // Creation date
        {
          title: (booking) => {
            const d = utcTo(booking.createdAt, this.timeZone);
            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
          }
        },
        // Name
        // { title: (booking) => booking.driverName ? booking.driverName : this.$t('globals.unknow'), class: "nickname" },
        { title: (booking) => this.getDriverName(booking), class: "nickname" },

        // Plate
        { title: (booking) => booking.truckLicenseCountry.toUpperCase() + ' ' +booking.truckLicensePlate + `${booking.trailLicensePlate && booking.truckLicensePlate ? ' - ' : ''}` +  booking.trailLicenseCountry.toUpperCase() + ' ' + booking.trailLicensePlate },
        // Slot type
        { title: (booking) => this.translations[`database.slot.0${booking.package.slotId}.name`] },
        // Arrival
        {
          title: (booking) => {
            if (booking.checkinAt) {
              const d = formatDate(booking.checkinAt, this.timeZone);
                return `
                  ${d.date}
                  <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
                `
            }

            const d = formatDate(booking.startAt, this.timeZone);

            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
           
          },
          class: 'medium-col'
        },
        // Departure
        {
          title: (booking) => {
            if (booking.checkoutAt) {
              const d = formatDate(booking.checkoutAt, this.timeZone);
                return `
                  ${d.date}
                  <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
                `
            }

            const d = formatDate(booking.endAt, this.timeZone);
            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
          },
          class: 'medium-col'
        },
        // Status
        {
          title: (booking) => {
            const { status, arrivalStatus } = booking;
            if (arrivalStatus === 'Spontaneous' || arrivalStatus === 2) return `<span class="status status--spontanious">${this.$t('bookings.status.spontaneous')}</span>`;
            if (arrivalStatus === 'Competitor' || arrivalStatus === 'Subscriber' || arrivalStatus === 6) return `${this.$t('bookings.status.payed')}`;
            if (!status) return '-';
            return this.$t(`bookings.status.${status.toLowerCase()}`);
          },
          class: "small vertical-center"
        },
        //trash
        { 
          title: (booking) => `<span class="u-visually-hidden">${this.$t('globals.delete')}</span>`,
          class: "icon width-36 vertical-center", 
          icon: { type: 'trash', width: 24, height: 24 },
          action: (booking) => this.trashClick(booking),
          condition: () => this.isAdmin
        },
        // Call
        { 
          title: (booking) => `<span class="u-visually-hidden">Call ${booking.driverName ? booking.driverName: ''}</span>`,
          class: "icon width-36 vertical-center", 
          icon: { type: 'message', width: 24, height: 24 },
          action: (booking) => this.phoneClick(booking),
          condition: (booking) => !booking.arrivalStatus || !booking.arrivalStatus.match(/Spontaneous|Competitor/)
        },
        { 
          title: (booking) => `<span class="u-visually-hidden">${this.$t('globals.ticket')}</span>`,
          class: "icon width-36 vertical-center", 
          icon: { type: 'accounting', width: 23, height: 18 },
          action: (booking) => this.ticketClick(booking),
          condition: (booking) => !booking.arrivalStatus || !booking.arrivalStatus.match(/Spontaneous|Competitor/)
        },
        { 
          title: () => '', 
          class: "icon width-50 vertical-center", 
          actions: (booking) => {
            const userIsAdmin = this.isAdmin;
            const { status, arrivalStatus, creationType } = booking;
            const actions = [];

            if (arrivalStatus.match(/Spontaneous|Competitor/)) {
              // Spontaneous booking
              if (arrivalStatus.match(/Spontaneous/)) {
                if (status.match(/Booked|CheckedIn/)) {
                  actions.push({
                    label: this.$t('globals.update'),
                    action: () => {
                      this.updateBooking(booking);
                    }
                  });
                  
                  if (userIsAdmin && status.match(/CheckedIn/)) {
                    actions.push({
                      label: this.$t('globals.checkout'),
                      action: () => {
                        this.open('checkout', booking);
                      }
                    });
                  }
                }

                actions.push({
                  label: this.$t('globals.overrule'),
                  action: () => {
                    this.openReject(booking);
                  }
                });
              }
            } else {

              // Normal booking
              if ((status === 'Booked' || status === 'NoShow') && userIsAdmin) {
                actions.push({
                  label: this.$t('globals.checkin'),
                  action: () => {
                    this.open('checkin', booking);
                  }
                });
              }

              if ((status === 'CheckedIn' && !creationType.match(/SpontaneousArrival/))
                || (userIsAdmin && status === 'CheckedIn' && creationType.match(/SpontaneousArrival/))
                || (userIsAdmin && (status === 'Booked' || status === 'NoShow'))) {
                actions.push({
                  label: this.$t('globals.checkout'),
                  action: () => {
                    this.open('checkout', booking);
                  }
                });

                if (booking.paymentMethod === 'AtParking' && !booking.payedAt) {
                  actions.push({
                  label: this.$t('globals.pay'),
                  action: () => {
                    this.payClick(booking);
                  }
                });
                }
              }
            }

            // Internal
            // Competitor
            // Other
            return actions;
          },
          condition: (booking) => !booking.arrivalStatus || !booking.arrivalStatus.match(/Competitor/)
        }
      ]
    }
  },
  beforeUnmount() {
    this.$store.commit('dashboard/reset');
  },
  computed: {
    timeZone() {
      if (!this.$store.state.parkings?.current?.timeZoneId) return 'Europe/Brussels';
      return this.$store.state.parkings.current.timeZoneId;
    },
    bookings() {
      const { order, items } = this.$store.state.dashboard;
      let ordereds = items
        .filter(booking => booking.arrivalStatus !== 'Internal')
        .sort((a, b) => {
          if(a[order.type] < b[order.type]) return order.dir === 'asc' ? -1 : 1;
          if(a[order.type] > b[order.type]) return order.dir === 'asc' ? 1 : -1;
          return 0;
        });

      return ordereds;
    },
    filters() {
      return this.$store.state.dashboard.filters;
    },
    loading() {
      return this.$store.state.dashboard.requests !== 0;
    },
    translations() {
      return this.$store.state.translations.keys;
    },
    order() {
      return this.$store.state.dashboard.order;
    },
    slots() {
      return this.$store.state.parkings.slots;
    },
    message() {
      return this.$t('home.no_result.default');
    },
    placeholder() {
      return {
        checkin: this.$t('home.no_result.checkin'),
        checkout: this.$t('home.no_result.checkout'),
        parked: this.$t('home.no_result.parked')
      };
    },
    thead() {
      return [
        {
          title: this.$t('home.thead.id'),
          class: "small head id-col",
          type: 'id'
        },
        {
          title: this.$t('home.thead.creation'),
          class: "small head",
          type: 'createdAt'
        },
        {
          title: this.$t('home.thead.nickname'),
          class: "small head"
        },
        {
          title: this.$t('home.thead.plates'),
          class: "small head"
        },
        {
          title: this.$t('home.thead.slot_type'),
          class: "small head"
        },
        {
          title: this.$t('home.thead.arrival'),
          class: "medium-col small head",
          type: 'startAt'
        },
        {
          title: this.$t('home.thead.departure'),
          class: "medium-col small head",
          type: 'endAt'
        },
        {
          title: this.$t('home.thead.status'),
          class: "small",
          type: 'status'
        },
        {
          title: this.$t('home.thead.actions'),
          class: "head-hidden"
        },
        {
          title: this.$t('home.thead.actions'),
          class: "head-hidden"
        },
        {
          title: this.$t('home.thead.actions'),
          class: "head-hidden"
        },
        {
          title: this.$t('home.thead.actions'),
          class: "head-hidden"
        },
      ];
    },
    isAdmin() {
      const user = this.$store.state.user?.userProfile?.user;
      if (!user) return false;
      const { roles } = user;
      if (!roles) return false;
      const index = roles.indexOf('otra-admin');
      return index >= 0;
    }
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD[.]MM[.]YYYY");
    },
    trashClick(booking) {
      this.$store.commit('popups/updatePopup', { type: 'removeBooking', property: 'booking', value: booking });
      this.$store.commit('popups/updatePopup', { type: 'removeBooking', property: 'visible', value: true });
    },
    phoneClick(booking) {
      this.$store.dispatch('popups/getPopupBooking', { popup: 'detail', booking });
      this.$store.commit('popups/updatePopup', { type: 'detail', property: 'visible', value: true });
    },
    ticketClick(booking) { 
      this.$store.dispatch('popups/getPopupBooking', { popup: 'ticket', booking });
      this.$store.commit('popups/updatePopup', { type: 'ticket', property: 'visible', value: true });
    },
    payClick(booking) {
      this.$store.dispatch('popups/getPopupBooking', { popup: 'pay', booking });
      this.$store.commit('popups/updatePopup', { type: 'pay', property: 'visible', value: true });
    },
    openNoshow(booking) {
      this.$store.dispatch('popups/getPopupBooking', { popup: 'noshow', booking });
      this.$store.commit('popups/updatePopup', { type: 'noshow', property: 'visible', value: true });
    },
    openReject(booking) {
      // this.$store.dispatch('popups/getPopupBooking', { popup: 'noshow', booking });
      this.$store.commit('popups/updatePopup', { type: 'reject', property: 'booking', value: booking });
      this.$store.commit('popups/updatePopup', { type: 'reject', property: 'visible', value: true });
    },
    open(type, booking) {
      const tzId = this.$store.state.parkings.current.timeZoneId;
      const date = moment().tz(tzId);
      this.$store.commit('popups/updatePopupField', { type: type, field: 'date', value: date.format('YYYY[-]MM[-]DD') });
      this.$store.commit('popups/updatePopupField', { type: type, field: 'time', value: date.format('HH[:]mm') });
      this.$store.dispatch('popups/getPopupBooking', { popup: type, booking });
      this.$store.commit('popups/updatePopup', { type: type, property: 'visible', value: true });
    },
    updateOrder(type) {
      this.$store.commit('dashboard/updateOrder', type);
    },
    updateBooking(booking) {
      this.$router.push({
        path: `/update/${booking.id}/details`
      });
    },
    getDriverName(booking) {      
        // let driverName = booking?.driverNameCognito;
        // let fleetName = booking?.fleetName;

        // if (!driverName || driverName === 'n.a.'){
        //   driverName = this.$t('globals.unknow')
        // }
  
        // if (fleetName){
        //   driverName += ` (${fleetName})`
        // }
        
        // return driverName
        if (booking?.fleetName){
          return booking?.fleetName
        }
        if (booking?.driverNameCognito && booking?.driverNameCognito !== 'n.a.'){
          return booking?.driverNameCognito
        }
        
        return this.$t('globals.unknow')

    }
  }
}
</script>