<template>
  <popup
    v-if="popup.visible"
    :classes="'popup-remove-plates'"
  >
    <template v-slot:content>
      <div class="popup-header">
        <h2 class="popup-title h4">{{ $t('parkings.plates.popups.remove_multiple.title') }}</h2>
        <p class="popup-main-text">{{ $t('parkings.plates.popups.remove_multiple.text') }}</p>
      </div>

      <div class="popup-actions">
        <button
          class="button button-border"
          @click="close"
        >
          {{ $t('globals.close') }}
        </button>
        <button
          class="button"
          @click="remove"
        >
          {{ $t('globals.remove') }}
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';

export default {
  components: {
    Popup,
  },
  computed: {
    popup() {
      return this.$store.state.popups.removePlates;
    }
  },
  data() {
    return {
      type: 'removePlates'
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'visible', value: false });
      // this.$store.commit('popups/updatePopup', { type: this.type, property: 'success', value: false });
      // this.$store.commit('popups/updatePopup', { type: this.type, property: 'value', value: '' });
    },
    async remove() {
      this.$emit('remove');
    }
  }
};
</script>