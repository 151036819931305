// import { useI18n } from 'vue-i18n';
import i18n from '../i18n';
import {
  getPlates,
  updatePlates
} from '@/calls/plates';

const parkings = {
  namespaced: true,
  state: {
    items: [],
    requests: 0
  },
  mutations: {
    updateItems(state, items) {
      state.items = items;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
  },
  actions: {
    async getPlates ({ commit, dispatch, state }, { id }) {
      commit('increaseRequests');
      const request = await getPlates(id);

      if (request && !request.hasError) {
        commit('updateItems', request.whiteList);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.plates.get', { message: request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    }
  }
};

export default parkings;
