<template>
  <div>
    <table
      v-if="bookings && bookings.length"
      class="table"
      cellspacing="0"
    >
      <!-- TABLE HEAD -->
      <Thead
        :head="thead"
        :order="order"
        @onOrder="updateOrder"
      />

      <!-- TABLE BODY -->
      <tbody>
        <tr 
          v-for="booking in bookings"
          :key="booking.id"
        > 
          <TBody 
            v-for="(b, index) in tbody"
            :key="index"
            :body="b" 
            :el="booking" 
          />
        </tr>
      </tbody>
    </table>

    <!-- NO RESULTS -->
    <NoResult v-if="(!bookings || !bookings.length) && !loading">
      <slot>{{ this.$t('components.stack.no_result') }}</slot>
    </NoResult>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import {
  formatDate,
  utcTo
} from '@/helpers/helpers';

import Thead from '@/components/elements/THead';
import TBody from '@/components/elements/TBody';
import NoResult from '@/components/partials/Bookings/NoResult';

export default {
  components: {
    Thead,
    TBody,
    NoResult
  },
  data() {
    return {
      tbody: [
        // ID
        {
          title: (booking) => booking.id,
          class: 'id-col'
        },
        // Creation date
        {
          title: (booking) => {
            const d = utcTo(booking.createdAt, this.timeZone);
            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
          }
        },
        // Name
        { title: (booking) => this.getDriverName(booking), class: "nickname" },
        // Plate
        { title: (booking) => booking.truckLicenseCountry.toUpperCase() + ' ' + booking.truckLicensePlate + `${booking.trailLicensePlate && booking.truckLicensePlate ? ' - ' : ''}` +  booking.trailLicenseCountry.toUpperCase() + ' ' + booking.trailLicensePlate },
        // Slot type
        { title: (booking) => this.translations[`database.slot.0${booking.package.slotId}.name`] },
        // Arrival
        {
          title: (booking) => {
          
            if (booking.checkinAt) {
              const d = formatDate(booking.checkinAt, this.timeZone);
                return `
                  ${d.date}
                  <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
                `
            }

            const d = formatDate(booking.startAt, this.timeZone);

            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
          },
          class: 'medium-col'
        },
        // Departure
        {
          title: (booking) => {
           
            if (booking.checkoutAt) {
              const d = formatDate(booking.checkoutAt, this.timeZone);
                return `
                  ${d.date}
                  <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
                `
            }

            const d = formatDate(booking.endAt, this.timeZone);
            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
          },
          class: 'medium-col'
        },
        // Status
        {
          title: (booking) => {
            const { status } = booking;
            if (!status) return '-';
            return this.$t(`bookings.status.${status.toLowerCase()}`);
          },
          class: "small"
        },
        //trash
        { 
          title: (booking) => `<span class="u-visually-hidden">${this.$t('globals.delete')}</span>`,
          class: "icon width-36", 
          icon: { type: 'trash', width: 24, height: 24 },
          action: (booking) => this.trashClick(booking),
          condition: () => this.isAdmin
        },
        // Check out / Pay button
        { 
          title: (booking) => '',
          buttons: (booking) => {
            const buttons = [];
            const userIsAdmin = this.isAdmin;
            const now = moment();
            const end = moment(booking.endAt);

            if (userIsAdmin && (booking.status === 'Booked' || booking.status === 'NoShow')) {
              buttons.push({
                label: this.$t('globals.checkin'),
                id: booking.id,
                classes: 'button-border',
                action: (booking) => {
                  this.openCheckin(booking);
                }
              });
            }

            if (booking.status === 'CheckedIn'|| (userIsAdmin && (booking.status === 'Booked' || booking.status === 'NoShow'))) {
              if (end.isBefore(now) && booking.status !== 'NoShow') {
                buttons.push({
                  label: this.$t('globals.noshow'),
                  id: booking.id,
                  classes: 'button-border',
                  action: (booking) => {
                    this.openNoshow(booking);
                  }
                });
              }

              if (!booking.payedAt) {
                buttons.push({
                  label: booking.paymentMethod === 'AtParking' ? this.$t('globals.checkout_pay') : this.$t('globals.checkout'),
                  id: booking.id,
                  classes: '',
                  action: (booking) => {
                    this.openCheckout(booking);
                  }
                })
              }
            }

            return buttons;
          },
          class: 'align-right no-break'
        },
      ]
    }
  },
  computed: {
    timeZone() {
      if (!this.$store.state.parkings?.current?.timeZoneId) return 'Europe/Brussels';
      return this.$store.state.parkings.current.timeZoneId;
    },
    bookings() {
      return this.$store.state.dashboard.items;
    },
    loading() {
      return this.$store.state.dashboard.requests !== 0 || this.$store.state.parkings.requests.parking !== 0;
    },
    translations() {
      return this.$store.state.translations.keys;
    },
    order() {
      return this.$store.state.dashboard.order;
    },
    slots() {
      return this.$store.state.parkings.slots;
    },
    thead() {
      return [
        {
          title: this.$t('home.thead.id'),
          class: "small head id-col",
          type: 'id'
        },
        {
          title: this.$t('home.thead.creation'),
          class: "small head",
          type: 'createdAt'
        },
        {
          title: this.$t('home.thead.nickname'),
          class: "small head"
        },
        {
          title: this.$t('home.thead.plates'),
          class: "small head"
        },
        {
          title: this.$t('home.thead.slot_type'),
          class: "small head"
        },
        {
          title: this.$t('home.thead.arrival'),
          class: "medium-col small head",
          type: 'startAt'
        },
        {
          title: this.$t('home.thead.departure'),
          class: "medium-col small head",
          type: 'endAt'
        },
        {
          title: this.$t('home.thead.status'),
          class: "small"
        },
        {
          title: this.$t('home.thead.actions'),
          class: "head-hidden"
        },
        {
          title: this.$t('home.thead.actions'),
          class: "head-hidden"
        },
      ];
    },
    isAdmin() {
      const user = this.$store.state.user?.userProfile?.user;
      if (!user) return false;
      const { roles } = user;
      if (!roles) return false;
      const index = roles.indexOf('otra-admin');
      return index >= 0;
    }
  },
  beforeUnmount() {
    this.$store.commit('dashboard/reset');
  },
  methods: {
    updateOrder(type) {
      const { selected } = this.$store.state.parkings;
      this.$store.commit('dashboard/updateOrder', type);
      this.$store.dispatch('dashboard/setStack', { ParkingId: selected });
    },
    openCheckin(booking) {
      const tzId = this.$store.state.parkings.current.timeZoneId;
      const date = moment().tz(tzId);
      this.$store.commit('popups/updatePopupField', { type: 'checkin', field: 'date', value: date.format('YYYY[-]MM[-]DD') });
      this.$store.commit('popups/updatePopupField', { type: 'checkin', field: 'time', value: date.format('HH[:]mm') });
      this.$store.dispatch('popups/getPopupBooking', { popup: 'checkin', booking });
      this.$store.commit('popups/updatePopup', { type: 'checkin', property: 'visible', value: true });
    },
    openNoshow(booking) {
      this.$store.dispatch('popups/getPopupBooking', { popup: 'noshow', booking });
      this.$store.commit('popups/updatePopup', { type: 'noshow', property: 'visible', value: true });
    },
    openCheckout(booking) {
      const tzId = this.$store.state.parkings.current.timeZoneId;
      const date = moment().tz(tzId);
      this.$store.commit('popups/updatePopupField', { type: 'checkout', field: 'date', value: date.format('YYYY[-]MM[-]DD') });
      this.$store.commit('popups/updatePopupField', { type: 'checkout', field: 'time', value: date.format('HH[:]mm') });
      this.$store.dispatch('popups/getCheckoutBooking', { popup: 'checkout', booking });
      this.$store.commit('popups/updatePopup', { type: 'checkout', property: 'visible', value: true });
    },
    getDriverName(booking) {      
       
        // return driverName
        if (booking?.fleetName){
          return booking?.fleetName
        }
        if (booking?.driverNameCognito && booking?.driverNameCognito !== 'n.a.'){
          return booking?.driverNameCognito
        }
        
        return this.$t('globals.unknow')

    },
    trashClick(booking) {
      this.$store.commit('popups/updatePopup', { type: 'removeBooking', property: 'booking', value: booking });
      this.$store.commit('popups/updatePopup', { type: 'removeBooking', property: 'visible', value: true });
    }
  }
}
</script>