<template>
  <article class="page">
    <h1 class="page-title container">{{ $t('help.title') }}</h1>
    <h2 class="page-help-subtitle">{{ $t('help.subtitle') }}</h2>
    <Components :components="components" />
  </article>
</template>

<script>
import Components from '@/components/partials/Components.vue';

export default {
  name:"Help",
  components: { 
    Components
  },
  computed: {
    components() {
      return {
        TwoColumns: [
          {
            title: this.$t('help.two_columns.account.title'),
            content: this.$t('help.two_columns.account.content'),
            // subtitle: 'Opening hour: 9 am to 6 pm (UTC)',
            // phone: '+32 2 400 22 18',
            mail: this.$t('help.two_columns.account.mail')
          },
          {
            title: this.$t('help.two_columns.operational.title'),
            content: this.$t('help.two_columns.operational.content'),
            mail: this.$t('help.two_columns.operational.mail')
          },
          {
            title: this.$t('help.two_columns.sales.title'),
            content: this.$t('help.two_columns.sales.content'),
            mail: this.$t('help.two_columns.sales.mail')
          }
        ],
        Faqs: {
          title: this.$t('help.faqs.title'),
          questions: this.faqs_questions()
        }
      }
    }
  },
  methods: {
    faqs_questions() {
      const questions = [];

      for (let i = 0; i < 7; i++) {
        questions.push({
          title: this.$t(`help.faqs.questions.${i}.title`),
          content: this.$t(`help.faqs.questions.${i}.content`)
        });
      }

      return questions;
    }
  }
}
</script>

<style lang="scss">
  .page {
    padding: 40px;

    &-title {
      font-size: rem(34px);
      letter-spacing: 0;
      font-weight: 400;
      text-align: center;
    }

    &-help-subtitle {
      margin-top: 60px;
      font-weight: 400;
      font-size: rem(24px);
      color: $black;
      text-align: center;
      text-transform: uppercase;
    }
  }
</style>
