<template>
  <nav 
    class="sidebar"
    :class="{'sidebar--small': !sidebar}"
  >
    <ul>
      <li
        v-for="route in routes"
        :key="route.name"
      >
        <!-- Link -->
        <router-link
          class="sidebar-item"
          :class="{
            'has-children': route.submenu,
            'is-active': route.isActive
          }"
          :to='{
            name: route.name,
            params: $route.params
          }'
        >
          <span class="sidebar-item__content">
            <Sprite 
              :type="route.icon"
              :width="18"
              :height="18"
            />
            <span class="sidebar-text">{{ $t(route.label) }}</span>
          </span>
          <Sprite 
            v-if="route.submenu"
            :type="'chevron'" 
            :width="12"
            :height="12"
          />
        </router-link>

        <!-- Submenu -->
        <ul
          v-if="route.submenu"
          class="sidebar-children"
        >
          <li>
            <router-link 
              class="small sidebar-children-item"
              :class="{
                'is-active': $route.name === route.name || (route.name === 'Home' && $route.name.match(/UpdateDetails|UpdateDuration|UpdateReview/))
              }"
              :to='{
                name: route.name,
                params: { id }
              }'
            >
              <Sprite 
                :type="route.submenu.icon"
                :width="18"
                :height="18"
              />
              {{ $t(route.submenu.label) }}
            </router-link>
          </li>
          <li 
            v-for="child in route.submenu.routes"
            :key="child.name"
          >
            <router-link 
              class="small sidebar-children-item"
              :class="{
                'is-active': child.isActive
              }"
              :to='{
                name: child.name,
                params: {
                  id
                }
              }'
            >
              <Sprite 
                :type="child.icon"
                :width="18"
                :height="18"
              />
              {{ $t(child.label) }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>

  </nav>
</template>

<script>
import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: 'Sidebar',
  components: { 
    Sprite 
  },
  data() {
    return {
      isSmall: false
    };
  },
  computed: {
    id() {
      return this.$store.state.parkings.selected;
    },
    router() {
      return this.$router.options.routes.filter(r => !r.meta.subbar && !r.meta.notInSidebar);
    },
    routes() {
      const { context } = this.$store.state.settings;
      const isOwner = context && context.parkingManager && context.parkingManager.isOwner;
      const isAdmin = context && context.isAdmin;

      const raw = this.$router.getRoutes();
      const { $route } = this;

      const filtereds = raw
        .filter(r => r.meta && r.meta.menu)
        .filter(r => {
          if (r.meta.menu.admin) return isOwner || isAdmin;
          return true;
        })
        .filter(r => r.name !== 'Whitelist' || (this.$store.state.parkings.isIntegrated && this.$store.state.parkings.whitelistable))
        .filter(r => r.name !== 'Subscriptions' || this.$store.state.parkings.subscribable)
      ;
      const subLevelRoutes = [];

      const routes = filtereds
        .map(r => {
          const route = {
            name: r.name,
            order: r.meta.menu.order,
            label: r.meta.menu.label,
            icon: r.meta.menu.icon,
            isActive: $route.name === r.name || (r.name === 'Home' && $route.name.match(/UpdateDetails|UpdateDuration|UpdateReview|Subscriptions|EditSubscription|CreateSubscription/))
          };

          const { menu } = r.meta;
          if (menu.submenu) {
            // Set submenu
            route.submenu = {
              label: menu.submenu.label,
              icon: menu.submenu.icon,
              routes: []
            };

            // Get route and put it in submenu
            raw.map(item => {
              if (menu.submenu.items.indexOf(item.name) >= 0 && item.meta.menu) {
                if (item.name === 'Whitelist' && (!this.$store.state.parkings.isIntegrated || !this.$store.state.parkings.whitelistable)) return;
                if (item.name === 'Subscriptions' && !this.$store.state.parkings.subscribable) return;

                // if ((item.name !== 'Whitelist' || this.$store.state.parkings.isIntegrated)){
                  subLevelRoutes.push(item.name);
                  if ($route.name === item.name) route.isActive = true;
                  route.submenu.routes.push({
                    name: item.name,
                    label: item.meta.menu.label,
                    icon: item.meta.menu.icon,
                    order: item.meta.menu.order,
                    isActive: $route.name === item.name || (item.name === 'Subscriptions' && $route.name.match('EditSubscription|CreateSubscription'))
                  });
                // }
              }

              return;
            });

            // Order submenu routes
            route.submenu.routes = route.submenu.routes.sort((a, b) => {
              if(a.order < b.order) return -1;
              if(a.order > b.order) return 1;
              return 0;
            });
          }

          return route;
        })
        // Remove firstlavel route that are in submenu
        .filter(r => subLevelRoutes.indexOf(r.name) < 0)
        // Order routes
        .sort((a, b) => {
          if(a.order < b.order) return -1;
          if(a.order > b.order) return 1;
          return 0;
        });

      return routes;
    },
    sidebar() {
      return this.$store.state.user.sidebar;
    },
    burgerClick() {
      return this.$store.state.user.burgerClick;
    },
    current() {
      return this.$route;
    },
    hasChildren() {
      return this.$route.children && this.$route.meta.displayChildren;
    }
  },
  methods: {
    setPath(path) {
      const the_path = path.replace(':id', this.id);
      if(the_path.includes('undefined')) return '/';
      return the_path;
    },
    handleHover() {
      if(this.burgerClick && window.innerWidth >= 1201) this.$store.dispatch('setSidebar');
    }
  }
}
</script>
