<template>
  <!-- Popup Instruction -->
  <popup
    v-if="popup.visible"
    :classes="'popup-instructions'"
  >
    <template v-slot:content>
      <!-- Success -->
      <template v-if="popup.success">
        <h2 class="popup-title">{{ $t('globals.success') }}</h2>
        <div class="popup-main">
          <img class="popup-main-img" src="/img/success.png"/>
          <p class="popup-main-text">{{ $t('components.stack.popups.instructions.success') }}</p>
        </div>

        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup"
            @click.prevent="close('instructions')"
          >
            {{ $t('globals.ok') }}
          </button>
        </div>
      </template>

      <!-- Form -->
      <template v-else>
        <div class="popup-header">
          <h2 class="popup-title h4">{{ $t('components.stack.popups.instructions.title') }}</h2>
        </div>

        <form class="popup-form">
          <Inputs
            ref="description"
            type="textarea" 
            :error="popup.errors.description"
            :required="true"
            :value="popup.value"
            @input="updateInstructions"
          />
        </form>

        <div class="popup-actions">
          <button
            class="button button-ghost"
            @click="close('instructions')"
          >
            {{ $t('globals.cancel') }}
          </button>
          <button
            class="button"
            @click="saveInstructions"
            :disabled="disabled"
          >
            {{ $t('globals.update') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Inputs from '@/components/elements/Inputs';
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Inputs,
    Loader,
    Popup,
  },
  data() {
    return {
      type: 'instructions'
    }
  },
  computed: {
    popup() {
      return this.$store.state.parkings.popups.instructions;
    },
    disabled() {
      return this.popup.startValue === this.popup.value || (!this.popup.startValue && !this.popup.value);
    }
  },
  methods: {
    updateInstructions(e) {
      if(!e) return;
      const { target } = e;
      const { value } = target;
      this.$store.commit('parkings/updatePopup', {
        type: 'instructions', property: 'value', value
      });
    },
    saveInstructions() {
      this.$store.dispatch('parkings/saveInstructions');
    },
    close(popup) {
      this.$store.commit('parkings/updatePopup', { type: this.type, property: 'visible', value: false });
      this.$store.commit('parkings/updatePopup', { type: this.type, property: 'success', value: false });
      this.$store.commit('parkings/updatePopup', { type: this.type, property: 'value', value: null });
    }
  }
}
</script>