<template>
  <table class="table table-slots"  cellspacing="0">
    <thead>
      <tr>
        <th></th>
        <th 
          v-for="val in sortedHead" 
          :key="val.value"
          class="small"
          :style="`width: ${cellWidth}% !important`"
        >
          {{ headToShow(val) }}
        </th>
        <th
          v-for="missing in missingTH"
          :key="`missing-th-slots-${missing}`"
          :style="`width: ${cellWidth}% !important`"
        />
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td 
          v-for="val in slots" 
          :key="val.type"
          :style="`width: ${cellWidth}% !important`"
        >
          <!-- @click="onClick" -->
          <div :class="{'no-editable': !val.totalSlotNumber}">
            <span
              class="edit"
              :class="{ 'is-focus': val.id === nowEdit.id && nowEdit.type === 'otra' }"
            >
              <button
                v-if="val.totalSlotNumber"
                class="edit-toggle"
                @click="toggle(val, 'otra')"
              >
                <sprite
                  :type="'edit'"
                  :width="13"
                  :height="13"
                />
                <sprite
                  :type="'check'"
                  :width="13"
                  :height="13"
                />
              </button>
              <span 
                :id="`otra-${val.id}`"
                :contenteditable="val.totalSlotNumber && val.id === nowEdit.id && nowEdit.type === 'otra' ? true : false"
                class="editable"
                :max="val.totalSlotNumber"
                :initialValue="val.otraSlotNumber"
                @keydown="handleInput"
                @keyup="handleKeyUp"
                @focusout="handleFocusOut"
                @click="toggle(val, 'otra', false)"
              >
                <!-- :class="{'editable': val.totalSlotNumber}" -->
                <!-- :contenteditable="val.totalSlotNumber ? true : false" -->
                {{ val.otraSlotNumber }}
              </span>
            </span>
            /
            <span
              class="edit"
              :class="{ 'is-focus': val.id === nowEdit.id && nowEdit.type === 'total' }"
            >
              <span 
                :id="`total-${val.id}`"
                :class="'editable'"
                :contenteditable="val.id && val.id === nowEdit.id && nowEdit.type === 'total'"
                :initialValue="val.totalSlotNumber"
                @keydown="handleInput"
                @keyup="handleKeyUp"
                @focusout="handleFocusOut"
                @click="toggle(val, 'total', false)"
              >
                <!-- :contenteditable="val.totalSlotNumber && val.id === nowEdit.id && nowEdit.type === 'total'" -->
                {{ val.totalSlotNumber }}
              </span>
                <!-- v-if="val.totalSlotNumber" -->
              <button
                v-if="val.id"
                class="edit-toggle"
                @click="toggle(val, 'total')"
              >
                <sprite
                  :type="'edit'"
                  :width="13"
                  :height="13"
                />
                <sprite
                  :type="'check'"
                  :width="13"
                  :height="13"
                />
              </button>
            <!-- {{val.totalSlotNumber}}  -->
            </span>
          </div>
        </td>
        <td
          v-for="missing in missingTD"
          :key="`missing-td-slots-${missing}`"
          :style="`width: ${cellWidth}% !important`"
        />
      </tr>
    </tbody>
  </table>
</template>

<script>
import _debounce from 'lodash/debounce';

import Sprite from '@/components/elements/Sprite';

export default {
  name: 'TableSlots',
  components: {
    Sprite
  },
  props: {
    head: {
      type: Object,
      required: true
    },
    body: {
      type: Object,
      required: true
    },
    headToShow: {
      type: [String, Function],
      required: true
    },
    bodyToShow: {
      type: [Array],
      required: true
    },
  },
  data() {
    return {
      nowEdit: {
        id: null,
        type: null
      }
    }
  },
  computed: {
    sortedHead() {
      const head = [...this.head].sort((a, b) => {
        if(a.id < b.id) return -1;
        if(a.id > b.id) return 1;
        return 0;
      });

      return head;
    },
    cellWidth() {
      if (!this.sortedHead || !this.sortedHead.length) return 25;
      const width = 100 / this.sortedHead.length;
      return width;
    },
    slots() {
      let slots = this.head.map(s => {
        const places = this.body.filter(b => b.slotId === Number(s.id));
        // const places = this.body.filter(b => b.slotId === Number(s.value));
        return {
          value: s.value,
          otraSlotNumber : places && places[0] && places[0].otraSlotNumber ? places[0].otraSlotNumber : 0,
          totalSlotNumber : places && places[0] && places[0].totalSlotNumber ? places[0].totalSlotNumber : 0,
          id: places && places[0] ? places[0].slotId : null,
        }
      });

      slots = slots.sort((a, b) => {
        if(a.id < b.id) return -1;
        if(a.id > b.id) return 1;
        return 0;
      });

      return slots;
    },
    missingTH() {
      const nbr = 4 - this.head.length;
      return Math.max(nbr, 0);
    },
    missingTD() {
      const nbr = 4 - this.slots.length;
      return Math.max(nbr, 0);
    },
  },
  methods: {
    toggle(val, type, force = true) {
      const $edit = this.$el.querySelector(`#${type}-${val.id}`);

      if (type === 'otra' && !val.totalSlotNumber) {
        return;
      }

      if (val.id === this.nowEdit.id && type === this.nowEdit.type) {
        if (force) {
          this.close();
          this.save($edit, val, type);
          return;
        }
      }

      this.nowEdit.id = val.id;
      this.nowEdit.type = type;

      if ($edit) {
        setTimeout(function() {
          $edit.focus();
        }, 50);
      }
    },
    close() {
      this.nowEdit.id = null;
      this.nowEdit.type = null;
    },
    save($el, val, type) {
      const value = Number($el.innerText);

      if (value === val) {
        return;
      }

      const params = {
        id: Number($el.id.replace(/otra-|total-/g, '')),
        otraSlotNumber: type === 'otra' ? value : val.otraSlotNumber,
        totalSlotNumber: type === 'total' ? value : val.totalSlotNumber
      };

      this.$store.dispatch('parkings/setSlotsValues', params);
    },
    // setPlaces() {
    //   this.slots = this.slots.map(s => {
    //     const places = this.body.filter( b => b.slotId === Number(s.value));
    //     return {
    //       value: s.value,
    //       otraSlotNumber : places && places[0] && places[0].otraSlotNumber ? places[0].otraSlotNumber : 0,
    //       totalSlotNumber : places && places[0] && places[0].totalSlotNumber ? places[0].totalSlotNumber : 0,
    //       id: places && places[0] ? places[0].id : null,
    //     }
    //   });
    // },
    handleInput(evt) {
      const pass = [8, 37, 38, 39, 40, 46]
      const { key, keyCode } = evt;
      const is = this.isNumbers(key); 

      if(!is && pass.indexOf(keyCode) === -1) evt.preventDefault();
    },
    handleKeyUp(evt) {
      const { target, key, keyCode } = evt;
      const { id } = target;
      const isTotal = id.match(/total-/g);

      // On enter
      if (keyCode === 13) {
        const valId = Number(id.replace(/otra-|total-/, ''));
        const val = this.slots.filter(s => s.id === valId)[0];
        const type = isTotal ? 'total' : 'otra';

        this.save(target, val, type);
        this.close();
        return;
      }

      if (isTotal) {
        return;
      }

      const { innerText } = target;

      if (!innerText) {
        return;
      }

      const value = Number(innerText);
      const max = target.getAttribute('max');

      if (value > max) {
        target.innerText = max;
      }
    },
    handleFocusOut(evt) {
      const { target } = evt;
      const value = target.innerText;
      const inital = target.getAttribute('initialvalue');

      if(!value) {
        target.innerText = inital
        return ;
      }
    },
    isNumbers(t) {
      var regex = /\d/g;
      return regex.test(t);
    },
    debouncedUpdate: _debounce(function(payload) {
      this.$store.dispatch('parkings/setSlotsValues', payload)
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
  .small {
    color: $grey;
    text-align: center;
  }

  /* td div {
    text-align: center;
  } */

  .error {
    color: $error !important;

    &:hover, &:focus {
      color: $error !important;
    }
  }
</style>
