import i18n from '../i18n';
import {
  getBookings,
  getStatus,
  getStackBookings,
} from '@/calls/bookings';
import { getStats } from '@/calls/parkings';

import moment from 'moment-timezone';

const bookings = {
  namespaced: true,
  state: {
    items: [],
    status: [],
    updated: null,
    requests: 0,

    order: {
      type: 'createdAt',
      dir: 'asc'
    },
    filters: {
      search: null,
      dates: {
        start: moment().format("YYYY[-]MM[-]DD[T]00:00:00"),
        end: moment().format("YYYY[-]MM[-]DD[T]23:59:59"),
      }
    },

    stats: {
      parkingId: null,
      requests: 0,
      items: []
    },
  },
  mutations: {
    updateStatus(state, status) {
      state.status = status;
    },
    updateItems(state, items) {
      state.items = items;
    },
    updateUpdated(state, updated) {
      state.updated = updated;
    },
    updateOrder(state, type) {
      if (state.order.type === type) {
        state.order.dir = state.order.dir === 'asc' ? 'desc' : 'asc';
        return;
      }

      state.order.type = type;
      state.order.dir = 'asc';
    },
    updateFilters(state, { property, value }) {
      state.filters[property] = value;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },

    // Stats
    updateStats(state, { property, value }) {
      state.stats[property] = value;
    },

    // Reset
    reset(state) {
      state.items = [];
      state.order = { type: 'createdAt', dir: 'asc' };
      state.filters =  {
        search: null,
        dates: {
          start: moment().format("YYYY[-]MM[-]DD[T]00:00:00"),
          end: moment().format("YYYY[-]MM[-]DD[T]23:59:59"),
        }
      };
    }
  },
  actions: {
    async getData({ rootState, state, commit, dispatch }, { ParkingId }) {
      const { isIntegrated } = rootState.parkings;

      if (isIntegrated) {
        dispatch('setIntegrated', { ParkingId });
      } else {
        dispatch('setStack', { ParkingId });
      }
    },
    // Dashboard
    async setIntegrated({ state, commit, dispatch }, { ParkingId }) {
      commit('increaseRequests');

      const { dates, search } = state.filters;
      const { start, end } = dates;
      const bookings = await getBookings({
        ParkingId,
        startAt: start,
        endAt: end,
        search
      });

      if (!bookings.hasError) {
        commit('updateItems', bookings.map(b => {
          // b.status = 'CheckedIn';
          // b.arrivalStatus = 'Spontaneous';
          return b;
        }));
        dispatch('getStats', { id: ParkingId });
      } else {
        const error = `<br>${bookings.error}`;
        const { t } = i18n.global;

        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.bookings.getting', { message: error }),
          type: 'error'
        }], { root: true });
      }

      commit('updateUpdated', Date.now());
      commit('decreaseRequests');
    },
    async setStack({ state, commit, dispatch }, { ParkingId }) {
      commit('increaseRequests');

      const { type, dir } = state.order;
      const types = { id: 0, startAt: 1, endAt: 2, createdAt: 3 };
      const bookings = await getStackBookings({
        ParkingId,
        order: types[type],
        dir: dir === 'asc' ? 0 : 1
      });

      if (!bookings.hasError) {
        commit('updateItems', bookings);
      } else {
        const { t } = i18n.global;
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.bookings.getting', { message: bookings.error}),
          type: 'error'
        }], { root: true });
      }

      commit('updateUpdated', Date.now());
      commit('decreaseRequests');
    },
    async getStats({ commit, state }, { id, force = false }) {
      commit('updateStats', {
        property: 'requests',
        value: state.stats.requests++
      });

      if (force || (state.stats.items.length === 0 || id !== state.stats.parkingId)) {
        commit('updateStats', {
          property: 'parkingId',
          value: id
        });
        const start = state.filters?.dates?.start;
        let stats = await getStats(id, moment(start).format('YYYY-MM-DD[T]HH:mm:ss'));

        if (!stats.hasError) {
          stats = stats.map(stat => {
            stat.name = stat.slot.translation.name;
            stat.occupancy = 0;
            return stat;
          });

          commit('updateStats', {
            property: 'items',
            value: stats
          });
        }
      }

      const slots = state.stats.items;
      const { items } = state;

      slots.map(slot => {
        slot.occupancy = items.filter(booking => booking.package && booking.package.slotId === slot.slotId).length;
        return slot;
      });

      commit('updateStats', {
        property: 'requests',
        value: state.stats.requests--
      });
    },

    setStatus({ commit }, ) {
      // 0: {value: "10", label: "Booked"}
      // 1: {value: "21", label: "CheckedIn"}
      // 2: {value: "30", label: "CheckedOut"}
      const status = getStatus();
      const noIncluding = ["40", "20", "0"];
      return status.then((promise) => {
        const filtered = promise.status.filter((p) => noIncluding.indexOf(p.value) === -1 );
        return commit('updateStatus', filtered);
      });
    }
  }
};

export default bookings;
