import { createRouter, createWebHistory } from 'vue-router'

// Authentication
import Authentication from '@/views/Authentication'
// import Home from '@/views/Home'
// Parking
import ParkingIndex from '@/views/parkings/Index';
import Dashboard from '@/views/Dashboard';
import Whitelist from '@/views/parkings/Whitelist';
import SlotsAndPrices from '@/views/parkings/SlotsAndPrices';
// Parkings - Bookings
import Sale from '@/views/parkings/bookings/Sale';
import List from '@/views/parkings/bookings/List';
import Update from '@/views/parkings/bookings/Update';
import Review from '@/views/parkings/bookings/update/Review';
import Details from '@/views/parkings/bookings/update/Details';
import Duration from '@/views/parkings/bookings/update/Duration';
// Parkings - Subscriptions
import Subscriptions from '@/views/parkings/subscriptions/List';
import EditSubscription from '@/views/parkings/subscriptions/Edit';
import CreateSubscription from '@/views/parkings/subscriptions/Create';

// TC
import TCIndex from '@/views/tc/Index';
import TCList from '@/views/tc/List';
// import TCReporting from '@/views/tc/Reporting';
// TW
import TWIndex from '@/views/tw/Index';
import TWList from '@/views/tw/List';
// import TWReporting from '@/views/tc/Reporting';
// Settings
import Users from '@/views/settings/Users';
// Help
import Help from '@/views/pages/Help';
// Static
import Terms from '@/views/pages/Terms';
import Accounting from '@/views/pages/Accounting';
import EditProfile from '@/views/profile/Edit';

// Page
import Page from '@/views/Page'

import LicenseChecker from '@/views/pages/LicenseChecker';

const routes = [
  // Parking
  {
    path: '/',
    redirect: '/parkings'
  },
  // Parkings
  {
    path: '/parkings',
    name: 'ParkingIndex',
    component: ParkingIndex,
    redirect: '/parkings/dashboard',
    meta: {
      namespace: 'parking',
      level: 1,
      order: 1,
      title: 'pages.home.sidebar_title',
    },
    children: [
      {
        path: '/parkings/dashboard',
        name: 'Home',
        component: Dashboard,
        meta: {
          namespace: 'parking',
          level: 2,
          order: 0,
          title: 'pages.home.page_title',
          icon: 'ticket',
          components: {
            QuickSearch: true,
            Calendar: true,
            // Status: true,
            // Time: true,
            // Stats: true,
          }

          // parent: 'parking',
          // titleTemplate: 'pages.home.page_title',
          // menuName: 'pages.home.sidebar_title',
          // icon: 'car',
          // menu: {
          //   order: 1,
          //   label: 'pages.home.sidebar_title',
          //   icon: 'car',
          //   submenu: {
          //     label: 'pages.home.sidebar_link',
          //     icon: 'ticket',
          //     items: ['Booking', 'Sale', 'Whitelist', 'SlotsAndPrices', 'Subscriptions']
          //   }
          // },
        },
      },
      // Whitelist
      {
        path: '/parkings/whitelist',
        name: 'Whitelist',
        component: Whitelist,
        meta: {
          namespace: 'parking',
          level: 2,
          order: 3,
          title: 'pages.whitelist.page_title',
          titleMenu: 'pages.whitelist.sidebar_title',
          icon: 'ticket',

          // parent: 'parking',
          // titleTemplate: 'pages.whitelist.page_title',
          // // menuName: 'pages.whitelist.sidebar_title',
          // // icon: 'car',
          // menu: {
          //   order: 3,
          //   label: 'pages.whitelist.sidebar_title',
          //   icon: 'ticket'
          // },
          // components: {
          //   // Pagination: true,
          // }
        }
      },
      // Subscriptions
      {
        path: '/parkings/subscriptions',
        name: 'Subscriptions',
        component: Subscriptions,
        meta: {
          namespace: 'parking',
          level: 2,
          order: 4,
          title: 'pages.subscriptions.page_title',
          icon: 'ticket',

          // parent: 'parking',
          // titleTemplate: 'pages.subscriptions.page_title',
          // menu: {
          //   order: 3,
          //   label: 'pages.subscriptions.page_title',
          //   icon: 'ticket'
          // },
          // components: {},
        },
        children: [
          {
            path: '/parkings/subscriptions/edit/:id',
            name: 'EditSubscription',
            component: EditSubscription, 
            meta: {
              parent: 'parking',
              titleTemplate: 'pages.subscriptions.edit.page_title',
              // components: {
              //   // AddButton: 'pages.settings.users.add_button'
              // }
            }
          },
          {
            path: '/parkings/subscriptions/new',
            name: 'CreateSubscription',
            component: CreateSubscription,
            meta: {
              // parent: 'parking',
              // titleTemplate: 'pages.subscriptions.create.page_title',
              // components: {}
            }
          },
        ]
      },
      // Bookingd
      {
        path: '/parkings/bookings',
        name: 'Booking',
        component: List,
        meta: {
          namespace: 'parking',
          level: 2,
          order: 1,
          title: 'pages.bookings.list.page_title',
          icon: 'ticket',
          components: {
            Time: false,
            Calendar: true,
            QuickSearch: true,
          },

          // parent: 'parking',
          // titleTemplate: 'pages.bookings.list.page_title',
          // menuName: 'pages.bookings.list.sidebar_title',
          // menu: {
          //   label: 'pages.bookings.list.sidebar_title',
          //   icon: 'ticket',
          //   order: 2
          // },
        },
      },
      {
        path: '/parkings/booking_entries',
        name: 'Sale',
        component: Sale, 
        meta: {
          namespace: 'parking',
          level: 2,
          order: 2,
          title: 'pages.bookings.entries.page_title',
          icon: 'ticket',
          components: {
            Calendar: true
            // Time: false,
          },

          // parent: 'parking',
          // titleTemplate: 'pages.bookings.entries.page_title',
          // menu: {
          //   label: 'pages.bookings.entries.page_title',
          //   icon: 'ticket',
          //   order: 3
          // },
        }
      },
      // Slots
      {
        path: '/parkings/slots_prices',
        name: 'SlotsAndPrices',
        component: SlotsAndPrices,
        meta: {
          namespace: 'parking',
          level: 2,
          order: 5,
          title: 'pages.slots_prices.page_title',
          icon: 'settings',
          components: {
            Available: true
          },

          // parent: 'parking',
          // titleTemplate: 'pages.slots_prices.page_title',
          // icon: 'settings',
          // noChildren: true
        },
        // children: [
        //   {
        //     path: '/parkings/slots_prices',
        //     name: 'SlotsAndPrices',
        //     component: SlotsAndPrices,
        //     meta: {
        //       namespace: 'parking',
        //       level: 2,
        //       title: 'Slot types & fares',
        //       icon: 'settings',
        //       components: {
        //         Available: true
        //       }

        //       // parent: 'parking',
        //       // titleTemplate: 'pages.slots_prices.page_title',
        //       // menu: {
        //       //   label: 'pages.slots_prices.page_title',
        //       //   icon: 'settings',
        //       //   order: 4
        //       // },
        //     }
        //   },
        // ]
      },
      // Reporting
      {
        path: '/parkings/reporting',
        name: 'Accounting',
        component: Accounting,
        meta: {
          namespace: 'parking',
          level: 2,
          order: 6,
          title: 'pages.reporting.page_title',
          icon: 'ticket',
          restricted: true,

          // titleTemplate: 'pages.reporting.page_title',
          // layout: 'page',
          // subbar: true,
          // menu: {
          //   order: 2,
          //   label: 'pages.reporting.page_title',
          //   icon: 'ticket',
          // },
          // components: {
          //   Pagination: true,
          // }
        },
    
      },
      //license checker
      {
        path: '/parkings/licenseChecker',
        name: 'LicenseChecker',
        component: LicenseChecker,
        meta: {
          namespace: 'parking',
          level: 2,
          order: 7,
          title: 'pages.licenseChecker.page_title',
          icon: 'ticket',
          restricted: true ,
          components: {            
            QuickSearch: true,
          },       
        }
    
      }
    ]
  },
  // TW
  {
    path: '/tw/:serviceId',
    name: 'TWIndex',
    component: TWIndex,
    redirect: to => {
      return { name: 'TWReservations', params: { serviceId: to.params.serviceId } }
    },
    // redirect: '/tw/:serviceId/reservations',
    meta: {
      namespace: 'tw',
      level: 1,
      order: 2,
      title: 'pages.tw.index.page_title',
    },
    children: [
      {
        path: '/tw/:serviceId/reservations',
        name: 'TWReservations',
        component: TWList,
        meta: {
          namespace: 'tw',
          level: 2,
          order: 0,
          title: 'pages.tw.list.page_title',
          icon: 'ticket',
          components: {
            Calendar: true,
          }
        }
      },
      {
        path: '/tw/:serviceId/reporting',
        name: 'TWReporting',
        component: Accounting,
        meta: {
          namespace: 'tw',
          level: 2,
          order: 0,
          title: 'pages.tw.reporting.page_title',
          icon: 'ticket',
          restricted: true,
        }
      },
    ]
  },
  // TC
  {
    path: '/tc',
    name: 'TCIndex',
    component: TCIndex,
    redirect: to => {
      return { name: 'TCReservations', params: { serviceId: to.params.serviceId } }
    },
    // redirect: '/tc/:serviceId/reservations',
    meta: {
      namespace: 'tc',
      level: 1,
      order: 3,
      title: 'pages.tc.index.page_title',
    },
    children: [
      {
        path: '/tc/:serviceId/reservations',
        name: 'TCReservations',
        component: TCList,
        meta: {
          namespace: 'tc',
          level: 2,
          order: 0,
          title: 'pages.tc.list.page_title',
          icon: 'ticket',
          components: {
            Calendar: true,
          }
        }
      },
      {
        path: '/tc/:serviceId/reporting',
        name: 'TCReporting',
        component: Accounting,
        meta: {
          namespace: 'tc',
          level: 2,
          order: 0,
          title: 'pages.tc.reporting.page_title',
          icon: 'ticket',
          restricted: true,
        }
      },
    ]
  },
  {
    path: '/update',
    name: 'Update',
    component: Update,
    meta: {
      titleTemplate: 'Helllo',
      // menuName: 'Helllo',
      // menu: {
      //   order: 3,
      //   label: 'Helllo',
      //   icon: 'ticket'
      // }
    },
    children: [
      {
        path: '/update/:id/details',
        name: 'UpdateDetails',
        component: Details,
        meta: {
          titleTemplate: 'pages.bookings.update.page_title',
          parent: 'parking'
        },
      },
      {
        path: '/update/:id/duration',
        name: 'UpdateDuration',
        component: Duration,
        meta: {
          titleTemplate: 'pages.bookings.update.page_title',
          parent: 'parking'
        },
      },
      {
        path: '/update/:id/review',
        name: 'UpdateReview',
        component: Review,
        meta: {
          titleTemplate: 'pages.bookings.update.page_title',
          parent: 'parking'
        },
      }
    ]
  },
  // Profile
  {
    path: '/profile/edit',
    name: 'EditProfile',
    component: EditProfile,
    meta: {
      titleTemplate: 'pages.profile.page_title',
      // layout: 'page',
      notInSidebar: true
      // subbar: true
    }
  },
 
  // Settings
  {
    path: '/settings',
    name: 'Settings',
    component: Page,
    redirect: '/settings/users',
    meta: {
      namespace: 'users',
      level: 1,
      title: 'pages.settings.users.page_title',
      titleMenu: 'pages.settings.users.sidebar_title',
      order: 4,
      restricted: true,

      // layout: 'page',
      // subbar: true,
      // titleTemplate: 'pages.settings.home.page_title',
    },
    children: [
      {
        path: '/settings/users',
        name: 'Users',
        component: Users, 
        meta: {
          icon: 'users',
          parent: 'Settings',
          titleTemplate: 'pages.settings.users.page_title',
          menuName: 'pages.settings.users.sidebar_title',
          menu: {
            order: 3,
            label: 'components.tabs.setting',
            icon: 'users-alt',
            admin: true
          },
          components: {
            Sidebar: true,
            // QuickSearch: true,
            AddButton: 'pages.settings.users.add_button'
          }
        }
      }
    ]
  },
  // Help
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      layout: 'page',
      subbar: true,
      // bodyClass: 'body-page',
      menu: {
        order: 4,
        label: 'components.tabs.help',
        icon: 'help',
      },
    }
  },
  // Authentication
  {
    path: '/lost-password',
    name: 'LostPassword',
    component: Authentication, 
    meta: {
      subbar: true,
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: Authentication, 
    meta: {
      subbar: true,
    }
  },
  // Static page - To change, now it's just to remove warning in console
  {
    path: '/privacy-policy',
    name: 'PrvacyPolicy',
    component: Help,
    meta: {
      layout: 'page',
      subbar: true,
      titleTemplate: 'pages.privacy.page_title',
      // bodyClass: 'body-page'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      layout: 'page',
      subbar: true,
      // titleTemplate: 'Terms and conditions',
      // bodyClass: 'body-page'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
