<template>
    <section ref="container" class="main-container licenseData" @scroll="handleScroll" >
      <div class="table" >
        <template v-if="licenseData">
          <div class="table-head">
            <div class="table-cell table-cell--name">{{ $t('labels.id') }}</div>
            <div class="table-cell table-cell--bookings">{{ $t('labels.country_license_plate') }}</div>
            <div class="table-cell table-cell--bookings">{{ $t('labels.fleet_company') }}</div>            
          </div>
          <div
            v-for="(item) in licenseData"
            :key="`account-item-${item.id}`"
            :class="['table-row']"
          >
            <!-- :class="['table-row']" -->
            <div class="table-cell table-cell--name">{{ item.id }}</div>
            <div class="table-cell table-cell--bookings">{{ item.truckCountryLicensePlate }}</div>
            <div class="table-cell table-cell--bookings">
                {{ item.fleetCompanyName }}
            </div>          
         
          </div>
        </template>
        <div
          v-if="!licenseData && !loading"
          class="no-result small"
        >
          <p>{{ $t('labels.no_results') }}</p>
        </div>
      </div>
  
      <div :style="{ height: loaderHeight + 'px', justifyContent: 'space-around' }"
      v-show="loading"
        class="main-container-loader"
      >
     
        <loader />
      </div> 
      

    </section>
  </template>
  
  <script>
  import Loader from '@/components/elements/Loader';
 
  export default {
    name: 'LicenseChecker',
    components: {
      Loader
    },
    data() {
    return {
      loaderHeight: 650, // Set the height value of content,
      
    };
  },
    methods:{
      handleScroll(event) {      
            const scrollBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight;
            if (scrollBottom > 0 && scrollBottom <= 650 && !this.loading) {              
                this.loading = true;
                this.loadMoreData();
            }
        },

        loadMoreData() {        
            this.$store.dispatch('licenseChecker/getLicensePlates', {updatePage: true });           
            this.loaderHeight += 5000;            
        },
    },
    computed: {
      licenseData() {       
       return this.$store.state.licenseChecker?.listLicense;
      },
      loading() {        
        return this.$store.state.licenseChecker.requests !== 0;
      }
    },
    beforeMount() {
     //this.addTools();
    },
    unmounted() {
      this.$store.commit('licenseChecker/updateData', null);     
    }
   
  };
  </script>
  
  <style lang="scss" scoped>
  .licenseData {
    .table {
      display: flex;
      flex-direction: column;      
  
      .table {
        .table-row {
          border-top: 0;
        }
  
        .table-cell {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: rem(14px);
  
          &--name {
            color: $snap-text-primary;
            align-items: flex-start;
          }
        }
      }
  
      &-head {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 1px solid $lightestgrey;
  
        + .table-row {
          border-top: 0;
        }
  
        .table-cell {
          color: rgba($black, .8);
          font-family: $primary;
          text-transform: uppercase;
        }
      }
  
      &-row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid $lightestgrey;
  
        &:last-child {
          border-bottom: 1px solid $lightestgrey;
        }
  
      
      }
  
      &-cell {
        position: relative;
        padding-top: 25px;
        padding-bottom: 25px;
        color: $mediumgrey;
        font-family: $secondary;
        font-size: rem(16px);
        line-height: 1.4;
        text-align: left;
  
        .table-row:last-child {
          border-bottom: 0;
        }
  
        small {
          display: block;
          font-size: rem(14px);
        }
  
        &--name {
          display: flex;
          align-items: center;
          width: 30%;
          padding-left: 25px;
          color: $black;
        }
  
        &--bookings {
          width: 30%;
          padding-left: 25px;
        }
  
        &--revenue {
          width: 15%;
          text-align: right;
        }
  
        &--actions {
          width: 25%;
          padding-right: 37px;
          text-align: right;
        }
  
        &--full {
          width: 100%;
          border-top: 1px solid $lightestgrey;
        }
  
        &-toggle {
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 12px;
          width: auto;
          height: 100%;
          padding: 0 25px;
          cursor: pointer;
        }
      }
    }
  }
  </style>
  