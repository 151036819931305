<template>
  <div class="breadcrumb-container">
    <button 
      type="button"
      class="breadcrumb-back"
      @click="onBack()"
    >
      <Sprite
        :width="10"
        :height="14"
        :type="'arrow-left'"
      />
      {{ $t('globals.back') }}
    </button>

    <ol class="breadcrumb">
      <li 
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        class="breadcrumb-step"
        :class="{
          'breadcrumb--active': index === current,
          'breadcrumb--previous': index < current,
        }"
      >
        <Sprite 
          v-if="index > 0" 
          :width="10" 
          :height="14" 
          :type="'chevron'" 
        />
        <span class="breadcrumb-step__content">
          {{index + 1}}. {{ breadcrumb.label }}
        </span>
      </li>
    </ol>
    <div class="breadcrumb-line"></div>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  name: "Breadcrumb",
  components:{
    Sprite,
  },
  props: {
    breadcrumbs: {
      type: Array
    },
    current: {
      type: Number
    }
  },
  data() {
    return {

    }
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
  .breadcrumb {
    font-family: $primary;
    font-weight: 500;
    font-size: rem(18);

    color: $snap-primary;
    line-height: 1.2;
    text-transform: uppercase;

    list-style: none;

    display: flex;
    align-items: center;

    padding-left: 0;
    margin: 15px 0 44px;

    &-back {
      font-family: $secondary;
      font-style: normal;
      font-weight: 500;
      font-size: rem(16px);
      text-transform: uppercase;
      color: $mediumgreen;
    }

    .svg-chevron {
      transform: rotate(-90deg);
      margin: 0 16px;
    }

    &-step {
      opacity: .5;
    }
    &--previous, &--active {
      opacity: 1;
    }
    &-step__content {
      position: relative;
    }
    &--active{

      .breadcrumb-step__content::after {
        display: block;
        content: '';
        width: 60px;
        height: 4px;
  
        position: absolute;
        bottom: -8px;
        left: 0;
  
        opacity: 1;
        background: $lightgreen;
  
        border-radius: 0 0 20px 20px;
      }
    }
  }
</style>