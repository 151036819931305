import {
  getLanguages,
  getCountries,
  getCurrencies,
  getSettings,
  getContext
} from '@/calls/settings';

const settings = {
  namespaced: true,
  state: {
    payAtParkingCharge: null,
    serviceFeePercent: null,
    language: 'en',
    languages: [],
    currency: 'eur',
    currencies: [],
    country: 'be',
    countries: [],
    minimalCountries: [],
    slots: null,
    context: null
  },
  mutations: {
    updateProperty(state, { property, value }) {
      state[property] = value;
    },
    updateLanguage(state, language) {
      state.language = language;
    },
    updateLanguages(state, languages) {
      state.languages = languages;
    },
    updateCountry(state, country) {
      state.country = country;
    },
    updateCurrency(state, currency) {
      state.currency = currency;
    },
    updateCountries(state, countries) {
      state.countries = countries;
    },
    updateCountriesMinimal(state, minimalCountries) {
      state.minimalCountries = minimalCountries;
    },
    updateCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    updateContext(state, context) {
      state.context = context;
    }
  },
  actions: {
    // setLanguage({ commit }) {
      // return commit('updateLanguage', langs);
    // },
    // setLanguages({ commit }) {
    //   const langs = getLanguages();
    //   langs.then((promise) => {
    //     return commit('updateLanguages', promise);
    //   })
    // },
    setCountry({ commit }, country) {
      return commit('updateCountry', country);
    },
    setCurrency({ commit }) {
      return commit('updateCurrency', '');
    },
    setCountries({ commit }) {
      const countries = getCountries();
      countries.then((promise) => {
        const arr = []
        Object.values(promise).forEach(country => {
            arr.push({
              value: country.code.toLowerCase(),
              label: country.name
            })
        });
        commit('updateCountriesMinimal', arr);
        return commit('updateCountries', promise);
      });
    },
    setCurrencies({ commit }) {
      const currencies = getCurrencies();
      currencies.then((promise) => {
        return commit('updateCurrencies', promise);
      });
    },
    setContext({ commit, dispatch }) {
      const context = getContext();
      context.then((promise) => {
        if(promise.isAdmin) {
          // commit('companies/updateProperty', {
          //   property: 'visible',
          //   value: true
          // }, { root: true });
          dispatch('companies/getCompanies', null, { root: true });
        }
        
        const parkingCompany = promise?.parkingManager?.parkingCompany;
        if (parkingCompany) {
          commit('user/updateCompany', parkingCompany, { root: true });
        }
        return commit('updateContext', promise);
      });
    },
    async setSettings({ commit, state }, types = ['languages', 'currencies',]) {
      const settings = await getSettings();

      commit('updateProperty', {
        property: 'payAtParkingCharge',
        value: settings.payAtParkingCharge
      });

      commit('updateProperty', {
        property: 'serviceFeePercent',
        value: settings.serviceFeePercent
      });

      for (let i = 0; i < types.length; i++) {
        const type = types[i];
        const typeUpper = type.charAt(0).toUpperCase() + type.slice(1);
        commit(`update${typeUpper}`, settings[type]);
      }
    }
  }
};

export default settings;
