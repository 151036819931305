import { formContainer } from '@aws-amplify/ui';
import axios from 'axios';

const checkForFleet = async (plate) => {
  try {
    const request = await axios.get(`/pmng/fleets/license-plate`, { params: { licensePlate: plate } });
    return request.data;
  } catch (error) {
    console.log(error);
    return {
      hasError: true,
      error
    };
  }
}

export {
  checkForFleet
};