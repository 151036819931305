<template>
  <div class="packages">
    <p class="label">{{ $t('components.package.label_selection') }}</p>

    <div
      v-if="packages && packages.length"
      class="packages-list"
    >
      <button
        v-for="pckg in packages"
        :key="`package-${pckg.id}`"
        :class="['package', { 'selected': selected === pckg.id }]"
        @click.prevent="onClick(pckg)"
      >
        <p class="package-text">
          <span class="package-time">
            <strong class="time">{{ pckg.duration }}{{ $t('globals.hours_short') }}</strong>
            {{ $t('components.package.stay') }}
          </span>
          <span class="package-price">
            {{ $t('components.package.as_from') }}
            <strong class="price">{{ pckg.price }}€</strong>
          </span>
        </p>
        <span class="package-round"></span>
      </button>
    </div>

    <div
      v-show="loading"
      class="packages-loader"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Loader
  },
  props: {
    packages: {
      type: Array,
      default: () => []
    },
    selected: {
      type: [Number, String],
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayPackages() {
      return this.packages;
    }
  },
  methods: {
    onClick(pckg) {
      this.$emit('onChange', pckg);
    }
  }
}
</script>

<style lang="scss">
  .packages {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 645px;

    &-list {
      display: flex;

      + .packages-loader {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }

    &-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: rgba($white, .7);
    }
  }

  .package {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 200px;
    box-shadow: 4px 4px 10px rgba($black, 0.08);
    border: 1px solid $lightestgrey;

    border-radius: 11px;
    padding: 15px 30px;

    margin-right: 24px;
    cursor: pointer;
    transition: background-color .3s $ease-out-quart;

    &:hover {
      background-color: rgba($snap-primary, .03);
    }

    &.selected {
      .package-round {
        &::after {
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          left: calc(50% - 5px);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $mediumgreen;
         }
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .time {
        display: block;
        font-size: rem(40px);
      }

      .price {
        display: block;
        font-size: rem(40px);
        font-weight: 500;
      }
    }

    &-time {
      font-family: $secondary;
      font-weight: 700;
      font-size: rem(40px);

      color: $snap-primary;
      line-height: 1.2;
      text-transform: uppercase;
      
      margin-bottom: 26px;
    }

    &-price {
      font-family: $secondary;
      font-weight: 500;
      text-transform: uppercase;
      font-size: rem(24px);
      line-height: 1.2;
      margin-bottom: 26px;
    }

    &-round {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid $mediumgreen;
    }
  }
</style>