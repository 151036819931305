import moment from 'moment-timezone';
import i18n from '../i18n';
import {
  payBooking,
  getBooking,
  setNoshow,
  setReject,
  setCheckin,
  setCheckout,
  getCheckoutBooking,
  removeBooking
} from '@/calls/bookings';

import {
  addPlate,
  removePlate,
} from '@/calls/plates';

import { removeSubscription } from '@/calls/subscriptions';
import {
  getReservation,
  getService
} from '@/calls/services';


const bookings = {
  namespaced: true,
  state: {
    detail: {
      visible: false,
      requests: 0,
      booking: null
    },
    pay: {
      visible: false,
      requests: 0,
      booking: null
    },
    ticket: {
      visible: false,
      requests: 0,
      booking: null
    },
    noshow: {
      visible: false,
      requests: 0,
      booking: null
    },
    checkout: {
      visible: false,
      requests: 0,
      booking: null,
      error: null,
      fields: {
        date: null,
        time: null
      }
    },
    checkin: {
      visible: false,
      requests: 0,
      booking: null,
      error: null,
      fields: {
        date: null,
        time: null
      }
    },
    reject: {
      visible: false,
      requests: 0,
      booking: null,
      fields: {
        reason: null,
        comment: ''
      }
    },
    addPlate: {
      visible: false,
      requests: 0,
      value: '',
      success: false
    },
    removePlate: {
      visible: false,
      requests: 0,
      value: null,
      success: false
    },
    addPlates: {
      visible: false,
      // requests: 0,
      value: '',
      // success: false
    },
    removePlates: {
      visible: false,
      // requests: 0,
      // value: null,
      // success: false
    },
    removeSubscription: {
      visible: false,
      requests: 0,
      subscription: null,
      success: false
    },
    reservation: {
      visible: false,
      id: null,
      item: null,
      requests: 0
    },
    service: {
      visible: false,
      id: null,
      item: null,
      requests: 0
    },
    removeBooking: {
      visible: false,
      requests: 0,
      success: false,
      booking: null
    }
  },
  mutations: {
    // Popups
    updatePopup(state, { type, property, value }) {
      state[type][property] = value;
    },
    updatePopupField(state, { type, field, value }) {
      state[type].fields[field] = value;
    },
  },
  actions: {
    // Get
    async getPopupBooking({ commit, state }, { popup, booking }) {
      commit('updatePopup', { type: popup, property: 'requests', value: state[popup].requests + 1 });

      const b = await getBooking(booking.id);

      if (b.hasError) {
        commit('updatePopup', { type: popup, property: 'visible', value: false });
        dispatch('notifications/addNotifs', [{
          text: `Error while getting booking : ${b.error}`,
          type: 'error'
        }], { root: true });
      } else {
        commit('updatePopup', { type: popup, property: 'booking', value: b });
      }

      commit('updatePopup', { type: popup, property: 'requests', value: state[popup].requests - 1 });
      return b;
    },
    async getCheckoutBooking({ commit, state, dispatch, rootState }, { popup, booking }) {
      commit('updatePopup', { type: popup, property: 'requests', value: state[popup].requests + 1 });
      const { date, time } = state.checkout.fields;
      const tzId = rootState.parkings.current.timeZoneId;
      const mom = moment.tz(`${date} ${time}`, tzId);
      const dateTime = mom.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:00]');
      const b = await getCheckoutBooking(booking.id, dateTime);

      if (b.hasError) {
        commit('updatePopup', { type: popup, property: 'visible', value: false });
        dispatch('notifications/addNotifs', [{
          text: `Error while getting booking : ${b.error}`,
          type: 'error'
        }], { root: true });
      } else {
        commit('updatePopup', { type: popup, property: 'booking', value: b });
      }

      commit('updatePopup', { type: popup, property: 'requests', value: state[popup].requests - 1 });
      return b;
    },
    // Set
    async pay({ commit, state, dispatch, rootState }, booking) {
      commit('updatePopup', {
        type: 'pay',
        property: 'requests',
        value: state.pay.requests + 1
      });

      const b = booking || state.pay.booking;
      const paying = await payBooking(b);

      if (paying.hasError) {
        dispatch('notifications/addNotifs', [{
          text: `Error while paying booking : ${paying.error}`,
          type: 'error'
        }], { root: true });
      } else {
        commit('updatePopup', { type: 'pay', property: 'visible', value: false });

        dispatch('notifications/addNotifs', [{
          text: `Booking paid !`,
          type: 'success'
        }], { root: true });
      }

      commit('updatePopup', {
        type: 'pay', property: 'requests', value: state.pay.requests - 1
      });

      return paying;
    },
    async setNoshow({ state, commit, dispatch, rootState }, booking) {
      commit('updatePopup', { type: 'noshow', property: 'requests', value: state.noshow.requests + 1 });
      const { t } = i18n.global;
      const b = booking || state.noshow.booking;
      const request = await setNoshow(b);

      if (!request.hasError) {
        commit('updatePopup', { type: 'noshow', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.bookings.noshow', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'noshow', property: 'requests', value: state.noshow.requests - 1 });
      return request;
    },
    async setCheckout({ state, commit, dispatch, rootState }, booking) {
      if (state.checkout.error) return;
      commit('updatePopup', { type: 'checkout', property: 'requests', value: state.checkout.requests + 1 });
      const { t } = i18n.global;
      const b = booking || state.checkout.booking;
      const { date, time } = state.checkout.fields;
      const tzId = rootState.parkings.current.timeZoneId;
      const mom = moment.tz(`${date} ${time}`, tzId);
      const dateTime = mom.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:00]');
      // const dateTime = `${date}T${time}:00:00`;

      const request = await setCheckout(b, dateTime);

      if (!request.hasError) {
        commit('updatePopup', { type: 'checkout', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.bookings.checkout', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'checkout', property: 'requests', value: state.checkout.requests - 1 });
      return request;
    },
    async setCheckin({ state, commit, dispatch, rootState }, booking) {
      if (state.checkin.error) return;
      commit('updatePopup', { type: 'checkin', property: 'requests', value: state.checkin.requests + 1 });
      const { t } = i18n.global;
      const b = booking || state.checkin.booking;
      const { date, time } = state.checkin.fields;
      const tzId = rootState.parkings.current.timeZoneId;
      const mom = moment.tz(`${date} ${time}`, tzId);
      const dateTime = mom.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:00]');
      // const dateTime = `${date}T${time}:00:00`;

      const request = await setCheckin(b, dateTime);

      if (!request.hasError) {
        commit('updatePopup', { type: 'checkin', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.bookings.checkin', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'checkin', property: 'requests', value: state.checkin.requests - 1 });
      return request;
    },
    async setReject({ state, commit, dispatch, rootState }, booking) {
      commit('updatePopup', { type: 'reject', property: 'requests', value: state.reject.requests + 1 });
      const { t } = i18n.global;
      const b = booking || state.reject.booking;
      const { reason, comments } = state.reject.fields;
      const tzId = rootState.parkings.current.timeZoneId;
      const mom = moment.tz(moment(), tzId);
      const dateTime = mom.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:00]');
      const params = {
        rejectedDate: dateTime,
        reason
      };
      if (reason === 7) params.comments = comments;

      const request = await setReject(b, params);

      if (!request.hasError) {
        commit('updatePopup', { type: 'reject', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.bookings.reject', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'reject', property: 'requests', value: state.reject.requests - 1 });
      return request;
    },
    async addPlate({ state, commit, dispatch }, parkingId) {
      commit('updatePopup', { type: 'addPlate', property: 'requests', value: state.addPlate.requests + 1 });
      const { t } = i18n.global;
      const request = await addPlate(parkingId, state.addPlate.value);

      if (!request.hasError) {
        commit('updatePopup', { type: 'addPlate', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.plates.add', { message: request?.error?.response?.data?.detail ? request?.error?.response?.data?.detail : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'addPlate', property: 'requests', value: state.addPlate.requests - 1 });
      return request;
    },
    async removePlate({ state, commit,dispatch }, parkingId) {
      commit('updatePopup', { type: 'removePlate', property: 'requests', value: state.removePlate.requests + 1 });
      const { t } = i18n.global;
      const request = await removePlate(parkingId, state.removePlate.value);
      
      if (!request.hasError) {
        commit('updatePopup', { type: 'removePlate', property: 'success', value: true });
      } else {


        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.plates.remove', { message: request?.error?.response?.data?.detail ? request?.error?.response?.data?.detail 
                      : request?.error?.response?.data ? request?.error?.response?.data
                      : request.error.message ? request.error.message 
                      : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'removePlate', property: 'requests', value: state.removePlate.requests - 1 });
      return request;
    },
    async removeSubscription({ state, commit, dispatch, rootState }) {
      commit('updatePopup', { type: 'removeSubscription', property: 'requests', value: state.removeSubscription.requests + 1 });

      const request = await removeSubscription(state.removeSubscription.subscription.id);

      if (!request.hasError) {
        commit('updatePopup', { type: 'removeSubscription', property: 'success', value: true });
        dispatch('subscriptions/getSubscriptions', rootState.parkings.selected, { root: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.subscriptions.remove', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'removeSubscription', property: 'requests', value: state.removeSubscription.requests - 1 });
      return request;
    },
    async getReservation({ commit, state, dispatch }) {
      commit('updatePopup', { type: 'reservation', property: 'requests', value: state.reservation.requests + 1});
      const request = await getReservation(state.reservation.id);
      if (!request.hasError) {
        const reservation = request;
        try { reservation.description = JSON.parse(reservation.description); }
        catch (e) { reservation.description = null; }
        commit('updatePopup', { type: 'reservation', property: 'item', value: reservation });
      }
      commit('updatePopup', { type: 'reservation', property: 'requests', value: state.reservation.requests - 1});
    },
    async getService({ commit, state, dispatch }) {
      commit('updatePopup', { type: 'service', property: 'requests', value: state.service.requests + 1});
      const request = await getService(state.service.id);

      if (!request.hasError) {
        // const reservation = request;
        // try { reservation.description = JSON.parse(reservation.description); }
        // catch (e) { reservation.description = null; }
        commit('updatePopup', { type: 'service', property: 'item', value: request });
      }
      commit('updatePopup', { type: 'service', property: 'requests', value: state.service.requests - 1});
    },
    async removeBooking({ state, commit, dispatch, rootState }, bookingId) {
      commit('updatePopup', { type: 'removeBooking', property: 'requests', value: state.removeBooking.requests + 1 });
      
      const request = await removeBooking(bookingId);

      if (!request.hasError) {
        commit('updatePopup', { type: 'removeBooking', property: 'success', value: true });
      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.bookings.checkout', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', { type: 'removeBooking', property: 'requests', value: state.removeBooking.requests - 1 });
      return request;
    },
    
  }
};

export default bookings;
