<template>
  <table class="table table-prices" cellspacing="0">
    <thead>
      <tr>
        <th class="head-hidden">{{ $t('globals.hours') }}</th>
        <th 
          v-for="val in sortedHead" 
          :key="val.value"
          class="small"
          :style="`width: ${cellWidth}% !important`"
        >
          {{ headToShow(val) }}
        </th>
        <!-- <td v-if="head.length < 4"/> -->
        <th
          v-for="missing in missingTH"
          :key="`missing-th-${missing}`"
          :style="`width: ${cellWidth}% !important`"
        />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="val in times" 
        :key="val"
      > 
        <td>
          {{ val }}{{ $t('globals.hours_stay') }}
        </td>
        <td
          v-for="pack in sorted[val]"
          :key="pack.price"
          :class="{'disabled': !pack.duration}"
          :style="`width: ${cellWidth}% !important`"
        > 
          <!-- @click="onClick" -->
          <div class="prices">
            <div
              class="edit"
              :class="{ 'is-focus': pack.id === nowEdit.id && nowEdit.type === 'price' }"
            >
              <span 
                type="price"
                :id="`price-${pack.id}`"
                :class="{'editable': pack.duration}"
                :contenteditable="pack.duration && pack.id === nowEdit.id && nowEdit.type === 'price' ? true : false"
                :initialValue="pack.price"
                :data-val="val"
                @keydown="handleInput"
                @keyup="handleKeyUp"
                @focusout="handleFocusOut"
                @click="toggle(pack, 'price', false)"
              >
                <!-- :contenteditable="pack.duration ? true : false" -->
                {{ pack.price }}
              </span> €
              <button
                v-if="pack.duration"
                class="edit-toggle"
                @click="toggle(pack, 'price')"
              >
                <sprite
                  :type="'edit'"
                  :width="13"
                  :height="13"
                />
                <sprite
                  :type="'check'"
                  :width="13"
                  :height="13"
                />
              </button>
            </div>
            <strong
              class="edit"
              :class="{ 'is-focus': pack.id === nowEdit.id && nowEdit.type === 'extra' }"
            >
              <span>
                <span
                  :id="`extra-${pack.id}`"
                  :contenteditable="pack.duration && pack.id === nowEdit.id && nowEdit.type === 'extra' ? true : false"
                  :initialValue="pack.extra"
                  :data-val="val"
                  type="extensionPrice"
                  class="editable"
                  @keydown="handleInput"
                  @keyup="handleKeyUp"
                  @focusout="handleFocusOut"
                  @click="toggle(pack, 'extra', false)"
                >
                  <!-- :class="{ 'editable': pack.duration }" -->
                  {{ pack.extra }}
                </span>€
              </span> {{ $t('globals.per_extra') }}

              <button
                v-if="pack.duration"
                class="edit-toggle"
                @click="toggle(pack, 'extra')"
              >
                <sprite
                  :type="'edit'"
                  :width="13"
                  :height="13"
                />
                <sprite
                  :type="'check'"
                  :width="13"
                  :height="13"
                />
              </button>
            </strong>
          </div>
        </td>
        <td v-for="missing in missingTD(val)" :key="`missing-td-${missing}`"/>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import _debounce from 'lodash/debounce';

export default {
  name: 'TablePrices',
  components: {
    Sprite
  },
  props: {
    head: {
      type: Object,
      required: true
    },
    body: {
      type: Object,
      required: true
    },
    slotsAvailable: {
      type: Object,
      required: true
    },
    headToShow: {
      type: [String, Function],
      required: true
    },
  },
  data() {
    return {
      sorted: [],
      // times: [],
      // byTypes: [],
      nowEdit: {
        id: null,
        type: null
      },
      reloaded: false
    }
  },
  computed: {
    sortedHead() {
      const head = [...this.head].sort((a, b) => {
        if(a.id < b.id) return -1;
        if(a.id > b.id) return 1;
        return 0;
      });

      return head;
    },
    cellWidth() {
      if (!this.sortedHead || !this.sortedHead.length) return 25;
      const width = 100 / this.sortedHead.length;
      console.log('width :', width);
      return width;
    },
    byTypes() {
      let head = this.head;

      head = head.map(el => {
        return {
          price: 0,
          // type: Number(el.value),
          type: Number(el.id),
          extra: 0
        }
      });

      return head;
    },
    times() {
      let times = this.body.packages.map(element => `${element.duration}`);
      times = new Set(times);
      times = [...times].sort((a,b) => a - b);
      return times;
    },
    missingTH() {
      const nbr = 4 - this.head.length;
      return Math.max(nbr, 0);
    },
  },
  watch: {
    slotsAvailable() {
      this.setPrices();
    },
    times() {
      this.setPrices();
    },
    $route() {
      this.setPrices();
    },
  },
  beforeMount() {
    this.setPrices();
  },
  methods: {
    missingTD(val) {
      const nbr = 4 - this.sorted[val].length;
      return Math.max(nbr, 0);
    },
    toggle(pack, type, force = true) {
      const $edit = this.$el.querySelector(`#${type}-${pack.id}`);

      // if (type === 'extra' && !pack.totalSlotNumber) {
      //   return;
      // }

      if (pack.id === this.nowEdit.id && type === this.nowEdit.type) {
        if (force) {
          this.close();
          this.save($edit, pack, type);
          return;
        }
      }

      setTimeout(() => {
        this.nowEdit.id = pack.id;
        this.nowEdit.type = type;
      }, 10);

      if ($edit) {
        setTimeout(() => {
          $edit.focus();
        }, 50);
      }
    },
    close() {
      this.nowEdit.id = null;
      this.nowEdit.type = null;
    },
    save($el, val, type) {
      const value = Number($el.innerText);

      if (type === 'price' && value === val.price) {
        return;
      }

      if (type === 'extra' && value === val.extensionPrice) {
        return;
      }

      const params = {
        id: Number($el.id.replace(/extra-|price-/g, '')),
      };

      if (type === 'price') {
        params.price = Number(value);
      }

      if (type === 'extra') {
        params.extensionPrice = Number(value);
      }

      this.$store.dispatch('parkings/setSlotsPrices', params);
    },
    setPrices() {
      this.times.forEach(time => {
        const newArr = this.body.packages.map(element => {
          const available = this.slotsAvailable.filter(s => s.slotId === element.slotId && s.totalSlotNumber > 0)
          if(time === `${element.duration}` && available.length) { 
            return {
              'id': element.id,
              'price': element.price,
              'type': element.slotId,
              'extra': element.extensionPrice,
              'duration': element.duration
            };
          }
        }).filter(v => v);

        const typeMapped = [...this.byTypes]
          .map( t => {
            const finalArr = newArr.filter( n => n.type === t.type);
            if(finalArr.length > 0) return finalArr.shift();
            return t;
          })
          .sort((a, b) => {
            if(a.type < b.type) return -1;
            if(a.type > b.type) return 1;
            return 0;
          });

        this.sorted[time] = typeMapped
      });

      let moreThan4 = false;

      this.sorted.map(time => {
        if (time.length > 4) moreThan4 = true;
      });

      if (moreThan4) {
        if (!this.reloaded) {
          this.$store.dispatch('parkings/setParking');
          this.reloaded = true;
        }

        return;
      }

      this.reloaded = false;
    },
    handleInput(evt) {
      const pass = [8, 37, 38, 39, 40, 46, 190]
      // const pass = [8, 37, 38, 39, 40, 46, 188, 190]
      const { key, keyCode } = evt;
      const is = this.isNumbers(key);
      if(!is && pass.indexOf(keyCode) === -1) evt.preventDefault();
    },
    handleKeyUp(evt) {
      const { target, keyCode } = evt;
      const { id } = target;
      const isPrice = id.match(/price-/g);

      // On enter
      if (keyCode === 13) {
        const dataValue = target.dataset.val;
        const values = this.sorted[dataValue];
        const valId = Number(id.replace(/price-|extra-/, ''));
        const val = values.filter(s => s.id === valId)[0];
        const type = isPrice ? 'price' : 'extra';

        this.save(target, val, type);
        this.close();
        return;
      }
    },
    handleFocusOut(evt) {
      const { target } = evt;
      const value = target.innerText;
      const inital = target.getAttribute('initialvalue');
      const type = target.getAttribute('type');

      // this.close();

      if(!value) {
        target.innerText = inital
        return ;
      }
    },
    isNumbers(t) {
      var regex = /\d/g;
      return regex.test(t);
    },
    debouncedUpdate: _debounce(function(payload) {
      this.$store.dispatch('parkings/setSlotsPrices', payload)
    }, 500)
  }
}
</script>


<style lang="scss" scoped>
  .disabled > *{
    opacity: .4;
    pointer-events: none;
  }
    
  .small {
    color: $grey;
  }

</style>
