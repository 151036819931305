import axios from 'axios';

const getCompanies = () => {
  // return axios.get(`/admin/parking-companies`)
  return axios.get(`/pmng/admin/companies`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

export {
  getCompanies
}