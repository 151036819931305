<template>
  <div
    class="languages"
    v-show="languages.length"
  >
    <button
      class="languages-toggle"
      @click.stop="onToggle"
    >
      {{ language.label }}
        <Sprite
        :type="'chevron'"
        :width="10"
        :height="15"
      />
    </button>
    <ul
      v-show="open"
      class="languages-dropdown"
    >
      <li
        v-for="lang in languages"
        :key="`languages-${lang.value}`"
      >
        <button
          :class="[
            'languages-option',
            { 'languages-option--selected': lang.value === language.value }
          ]"
          @click="clickLanguage(lang)"
        >
          {{ lang.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable */
import Sprite from '@/components/elements/Sprite';
// import languages from '@/data/languages';

export default {
  name: 'Languages',
  components: {
    Sprite
  },
  data() {
    return {
      open: false,
      _documentClick: this.onDocumentClick.bind(this)
    }
  },
  computed: {
    language() {
      const { locale } = this.$i18n;
      const language = this.languages.filter(l => l.value === locale);

      if (!language || !language.length) {
        return { value: 'en', label: 'English' };
      }
      return language[0];
    },
    languages() {
      return this.$store.state.settings.languages.map(l => {
        return {
          value: l.code,
          label: l.nativeName
        }
      });
    }
  },
  methods: {
    onToggle() {
      this.open = !this.open;

      if (this.open) {
        document.addEventListener('click', this._documentClick);
      } else {
        document.removeEventListener('click', this._documentClick);
      }
    },
    clickLanguage(option) {
      this.$i18n.locale = option.value;
      this.$store.commit('user/updateFormValue', { property: 'locale', value: option.value });
      this.$store.dispatch('user/updateUserLanguage');
      this.onToggle();
    },
    onDocumentClick(e) {
      const { target } = e;
      const isDescendant = this.isDescendant(this.$refs.container, target);
      if (!isDescendant && this.open) {
        this.onToggle();
      }
    },
    isDescendant(parent, child) {
      let node = child.parentNode;
      while (node) {
        if (node === parent) return true;
        node = node.parentNode;
      }

      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.languages {
  position: relative;
  z-index: 11;

  &-toggle {
    @extend %p;
    padding: 12px 24px;
    color:$white;
    font-weight: bold;
    cursor: pointer;

    .svg {
      fill: $snap-primary-lighten;
      margin-left: 16px;
    }
  }

  &-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 50%;
    width: auto;
    min-width: 100%;
    padding: 16px 0;
    transform: translateX(-50%);
    border: 1px solid rgba($grey, .5);
    border-radius: 10px;
    background-color: $white;
    box-shadow: 4px 4px 10px rgba(30, 43, 54, .08);
    list-style: none;
  }

  &-option {
    display: block;
    padding: 8px 20px 5px;
    width: 100%;
    transition: color .3s $ease-out-quart;
    color: $snap-action-default;
    font-size: rem(16px);
    font-weight: 600;
    text-align: left;
    cursor: pointer;

    &:hover {
      color: $black;
    }

    &--selected {
      background-color: $snap-primary-hover;
      color: $snap-primary;
    }
  }
}
</style>