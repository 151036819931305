<template>
  <nav 
    class="sidebar"
    :class="{'sidebar--small': !sidebar}"
  >
    <ul>
      <li
        v-for="route in routes"
        :key="route.name"
      >
        <!-- Link -->
        <router-link
          class="sidebar-item"
          :class="{ 'is-active': route.name === $route.name || route.meta?.namespace === $route.meta?.namespace }"
          :to='{
            name: route.name,
            query: route.meta.namespace === $route.meta.namespace ? $route.query : null,
            params: getRouteParams(route)
          }'
        >
          <span class="sidebar-item__content">
            <Sprite
              :type="route.meta.icon"
              :width="18"
              :height="18"
            />
            <span class="sidebar-text">{{ route.meta.titleMenu ? $t(route.meta.titleMenu) : $t(route.meta.title) }}</span>
          </span>
          <Sprite 
            v-if="route.children && route.children.length"
            :type="'chevron'" 
            :width="12"
            :height="12"
          />
        </router-link>
        <!-- Submenu -->
        <ul
          v-if="route.children && route.children.length"
          class="sidebar-children"
        >
          <li 
            v-for="child in route.children"
            :key="child.name"
          >
            <router-link 
              class="small sidebar-children-item"
              :class="{
                'is-active': child.name === $route.name || $route.path.match(child.path)
              }"
              :to='{
                name: child.name,
                query: child.meta.namespace === $route.meta.namespace ? $route.query : null,
                params: getRouteParams(child)
              }'
            >
              <Sprite 
                :type="child.meta.icon"
                :width="18"
                :height="18"
              />
              {{ child.meta.titleMenu ? $t(child.meta.titleMenu) : $t(child.meta.title) }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: 'Sidebar',
  components: { 
    Sprite 
  },
  computed: {
    sidebar() {
      return this.$store.state.user.sidebar;
    },
    routes() {
      const { context } = this.$store.state.settings;
      const isOwner = context && context.parkingManager && context.parkingManager.isOwner;
      const isAdmin = context && context.isAdmin;
      const hasAccess = isOwner || isAdmin;
      const routerRoutes = this.$router.getRoutes();

      let routes = routerRoutes
        .filter(r => {
          const { meta } = r;
          if (!meta) return false;
          if (r.meta.restricted && !hasAccess) return false;
          if (r.meta.namespace === 'tw' && !this.$store.state.services.tw.length) return false;
          if (r.meta.namespace === 'tc' && !this.$store.state.services.tc.length) return false;
          if (r.meta.namespace === 'parking' && !this.$store.state.parkings.parkings.length) return false;
          return r.meta.level === 1
        })
        .map(r => {
          const { meta } = r;
          if (!meta) return false;
          r.children = routerRoutes
            .filter(sr => {
              if (!sr.meta) return false;
              if (sr.meta.restricted && !hasAccess) return false;
              return sr.meta.level === 2 && meta.namespace === sr.meta.namespace;
            })
            .filter(r => r.name !== 'Whitelist' || (this.$store.state.parkings.isIntegrated && this.$store.state.parkings.whitelistable))
            .filter(r => r.name !== 'Subscriptions' || this.$store.state.parkings.subscribable)
            .sort((a, b) => {
              if(a.meta.order < b.meta.order) return -1;
              if(a.meta.order > b.meta.order) return 1;
              return 0;
            });
          return r;
        })
        .sort((a, b) => {
          if(a.meta.order < b.meta.order) return -1;
          if(a.meta.order > b.meta.order) return 1;
          return 0;
        });

      return routes;
    },
  },
  methods: {
    getRouteParams(route) {
      if (route.meta.namespace) {
        if (route.meta.namespace.match(/tw|tc/)) {
          const services = this.$store.state.services[route.meta.namespace];
          if (!services || !services.length) return { serviceId : services[0].value };
          return { serviceId : services[0].value };
        }
      }

      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  grid-area: sidebar;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  > ul {
    position: absolute;
    top: 0;
    left: 0;
    padding: 35px 0 35px 0;
    width: 75px;
    height: 100%;
    overflow: auto;
    z-index: 1;
    background: $white;
    border-right: 1px solid $lightestgrey;
    transition: width .3s $ease-out-quart;

    @include media-min(xl) {
      width: 280px;
      transition: width 0 $ease-out-quart;
    }
  }

  @include media-max(xl) {
    .sidebar-text {
      opacity: 0;
    }
    .svg {
      transform: translateX(-10px);
    }
    .sidebar-children, .svg-chevron {
      display: none;
    }
    .sidebar-item__content {
      transform: translateX(-10px);
    }
  }

  &--small {
    .sidebar-text {
      opacity: 0;
    }
    .svg {
      transform: translateX(-10px);
    }
    .sidebar-children, .svg-chevron {
      display: none;
    }
    > ul {
      width: 75px;
    }
    .sidebar-item__content {
      transform: translateX(-10px);
    }
    @include media-max(xl) {
      > ul {
        width: 280px;
      }
      .sidebar-text {
        opacity: 1;
      }
      .svg {
        transform: translateX(0);
      }
      .sidebar-children, .svg-chevron {
        display: block;
      }
    }
  }

  &-text {
    white-space: nowrap;
    display: block;
    // position: absolute;
    // left: 65px;
    pointer-events: none;
    transition: opacity .3s $ease-out-quart;
  }

  &-item {
    // color: $mediumgreen;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 15px 15px 35px;
    color: $snap-action-default;
    @extend %p;
    transition: color .3s $ease-out-quart;

    &:hover {
      color: $snap-primary;

      .svg {
        fill: $snap-primary;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      transition: transform .3s $ease-out-quart;
    }
    .svg {
      fill: $snap-action-default;
      margin-right: 10px;
      margin-top: -4px;
      transition: fill .3s $ease-out-quart, transform .3s $ease-out-quart;
    }

    &.is-active {
      background: $snap-primary-hover;
      color: $snap-primary;
      border-right: 2px solid $snap-primary;

      .svg-chevron {
        transform: rotate(180deg);
      }

      ~ .sidebar-children {
        height: 100%;
        padding: 15px 0;
      } 
    }

  }
  &-children {
    list-style: none;
    height: 0;
    overflow: hidden;

    &-item {
      display: block;
      color: $snap-action-default;
      padding: 8px 15px 8px 50px;
      transition: color .3s $ease-out-quart;

      &:hover {
        color: $snap-primary;
      }

      .svg {
        fill: $snap-primary;
        margin-right: 8px;
      }
    }

    &-item.is-active {
      color: $mediumgreen;
    }
  }
}
</style>