<template>
  <div 
    class="select"
    :class="{
      'error' : error,
      'select--open': open
    }"
  >
    <label v-if="label" class="label">{{ label }}</label>
      <div 
        class="custom-select"
        :class="{'custom-select--open': open}"
      >
        <strong 
          @click="onOpen"
          class="selected"
        > 
          {{HighlightSelect()}}
          <span class="selected-icon">
            <Sprite 
              :type="'chevron'"
            />
          </span>
        </strong>
        <ul
          ref="ul"
          class="items hide-scrollbar"
        >
          <li 
            class="item"
            :ref="option.value"
            v-for="option in options"
            :key="option.value"
            :rel="option.value"
            :class="Number(option.value) === Number(value) ? 'item--selected' : ''"
            @click="() => onClick(option)"
          >   
            {{ option.label ? option.label : option.value }}
          </li>
        </ul>
      </div>
      <select
        ref="input"
        :name="name"
        :required="required"
        autocomplete="off"
        :value="selected ? selected.value : null"
        @keyup="onKeyUp"
      >
        <option 
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :selected="selected && selected.value === option.value"
        >
          {{ option.label ? option.label : option.value }}
        </option>
      </select>
      <strong 
        v-if="error"
        class="small error-msg"
      >
        <span>{{ error }}</span>
        
      </strong>
  </div>
</template>

<script>
import Sprite from './Sprite';
export default {
  components: {
    Sprite
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      placeholder: 0,
    },
  },
  data() {
    return {
      selected: {
        value: this.placeholder,
        label: null,
      },
      value: null,
      oldLetter: null,
      open: false,
    };
  },
  watch: {
    placeholder(value) {
      this.selected.value = value;
      if(this.options) {
        const obj = this.options.filter(opt => this.placeholder === opt.value).shift()
        if(this.selected && obj && obj.value) {
          this.selected.label = obj.label ? obj.label : null ;
          this.selected.value = obj.value;
        }
      }
      return value;
    },
    options(arr){
      return arr;
    },
    selected(val){
      return val;
    },
  },
  mounted() {
    this.selected.value = this.placeholder;
    if(this.options) {
      const obj = this.options.filter(opt => this.placeholder === opt.value).shift()
      if(this.selected && obj && obj.value) {
        this.selected.label = obj.label ? obj.label : null ;
        this.selected.value = obj.value;
      }
    }
  },
  methods: {
    onClick(option) {
      this.selected = option
      this.open = false;
      this.$emit('change', option.value)
    },
    onOpen() {
      this.open = !this.open
      if(this.open && this.$refs.input) this.$refs.input.focus();
    },
    onKeyUp(evt) {
      const {selectedOptions} = evt.target;
      if(this.$refs.input) this.$refs.ul.scrollTo(0, this.$refs[selectedOptions[0]?.value].offsetTop);
    },
    HighlightSelect() {
      return this.selected && this.selected.label ? this.selected.label : this.selected.value
    }
  }
};
</script>

<style lang="scss">
  .select--open {
    z-index: 10;
  }
</style>