import moment from 'moment-timezone';

const FORMATS = {
  date: 'DD[.]MM[.]YYYY',
  hours: 'HH[:]mm a',
  hoursDigits: 'HH[:]mm',
  hoursAmPm: 'a',
}

const formatDate = function(date, tz = 'Europe/Brussels',  dateFormat = FORMATS.date, hourFormat = FORMATS.hours) {
  // Get date without offset
  const match = date.match(/(\+|\-)[0-9]{2}:[0-9]{2}$/g);
  const dateString = match.length > 0 ? date.replace(match[0], '') : date;
  const d = moment.tz(dateString, tz);

  return {
    date: d.format(dateFormat),
    hours: d.format(hourFormat),
    hoursDigits: d.format(FORMATS.hoursDigits),
    hoursAmPm: d.format(FORMATS.hoursAmPm),
  };
};

const utcTo = function(date, tz, dateFormat = FORMATS.date, hourFormat = FORMATS.hours) {
  const match = date.match(/(\+|\-)[0-9]{2}:[0-9]{2}$/g);
  const dateString = match.length > 0 ? date.replace(match[0], '') : date;
  const utcDate = moment.utc(dateString);
  const tzDate = utcDate.tz(tz);

  const obj =  {
    date: tzDate.format(dateFormat),
    hours: tzDate.format(hourFormat),
    hoursDigits: tzDate.format(FORMATS.hoursDigits),
    hoursAmPm: tzDate.format(FORMATS.hoursAmPm),
  };

  return obj;
};

const getParams = (url) => {
  const match = url.match(/\?([\w_\-.=&]+)/);

    if (!match) return null;

    const search = match[1].split('&');
    const object = {};

    for (let i = 0; i < search.length; i++) {
      const part = search[i].split('=');
      const { 0: key } = part;
      const { 1: value } = part;

      object[key] = value;
    }

    return object;
}

export {
  formatDate,
  utcTo,
  getParams
};