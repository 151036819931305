<template>
  <popup
    v-if="popup.visible"
    :classes="'popup-add-plates'"
  >
    <template v-slot:content>
      <div class="popup-header">
        <h2 class="popup-title h4">{{ $t('parkings.plates.popups.add_multiple.title') }}</h2>
      </div>

      <form class="popup-form">
        <Inputs
          type="textarea" 
          :value="popup.value"
          :label="$t('parkings.plates.popups.add_multiple.label')"
          :placeholder="$t('parkings.plates.popups.add_multiple.placeholder')"
          @input="onInput"
        />
      </form>

      <div class="popup-actions">
        <button
          class="button button-border"
          @click="close"
        >
          {{ $t('globals.close') }}
        </button>
        <button
          class="button"
          @click="add"
          :disabled="!popup.value.length"
        >
          {{ $t('globals.add') }}
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
import Inputs from '@/components/elements/Inputs';
import Popup from '@/components/partials/Popup';

export default {
  components: {
    Inputs,
    Popup,
  },
  computed: {
    popup() {
      return this.$store.state.popups.addPlates;
    }
  },
  data() {
    return {
      type: 'addPlates'
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'value', value: '' });
    },
    onInput(e) {
      if (!e || !e.target) return;
      const { value } = e.target;
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'value', value });
    },
    async add() {
      this.$emit('add', this.popup.value);
    }
  }
};
</script>