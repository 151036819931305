import axios from 'axios';

const getUsers = async () => {
  return await axios.get('/pmng/managers')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
}

const getUser = async (id) => {
  return await axios.get(`/pmng/managers/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
}

const createUser = async (params) => {
  return await axios.post('/pmng/managers', params)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
}

const patchUser = async (id, params) => {
  return await axios.patch(`/pmng/managers/${id}`, params)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
}

const revokeUser = async (id) => {
  return await axios.get(`/pmng/managers/revoke/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
}
export {
  getUser,
  getUsers,
  patchUser,
  createUser,
  revokeUser
}