<template>
  <section class="topbar">
    <div class="topbar-right">
      <!-- <Notifications />
      <hr> -->
      <Languages />
      <hr>
      <Profile />
    </div>

  </section>
</template>

<script>
import Profile from '@/components/modules/Header/Profile';
import Languages from '@/components/modules/Header/Languages';
// import Notifications from '@/components/modules/Header/Notifications';

export default {
  name: 'Topbar',
  components: {
    Profile,
    Languages,
  }
}
</script>

<style lang="scss" scoped>
.topbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 35px 0 50px;

  /* Right */
  &-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    hr {
      display: block;
      width: 1px;
      height: 42px;
      background: rgba(#8B8D96, 0.2);
    }
  }

  .topbar-menu {
    align-self: flex-end;

    &-list {
      display: flex;
      list-style: none;
    }

    &-item {
      text-align: center;
      position: relative;

      + .topbar-menu-item {
        margin-left: 10px;
      }
    }

    &-link {
      @extend %p;
      display: block;
      padding: 15px 15px 16px;
      color: $white;
      font-size: 22px;
      font-weight: $medium;

      &:before {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $lightgreen;
        border-radius: 8px 8px 0 0;
        opacity: 0;
      }

      &.router-link-active,
      &--active {
        color: $lightgreen;
        font-weight: $bold;
        border-right: 1px solid transparent;

        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
</style>