<template>
  <aside
    v-if="isVisible"
    class="modal modal--visible"
  >
    <div class="modal-container">
      <h3 class="h4">{{ booking.driverName }}</h3>
    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        class="button button-border"
        @click="onClick"
      >
        {{ $t('globals.close') }}
      </button>
      <button type="button" class="button">{{ $t('globals.contact') }}</button>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Modal",
  props: {
    fleet: {
      type: [Object, Boolean, String],
      default: null
    },
  },
  computed: {
    fleetName() {
      if (this.fleet) return this.fleet;
      return this.$t('components.modal.freelance');
    },
    isVisible() {
      return this.$store.state.popups.detail.visible;
    },
    driver() {
      return this.$store.state.user.driver;
    },
    booking() {
      const { booking } = this.$store.state.popups.detail;
      if (!booking) return {};
      return booking;
    }
  },
  data() {
    return {
      // abbr: '',
      // phone: '',
      // nickname: '',
    }
  },
  watch: {
    // driver(driver) {
    //   var matches = driver.driverName.match(/\b(\w)/g); // ['J','S','O','N']
    //   if(!matches) matches = ['n','n']
    //   this.abbr = matches.join(''); // JSON
    //   this.nickname = driver.driverName ? driver.driverName : this.$t('components.modal.no_name'); 
    //   this.phone = driver.driverPhone;
    //   this.fleetname = ` ${this.$t('components.modal.freelance')}`;
    // },
    // fleet(fleet) {
    //   if(fleet) this.fleetname = fleet.name;
    //   else this.fleetname = ` ${this.$t('components.modal.freelance')}`;
    // }
  },
  methods: {
    onClick() {
      this.$store.commit('bookings/updatePopup', {
        type: 'detail', property: 'visible', value: false
      });
    }
  }
}
</script>
