<template>
  <!-- Popup Checkout -->
  <popup
    v-if="popup.visible"
    :classes="'popup-reject'"
  >
    <template v-slot:content>
      <!-- Success -->
      <template v-if="popup.success">
        <h2 class="popup-title">{{ $t('globals.success') }}</h2>
        <div class="popup-main">
          <img class="popup-main-img" src="/img/success.png"/>
          <p class="popup-main-text">{{ successMessage }}</p>
        </div>

        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup button-big"
            @click.prevent="close"
          >
            {{ $t('globals.ok') }}
          </button>
        </div>
      </template>

      <!-- Infos -->
      <template v-else>
        <div class="popup-header">
          <h2 class="popup-title h4">{{ $t('components.integrated.popups.reject.title') }}</h2>
          <p class="popup-main-text">{{ $t('components.integrated.popups.reject.text') }}</p>
        </div>

        <form class="popup-form">
          <NewSelect
            :options="reasons"
            :value="popup.fields.reason"
            :label="$t('components.integrated.popups.reject.labels.reason')"
            :placeholder="$t('components.integrated.popups.reject.placeholders.reason')"
            :class="'select-input classic'"
            :name="'parking-select'"
            :required="true"
            @onChange="updateReason"
          />

          <Inputs
            v-if="popup.fields.reason === 7"
            type="textarea"
            :label="$t('components.integrated.popups.reject.labels.comments')"
            :placeholder="$t('components.integrated.popups.reject.placeholders.comments')"
            :value="popup.fields.comments"
            @input="updateComments"
          />
          <div 
            v-if="popup.error"
            class="popup-form-error"
          >
            {{ popup.error }}
          </div>
        </form>

        <div class="popup-actions">
          <button
            class="button button-big button-ghost"
            @click="close"
          >
            {{ $t('globals.cancel') }}
          </button>
          <button
            class="button button-big"
            @click="reject"
            :disabled="!popup.fields.reason || (popup.fields.reason === 7 && (!popup.fields.comments || !popup.fields.comments.length))"
          >
            {{ $t('globals.confirm') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';
import Inputs from '@/components/elements/Inputs';
import NewSelect from '@/components/elements/NewSelect';

export default {
  components: {
    Loader,
    Popup,
    Inputs,
    NewSelect
  },
  data() {
    return {
      type: 'reject'
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.reject;
    },
    successMessage() {
      // $t('components.integrated.popups.reject.success')
      const reason = this.reasons.filter(r => r.value === this.popup.fields.reason);
      if (!reason.length) return '';
      return reason[0].success;
    },
    reasons() {
      return [
        {
          label: this.$t('components.integrated.popups.reject.options.internal'),
          success: this.$t('components.integrated.popups.reject.success.internal'),
          value: 5
        },
        {
          label: this.$t('components.integrated.popups.reject.options.competitor'),
          success: this.$t('components.integrated.popups.reject.success.competitor'),
          value: 6
        },
        {
          label: this.$t('components.integrated.popups.reject.options.subscription'),
          success: this.$t('components.integrated.popups.reject.success.subscription'),
          value: 8
        },
        {
          label: this.$t('components.integrated.popups.reject.options.other'),
          success: this.$t('components.integrated.popups.reject.success.other'),
          value: 7
        }
      ]
    }
  },
  methods: {
    // ON
    close() {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'success', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'booking', value: null });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: null });
      this.$store.commit('popups/updatePopupField', { type: this.type, field: 'reason', value: null });
      this.$store.commit('popups/updatePopupField', { type: this.type, field: 'comments', value: ''});
    },
    updateReason(option) {
      this.$store.commit('popups/updatePopupField', { type: this.type, field: 'reason', value: option });
    },
    updateComments(e) {
      if(!e) return;
      const { target } = e;
      const { value } = target;
      this.$store.commit('popups/updatePopupField', { type: this.type, field: 'comments', value });
    },
    async reject(booking) {
      const request = await this.$store.dispatch('popups/setReject');
      if (!request.hasError) this.$emit('onSuccess');
    }
  }
}
</script>