import i18n from '../i18n';
import router from '../router';

import {
  getBooking,
  getAvailablePackages,
  updateBooking,
  simulateBooking
} from '@/calls/bookings';
import { getSlotsByParkingId } from '@/calls/parkings';
import { checkForFleet } from '@/calls/fleets';

import moment from 'moment-timezone';

const bookings = {
  namespaced: true,
  state: {
    data: null,
    form: {
      phone: null,
      plate: null,
      slotType: null,
      pckg: null,
      paid: false,
      fleetId: null,
      payAtParking: false
    },
    slots: null,
    pckgs: null,
    fleet: null,
    simulated: null,
    requests: {
      booking: null,
      pckgs: null
    }
  },
  mutations: {
    updateData(state, data) {
      state.data = data;
    },
    updateForm(state, { field, value }) {
      state.form[field] = value;
    },
    updateSimulated(state, data) {
      state.simulated = data;
    },
    updateSlots(state, value) {
      state.slots = value;
    },
    updatePckgs(state, value) {
      state.pckgs = value;
    },
    updateFleet(state, value) {
      state.fleet = value;
    },
    increaseRequests(state, type = 'booking') {
      state.requests[type]++;
    },
    decreaseRequests(state, type = 'booking') {
      state.requests[type]--;
    },
  },
  actions: {
    updateFormFromData({ state, commit, dispatch }) {
      commit('updateForm', { field: 'plate', value: state.data.truckLicensePlate });
      commit('updateForm', { field: 'phone', value: state.data.comment ? state.data.comment.replace(/([^0-9\+])+/g, '').trim() : null });
      // commit('updateForm', { field: 'phone', value: state.data.comment ? state.data.comment.replace(/Driver Phone Number\:/, '').trim() : null });
      commit('updateForm', { field: 'slot', value: state.data.package.slot });
      commit('updateForm', { field: 'pckg', value: state.data.package });
      commit('updateForm', { field: 'paid', value: !state.data.payAtParking });
      commit('updateForm', { field: 'payAtParking', value: state.data.payAtParking });
    },
    async getBooking({ commit, dispatch, rootState }, { id, getPackages = true }) {
      commit('increaseRequests');
      const booking = await getBooking(id);

      if (!booking.hasError) {
        commit('updateData', booking);
        dispatch('updateFormFromData');

        if (rootState.parkings.selected !== booking.package.parking.id) {
          commit('parkings/updateSelected', booking.package.parking.id, { root: true });
        }

        if (getPackages) {
          dispatch('getAvailablePackages');

          if (booking.truckLicensePlate) {
            dispatch('checkForFleet');
          }
        }
      }

      commit('decreaseRequests');
    },
    async getSlotsTypes({ commit, rootState }) {
      commit('increaseRequests');
      
      const slots = await getSlotsByParkingId(rootState.parkings.selected);
      if (slots) {
        commit('updateSlots', slots);
      }

      commit('decreaseRequests');
    },
    async getAvailablePackages({ commit, state }) {
      commit('increaseRequests', 'pckgs');
      const { slot, pckg } = state.form;
      const { id } = state.data;

      if (!slot || !id) return;

      const pckgs = await getAvailablePackages(id, slot.id);

      if (!pckgs.hasError) {
        commit('updatePckgs', pckgs);
      }

      commit('decreaseRequests', 'pckgs');
    },
    async checkForFleet({ commit, state }) {
      commit('increaseRequests', 'pckgs');
      const { plate } = state.form;

      const fleet = await checkForFleet(plate);

      if (!fleet.hasError) {
        commit('updateFleet', fleet);
        commit('updateForm', { field: 'fleetId', value: fleet.id });
        commit('updateForm', { field: 'payAtParking', value: false });
      } else {
        commit('updateFleet', null);
        commit('updateForm', { field: 'fleetId', value: null });
        commit('updateForm', { field: 'payAtParking', value: true });
      }

      commit('decreaseRequests', 'pckgs');
    },
    async simulateBooking({ commit, dispatch, state }) {
      commit('increaseRequests');
      const { form, data } = state;
      const params = {
        truckLicensePlate: form.plate,
        driverPhoneNumber: form.phone,
        packageId: form.pckg.id,
        fleetId: form.fleetId,
        payAtParking: form.payAtParking
      }

      const simulate = await simulateBooking(data.id, params);

      if (!simulate.hasError) {
        commit('updateSimulated', simulate);
      }

      commit('decreaseRequests');
    },
    async updateBooking({ commit, dispatch, state }) {
      commit('increaseRequests');
      const { form, data } = state;
      const params = {
        truckLicensePlate: form.plate,
        driverPhoneNumber: form.phone,
        packageId: form.pckg.id,
        fleetId: form.fleetId,
        payAtParking: form.payAtParking
      }

      const update = await updateBooking(data.id, params);
      const { t } = i18n.global;

      if (!update.hasError) {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.success.bookings.update'),
          type: 'success'
        }], { root: true });

        router.push('/');

      } else {
        dispatch('notifications/addNotifs', [{
          text: t('notifications.error.bookings.update'),
          type: 'error'
        }], { root: true });
      }

      commit('decreaseRequests');
    }
  }
};

export default bookings;
