<template>
  <popup
    v-if="popup.visible"
    :classes="'popup-service'"
  >
    <template v-slot:content>
      <ServiceDetail
        :type="$route.meta.namespace === 'tc' ? 'tankCleaning' : 'truckWashing'"
        :service="popup.item"
        :loading="popup.requests !== 0"
      />

      <div
        v-if="popup.requests === 0"
        class="popup-actions popup-actions--center"
      >
        <button
          class="button button-big"
          @click="close"
        >
          {{ $t('globals.close') }}
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import ServiceDetail from '@/components/partials/ServiceDetail';
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Popup,
    ServiceDetail
    // Loader
  },
  computed: {
    popup() {
      return this.$store.state.popups.service;
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/updatePopup', {
        type: 'service', property: 'visible', value: false
      });
      this.$store.commit('popups/updatePopup', {
        type: 'service', property: 'item', value: null
      });
    }
  }
}
</script>