<template>
  <popup
    v-if="popup.visible"
    :classes="'popup-reservation'"
  >
    <template v-slot:content>
      <template v-if="popup.item">
        <div class="popup-header">
          <h2 class="h4">{{ title() }}</h2>
        </div>

        <table class="popup-table">
          <tbody>
            <tr
              v-for="row in table"
              :key="`payPopup-${row.property}`"
            >
              <td>{{ row.label(popup.item) }}</td>
              <td v-html="row.property(popup.item) ? row.property(popup.item) : '-'"/>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('services.booking-popup.total-price') }}</td>
              <!-- <td>Total&nbsp;price&nbsp;:</td> -->
              <td>{{ popup.item?.price }}€</td>
              <!-- <td>{{ popup.booking.totalPrice }}€</td> -->
            </tr>
          </tfoot>
        </table>

        <div class="popup-actions popup-actions--center">
          <button
            class="button"
            @click="close"
          >
            {{ $t('globals.close') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';

import moment from 'moment';
import { formatDate, utcTo } from '@/helpers/helpers';

export default {
  name: 'Detail',
  components: {
    Loader,
    Popup
  },
  data() {
    return {
      table: [
        {
          label: () => this.$t('services.booking-popup.date'),
          property: (reservation) => {
            const d = utcTo(reservation.startAt, reservation.service.timeZoneId);
            return `${d.date}`;
          }
        },
        {
          label: () => this.$t('services.booking-popup.time'),
          property: (reservation) => {
            const d = utcTo(reservation.startAt, reservation.service.timeZoneId);
            return `${d.hoursDigits} ${this.$t(`globals.${d.hoursAmPm}`)}`;
          }
        },
        {
          label: () => this.$t('services.booking-popup.driver'),
          property: (reservation) => reservation?.description?.driver?.name ? reservation?.description.driver.name : '-'
        },
        {
          label: () => this.$t('services.booking-popup.truck'),
          property: (reservation) => reservation?.description?.truck?.name ? reservation?.description?.truck?.name : '-'
        },
        {
          label: () => this.$t('services.booking-popup.plate'),
          property: (reservation) => {
            if (!reservation?.description?.truck?.licensePlate) return '-';
            const { licensePlate, licenseCountry } = reservation.description.truck;
            return `${licenseCountry ? licenseCountry + ' - ' : ''}${licensePlate}`;
          }
        },

        {
          label: () => this.$t('services.booking-popup.price'),
          property: (reservation) => {
            if (reservation.type === 'TankCleaning' && reservation.tankCleaningBooking.package) {
              return `${reservation.tankCleaningBooking.package.price}€`;
            }

            if (reservation.type === 'TruckWashing' && reservation.truckWashingBooking.package) {
              return `${reservation.truckWashingBooking.package.price}€`;
            }

            return '-';
          }
        },
        {
          label: () => this.$t('services.booking-popup.options'),
          property: (reservation) => {
            let options = null;
            if (reservation.type === 'TankCleaning') {
              options = reservation.tankCleaningBooking.options.map(o => `${this.$t('services.options.' + o.id)} - ${o.price}€`);
            } else {
              options = reservation.truckWashingBooking.options.map(o => `${this.$t('services.options.' + o.id)} - ${o.price}€`);
            }

            return options.join('<br>');
          }
        }
      ]
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.reservation;
    },
    // type() {
    //   if (!this.popup.item.type) return '';
    //   return this.popup.item.type.charAt(0).toLowerCase() + this.popup.item.type.slice(1);
    // }
  },
  methods: {
    title() {
      if (this.popup.item.type === 'TruckWashing' && this.popup.item.truckWashingBooking?.package) {
        return this.popup.item.truckWashingBooking.package.label;
      }

      if (this.popup.item.type === 'TankCleaning' && this.popup.item.tankCleaningBooking?.package) {
        return this.popup.item.tankCleaningBooking.package.label;
      }

      return '-';
    },
    close() {
      this.$store.commit('popups/updatePopup', {
        type: 'reservation', property: 'visible', value: false
      });
      this.$store.commit('popups/updatePopup', {
        type: 'reservation', property: 'item', value: null
      });
    }
  }
}
</script>