<template>
    <!-- TABLE -->
    <section 
    ref="container"
      class="main-container"
      :class="{ 'no-booking': tmpBooking && tmpBooking.length === 0 , 'main-container--loading': loading }"
      @scroll="handleScrollEvent()"
    >
      <!-- :class="tmpBooking.length === 0 ? 'no-booking' : ''" -->
      <table v-if="tmpBooking && tmpBooking.length" class="table" cellspacing="0">
         <!-- TABLE HEAD -->
        <Thead :head="theadBooking" />
        
        <!-- TABLE BODY -->
        <tbody>
          <transition-group 
            name="slide-fade" mode="out-in"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
            v-bind:css="false"
          >
            <tr 
              v-for="booking in tmpBooking"
              :key="booking.id"
            > 
              <TBody 
                v-for="(b, index) in tbodyBooking"
                :key="index"
                :body="b"
                :el="booking"
              />
            </tr>
          </transition-group>
        </tbody>
      </table>
      <!-- NO RESULTS -->
      <NoResult v-else >
        <slot>{{message}}</slot>
      </NoResult>

      <div
        v-show="loading"
        class="main-container-loader"
      >
        <loader />
      </div>
    </section>
</template>

<script>
import moment from 'moment';
import { formatDate, utcTo } from '@/helpers/helpers';
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";

import NoResult from '@/components/partials/Bookings/NoResult';
import Thead from '@/components/elements/THead';
import TBody from '@/components/elements/TBody';
import Loader from '@/components/elements/Loader';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Sale',
  components: {
    NoResult,
    Thead,
    TBody,
    Loader
  },
  // beforeMount() {
  //   const { selected } = this.$store.state.parkings;
  //   if (selected) {
  //     this.$store.dispatch('bookings/setSales', { ParkingId: selected, dates: false });
  //   }
  // },
  data() {
    return {
      tmpBooking: [],
      currentList: [],
      isTableVisible: false,
      isScrolling: 0,
      tbodyBooking: [
        // { title: (booking) => this.Status(booking), class: "head" },
        { title: (booking) => {
          const { status } = booking;
          let displayedStatus = status === 'Undefined' ? this.$t('bookings.not_confirm') : status;
          // if (displayedStatus.toLowerCase() === 'payed') displayedStatus = 'Paid';
          return `<span class="${status.toLowerCase()}">${this.$t(`bookings.status.${displayedStatus.toLowerCase()}`)}</span>`
          }, 
          class: "head" },
        { 
          title: (booking) => {
            const d = utcTo(booking.createdAt, this.timeZone);
            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `;
          },
          class: "td-date"
        },
        {
          title: (booking) => {
            if (booking.checkinAt) {
              const d = formatDate(booking.checkinAt, this.timeZone);
             
                return `
                  ${d.date}
                  <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
                `
            }
            const d = formatDate(booking.startAt, this.timeZone);
            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `;
          },
          class: "td-date"
        },
        // Payment method
        {
          title: (booking) => {
            switch (booking.paymentMethod) {
              case 'AtParking':
                return this.$t('globals.payment_methods.at_parking');
              case 'Subscription':
                return this.$t('globals.payment_methods.subscription');
              case 'FleetInvoice':
                return this.$t('globals.payment_methods.fleet_invoice');
              default:
                return booking.paymentMethod;
            }
          }
        },
        // Payment status
        {
          title: (booking) => {
            const { arrivalStatus } = booking;

            if (booking.payedAt || arrivalStatus === 'Competitor' || arrivalStatus === 'Subscriber' || arrivalStatus === 6) return `<span class="pill pill--success">${this.$t('globals.payment_status.paid')}</span>`;
            return `<span class="pill pill--warning">${this.$t('globals.payment_status.unpaid')}</span>`;
          },
          class: "td-date"
        },
        { 
          title: (booking) => {
            const value = booking.packagePrice + booking.priceDetails.extraDurationPriceWithoutFee;
            const round = Math.round((value + Number.EPSILON) * 100) / 100;

            return `
              <span class="legend small">${booking.package.duration}${this.$t('globals.hours_stay')} - ${round}€</span>
            `
          }
        },
        { title: (booking) =>  {
          // if (booking.driverFirstname && booking.driverLastname) return `${booking.driverFirstname}<br> ${booking.driverLastname}`
          // if (booking.driverName) return booking.driverName;          
          // return this.$t('globals.no_name');
          return this.getDriverName(booking)
          },
          class: "nickname"
        },
        { title: (booking) => booking.truckLicenseCountry.toUpperCase() + ' ' +booking.truckLicensePlate + `${booking.trailLicensePlate && booking.truckLicensePlate ? ' - ' : ''}` +  booking.trailLicenseCountry.toUpperCase() + ' ' + booking.trailLicensePlate },
        {
          title: (booking) => {
            return booking?.route?.fleet?.name ?  booking?.route?.fleet?.name : '-'
          }
        }
      ]
    }
  },
  computed: {
    timeZone() {
      if (!this.$store.state.parkings?.current?.timeZoneId) return 'Europe/Brussels';
      return this.$store.state.parkings.current.timeZoneId;
    },
    current() {
      return this.$store.state.parkings.current;
    },
    bookings() {
      return this.$store.state.bookings.sales;
      // return this.$store.state.bookings.bookings;
    },
    title() {
      return this.$t(this.$route.meta.titleTemplate);
    },
    filters() {
      return this.$store.state.bookings.filters;
    },
    opt() {
      return this.$store.state.user.opt;
    },
    loading() {      
      return this.$store.state.bookings.requests.sales !== 0;
    },
    message() {
      return this.$t('bookings.no_result');
    },
    theadBooking() {
      return [
        { title: this.$t('bookings.sale.thead.status'), class: "small head big-col" },
        { title: this.$t('bookings.sale.thead.date'), class: "td-date small" },
        { title: this.$t('bookings.sale.thead.arrival'), class: "td-date small" },
        { title: this.$t('bookings.sale.thead.payment_method'), class: "small head big-col" },
        { title: this.$t('bookings.sale.thead.payment_status'), class: "small head td-date" },
        { title: this.$t('bookings.sale.thead.price'), class: "small head big-col" },
        { title: this.$t('bookings.sale.thead.driver'), class: "small head big-col" },
        { title: this.$t('bookings.sale.thead.plates'), class: "small head big-col" },
        { title: this.$t('bookings.sale.thead.fleet_company'), class: "small head big-col" },
      ]
    }
  }, 
  watch: {
    bookings(arr) {
      if (!arr) {
        arr = [];
      }

      this.setTab(arr)
      return arr;
    },
    tmpBooking(arr) {
      return arr;
    },
    currentList(arr) {
      return arr;
    },
    opt() {
      this.handleView();
    },
    filters: {
      deep: true,
      handler(filters) {
        return;
      }
    },
    $route() {
      this.tmpBooking = this.bookings
      this.currentList = this.tmpBooking;
      this.setTab(this.bookings)
    },
    loading() {
     
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  mounted() {
    this.tmpBooking = this.bookings
    this.currentList = this.tmpBooking;
    this.setTab(this.bookings)
  },
  beforeRouteUpdate (to, from, next) {
    this.tmpBooking = this.bookings
    // this.currentList = this.tmpBooking;
    next();
  },
  methods: {
    dateFormat(date) {
      // if(!date) return;
      return moment(date).format("DD[.]MM[.]YYYY");
    },
    hourFormat(date) {
      // if(!date) return;
      return moment(date).format("HH:mm a");
    },
    setTab(arr) {
      this.tmpBooking = arr;
      this.currentList = arr;
      this.$store.commit('bookings/updateCurrentBookingList', arr);
      // this.handleView();
      return arr;
    },
    handleView() {
      const from = (this.opt.page - 1) * this.opt.per;
      const to = this.opt.page * this.opt.per;
      if(this.tmpBooking) this.tmpBooking = [...this.currentList].slice(from, to);
    },
    // Animation
    beforeEnter(target) {
      gsap.set(target, {
        opacity: 0,
        y: 20,
      })
    },
    enter(target, done) {
      gsap.to(target, 0.3, {
        opacity: 1,
        y: 0,
        delay: 0.03 * target.rowIndex + 0.3,
        onComplete: done,
      })
    },
    beforeLeave(target) {
      const top = target.offsetTop;
      gsap.set(target, {
        position: 'absolute',
        top: top * target.rowIndex,
        delay: 0.3
      })
      
    },
    leave(target, done) {
      gsap.to(target, 0.3, {
        opacity: 0,
        y: -20,
        delay: 0.05 * target.rowIndex,
        onComplete: done
      })
      gsap.to(this.$refs.container, {duration: .2, scrollTo: 0});
    },
    updateListingView(){
      this.$store.dispatch('bookings/updateBookingDataForPagination', { ParkingId: this.$store.state.parkings.selected });
    },
    async handleLoading(){
      return this.$store.dispatch('bookings/triggerPageIsLoading');
    },
    async handleScrollEvent(){
      const containerElement = document.querySelector('.main-container');
      if(containerElement != null){
        //This calc is used to load the data when there is roughly 10 elements left to scroll
        if(Math.abs(containerElement.scrollHeight - containerElement.scrollTop) <= containerElement.clientHeight * 3){         
          if(this.$store.state.bookings.isCurrentlyLoading === false){          
            this.updateListingView();
          }
        }
        //This calc is used to check whether the data from API has been retrieved when it reaches the bottom of the table
        if(Math.abs(containerElement.clientHeight - (containerElement.scrollHeight - containerElement.scrollTop)) < 1) {
          const isPageLoading = await this.handleLoading();
          // Intended to be a failsafe just in case it is reaching the bottom and code is not calling the API to retrieve the data
          if(isPageLoading === false && this.$store.state.bookings.isAllContentLoaded === false){
            this.updateListingView();
          }
        }
      }
    },
    getDriverName(booking) {      
       
        if (booking?.fleetName){
          return booking?.fleetName
        }
        if (booking?.driverNameCognito && booking?.driverNameCognito !== 'n.a.'){
          return booking?.driverNameCognito
        }
        
        return this.$t('globals.unknow')

    },
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  .table {
    td {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
}
</style>