import i18n from '../i18n';
import { getLicensePlates } from '@/calls/licenseChecker';

const paginationLimitVal = 50;

const licenseChecker = {
  namespaced: true,
  state: {
    page: 0,
    requests: 0,
    data: [],   
    search: null,
    isAllContentLoaded: false,
    paginationLimit: paginationLimitVal,
    currentPage : 0,
    listLicense: [],
    currentDataList: [],
  },
  mutations: {
    updateData(state, data) {
      state.data = data;
    },
    updatePage(state, page) {
      state.currentPage = page;
    },   
    updateSearch(state, search) {
      state.search = search;
    },
    increaseRequest(state) {
      state.requests++;
    },
    decreaseRequest(state) {
      state.requests--;
    },
 
    updateProperty(state, { property, value }) {
      state[property] =  value;
    }

  },
  actions: {
    
    async getLicensePlates({ commit,state, dispatch }, updatePage = false) {
    
      commit('increaseRequest');
    
      if (updatePage)
      {
        commit('updatePage',  (state.currentPage + 1) ?? 1);
      }
      else{
      
        commit('updatePage',  1);
        commit('updateData', { property: 'isAllContentLoaded', value: false });
        commit('updateProperty',  { property: 'listLicense', value: null });
      }
     
      if(state.isAllContentLoaded === false && (state?.search?.trim().length > 0))
      {       
          const license = await getLicensePlates({
            search: state.search,
            listLimit: state.paginationLimit,
            page: state.currentPage,
          });
        
    
          if (license.hasError) {
            
            const error = license.error?.response?.data?.length ? license.error.response.data : license.error;
         
            dispatch('notifications/addNotifs', [{
              text: error ,
              type: 'error'
            }], { root: true });

          //   commit('updatePage',  1);
          //  commit('updateData', { property: 'isAllContentLoaded', value: false });
          //  commit('updateProperty',  { property: 'listLicense', value: null });

          
          } else {
           
            if (license && Object.keys(license).length === 0) {
              commit('updateData', null);
              commit('updateProperty',  { property: 'listLicense', value: null });
            } else {
            
              if (state.currentPage == 1){
                commit('updateData',  license);
                commit('updateProperty',  { property: 'listLicense', value: license });
              }
              else{
                if(license.length < paginationLimitVal){
                  commit('updateStateProperty', { property: 'isAllContentLoaded', value: true })
                }
                commit('updateProperty',  { property: 'listLicense', value: [...(state.listLicense || []), ...(license || [])] });
    
              }
    
          
            }
          }
      }     

      setTimeout(() => {
        commit('decreaseRequest');
      }, 500);
    },
   
  }
};

export default licenseChecker;
