
import { getUsers, patchUser, revokeUser, createUser } from '@/calls/users';

const users = {
  namespaced: true,
  state: {
    items: [],
    requests: 0,
    popups: {
      update: {
        visible: false,
        success: false,
        requests: 0,
        user: null,
        errors: {
          firstname: null,
          lastname: null,
          email: null
        },
        fields: {
          firstname: null,
          lastname: null,
          email: null
        }
      },
      add: {
        visible: false,
        success: false,
        requests: 0,
        errors: {
          firstname: null,
          lastname: null,
          email: null
        },
        fields: {
          firstname: null,
          lastname: null,
          email: null,
          locale: 'en'
        }
      },
      revoke: {
        visible: false,
        success: false,
        requests: 0,
        user: null,
      }
    }
  },
  mutations: {
    updateItems(state, items) {
      state.items = items;
    },
    increaseRequests(state) {
      state.requests++;
    },
    decreaseRequests(state) {
      state.requests--;
    },
    updatePopup(state, { popup, property, value }) {
      state.popups[popup][property] = value;
    },
    updatePopupError(state, { popup, field, value }) {
      state.popups[popup].errors[field] = value;
    },
    updatePopupField(state, { popup, field, value }) {
      state.popups[popup].fields[field] = value;
    },
    resetErrors(state, popup) {
      state.popups[popup].errors.firstname = null;
      state.popups[popup].errors.lastname = null;
      
      if (popup === 'add') {
        state.popups[popup].errors.email = null;
      }
    },
    resetFields(state, popup) {
      state.popups[popup].fields.firstname = null;
      state.popups[popup].fields.lastname = null;
      
      if (popup === 'add') {
        state.popups[popup].fields.email = null;
        state.popups[popup].fields.locale = 'en';
      }
    }
  },
  actions: {
    async getUsers({ commit, dispatch }) {
      commit('increaseRequests');

      try {
        const users = await getUsers();
        const planers = users.filter(u => !u.isOwner);
        commit('updateItems', planers);
      } catch (error) {
        dispatch('notifications/addNotifs', [{
          text: `Error while getting users : ${error}`,
          type: 'error'
        }], { root: true });
      }
      
      commit('decreaseRequests');
    },
    async updateUser({ state, commit, dispatch }) {
      commit('resetErrors', 'update');

      const properties = ['firstname', 'lastname', 'email'];
      const { fields, user } = state.popups.update;
      let hasError = false;

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        if (!fields[property] || !fields[property].length) {
          commit('updatePopupError', {
            popup: 'update', field: property, value: 'This field is required'
          });

          hasError = true;
        } else {
          const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (property === 'email' && !regex.test(fields[property])) {
            commit('updatePopupError', {
              popup: 'update', field: property, value: 'Please enter a valid email address'
            });

            hasError = true;
          }
        }
      }

      if (hasError) return;

      commit('updatePopup', {
        popup: 'update', property: 'requests', value: state.popups.update.requests + 1
      });

      try {
        const request = await patchUser(user.id, fields);
        commit('updatePopup', { popup: 'update', property: 'success', value: true });
        dispatch('getUsers');
      } catch (error) {
        dispatch('notifications/addNotifs', [{
          text: `Error while updating user : ${error}`,
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', {
        popup: 'update', property: 'requests', value: state.popups.update.requests - 1
      });
    },
    async revokeUser({ state, commit, dispatch }) {
      const { user } = state.popups.revoke;

      const request = await revokeUser(user.id);

      if (!request.hasError) {
        commit('updatePopup', {
          popup: 'revoke', property: 'success', value: true
        });
      } else {
        dispatch('notifications/addNotifs', [{
          text: `Error while revoking user : ${request.error}`,
          type: 'error'
        }], { root: true });
      }
    },
    async addUser({ state, commit, dispatch }) {
      commit('resetErrors', 'add');

      // Validation
      const properties = ['firstname', 'lastname', 'email', 'locale'];
      const { fields } = state.popups.add;
      let hasError = false;

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const value = fields[property];
        if (!value || !value.length) {
          commit('updatePopupError', {
            popup: 'add', field: property, value: 'This field is required'
          });

          hasError = true;
        } else {
          const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (property === 'email' && !regex.test(value)) {
            commit('updatePopupError', {
              popup: 'add', field: property, value: 'Please enter a valid email address'
            });

            hasError = true;
          }
        }
      }

      if (hasError) return;

      const { items } = state;
      const emails = items.map(item => item.user && item.user.email);
      const index = emails.indexOf(fields.email.trim());

      if (index >= 0) {
        dispatch('notifications/addNotifs', [{
          text: 'The user already exists',
          type: 'error'
        }], { root: true });

        return;
      }

      // Call
      commit('updatePopup', {
        popup: 'add', property: 'requests', value: state.popups.add.requests + 1
      });

      try {
        const request = await createUser(fields);
        commit('updatePopup', {
          popup: 'add', property: 'success', value: true
        });
        dispatch('getUsers');
      } catch (error) {
        dispatch('notifications/addNotifs', [{
          text: `Error while updating user : ${error}`,
          type: 'error'
        }], { root: true });
      }

      commit('updatePopup', {
        popup: 'add', property: 'requests', value: state.popups.add.requests - 1
      });
    }
  }
};

export default users;
