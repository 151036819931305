<template>
  <div :class="['input input-calendar', { 'is-open': isOpen }]">
    <label
      v-if="label"
      class="label small"
    >
      {{ label }}
    </label>

    <input
      type="text"
      :value="inputValue"
    >

    <Sprite
      :type="'arrow-down'"
      :width="16"
      :height="10"
    />

    <button
      type="button"
      class="input-calendar-toggle"
      @click.prevent="toggleDropdown"
    />

    <div v-if="isOpen" class="input-calendar-dropdown">
      <div class="input-calendar-dropdown__container prevent">
        <nav class="input-calendar-dropdown__nav">
          <button
            type="button"
            class="prev-month"
            @click="subtractMonth"
          >
            <Sprite :key="'prev'" type="chevron" :width="10" :height="16" />
          </button>
          <h2 class="current-month">{{ month +' '+ year }}</h2>
          <button
            type="button"
            class="next-month"
            @click="addMonth"
          >
            <Sprite :key="'next'" type="chevron" :width="10" :height="16"/>
          </button>
        </nav>
        <section class="input-calendar-dropdown__body">
          <ol class="weekdays">
              <li
                v-for="(day, index) in days"
                :key="day + index"
                class="weekday"
              >
                {{ day }}
              </li>
          </ol>
          <ol class="dates">
            <li class="date date--before" v-for="(blank, i) in firstDayOfMonth" :key="blank+'date'+i">{{blank}}</li>
            <li 
              @click="handleDate"
              class="date"
              v-for="(date, i) in daysInMonth"  
              :key="date+i"
              :data-time="year + dateContext.format('MM') + digitFormat(date)" 
              :class="setClass(year + dateContext.format('MM') + digitFormat(date))">
              {{ date }}
            </li>
          </ol>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import Sprite from '@/components/elements/Sprite.vue';

export default {
  components: {
    Sprite
  },
  props: {
    value: {
      type: String,
      default: moment().format('DD[-]MM[-]YYYY')
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    year() {
      return this.dateContext.format('Y');
    },
    month() {
      return this.$t(`components.calendar.months.${this.dateContext.format('M')}`);
      // return this.dateContext.format('MMMM');
    },
    daysInMonth() {
      return this.dateContext.daysInMonth();
    },
    currentDate() {
      return this.dateContext.get('date');
    },
    firstDayOfMonth() {
      const firstDay = moment(this.dateContext).subtract((this.currentDate), 'days');
      return firstDay.weekday();
    },
    formatedValue() {
      return moment(this.value).format('YYYYMMDD');
    },
    inputValue() {
      return moment(this.value).format('DD[/]MM[/]YYYY');
    },
    days() {
      return [
        this.$t('components.calendar.days_abbr.1'),
        this.$t('components.calendar.days_abbr.2'),
        this.$t('components.calendar.days_abbr.3'),
        this.$t('components.calendar.days_abbr.4'),
        this.$t('components.calendar.days_abbr.5'),
        this.$t('components.calendar.days_abbr.6'),
        this.$t('components.calendar.days_abbr.7')
      ];
    }
  },
  data() {
    return {
      calendar: [],
      dateContext: moment(),
      // days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      isOpen: false
    };
  },
  methods: {
    digitFormat(date) {
      return (`0${date}`).slice(-2);
    },
    setClass(date) {
      if (this.formatedValue === date) return 'start-date end-date';
      return null;
    },
    // Useful
    handleDate(evt) {
      const { target } = evt;
      const date = target.getAttribute('data-time');
      this.$emit('onChange', date);
      this.isOpen = false;
    },
    addMonth() {
      this.dateContext = moment(this.dateContext).add(1, 'month');
    },
    subtractMonth() {
      this.dateContext = moment(this.dateContext).subtract(1, 'month');
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>