import { createStore } from 'vuex'

import tc from './tc';
import tw from './tw';
import user from './user';
import users from './users';
import popups from './popups';
import plates from './plates';
import booking from './booking';
import settings from './settings';
import services from './services';
import parkings from './parkings';
import bookings from './bookings';
import dashboard from './dashboard';
import companies from './companies';
import accounting from './accounting';
import translations from './translations';
import subscription from './subscription';
import subscriptions from './subscriptions';
import notifications from './notifications';
import authentication from './authentication';
import licenseChecker from './licenseChecker';

export default createStore({
  modules: {
    // not namespaced
    authentication,
    // namespaced
    tc,
    tw,
    user,
    users,
    popups,
    plates,
    booking,
    parkings,
    settings,
    services,
    bookings,
    dashboard,
    companies,
    accounting,
    translations,
    subscription,
    subscriptions,
    notifications,
    licenseChecker
  },
});
