import {
  getBookings,
  getStatus,
  getBooking,
} from '@/calls/bookings';

import moment from 'moment-timezone';

const paginationLimitVal = 20;
const bookings = {
  namespaced: true,
  state: {
    currentBookingList: [],
    bookings: [],
    sales: [],
    search: null,
    requests: {
      home: 0,
      bookings: 0,
      sales: 0
    },
    filters: {
      search: '',
      dates: {
        start: null,
        end: null,
      },
      paginationLimit: paginationLimitVal,
      currentPage: 1,
    },

    status: [],
    isAllContentLoaded: false,
    isDataLoaded: false,
    isCurrentlyLoading: false
  },
  mutations: {
    updateStatus(state, status) {
      state.status = status;
    },
    updateSearch(state, search) {
      state.search = search;
    },
    updateCurrent(state, booking) {
      state.current = booking;
    },
    updateBookings(state, bookings) {
      state.bookings = bookings;
    },
    addToExistingBookings(state, bookings) {
      let currentBookings = state.bookings;
      currentBookings.push(...bookings)
      state.bookings = currentBookings;
    },
    updateSales(state, sales) {
      state.sales = sales;
    },
    addToExistingSales(state, sales) {
      state.sales.push(...sales);
    },
    updateCurrentBookingList(state, currentBookingList) {
      state.currentBookingList = currentBookingList;
    },
    addToExistingCurrentBookingList(state, currentBookingList) {
      let curBookingsList = state.currentBookingList;
      curBookingsList.push(...currentBookingList)
      state.currentBookingList = curBookingsList;
    },
    updateStateProperty(state, { property, value }) {
      state[property] = value;
    },
    updateDynamicFilter(state, { property, value }) {
      state.filters[property] = value;
    },
    updateDynamicFilters(state, filters) {
      state.filters = filters;
    },
    updateRequestItem(state, { property, value }){
      state.requests[property] = value;
    },
    updateOneItem(state, { index, item, type }) {
      state[type][index] = item;
    },

    increaseRequests(state, type) {
      state.requests[type]++;
    },
    decreaseRequests(state, type) {
      state.requests[type]--;
    },
  },
  actions: {
    setBooking({ commit }, id) {
      const booking = getBooking(id);
      booking.then((promise) => {
        return commit('updateBooking', promise);
      });
    },
    setBookings({ commit, state }, { ParkingId, search, dates = true }) {
      commit('increaseRequests', 'bookings');
      const start = state.filters?.dates?.start;
      const end = state.filters?.dates?.end
      const booking = getBookings({
        ParkingId,
        startAt: start && dates ? moment(start).format('YYYY-MM-DD[T]00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
        endAt: end && dates ? moment(end).format('YYYY-MM-DD[T]23:59:59') : null,
        search: search && search.length > 0 ? search : null
      });


      return booking.then((promise) => {
        commit('updateBookings', promise);
        commit('decreaseRequests', 'bookings');
        return promise;
      });
    },

    setBookingsWithPagination({ commit, state }, { ParkingId, search, dates = true }) {
      commit('increaseRequests', 'bookings');
      commit('updateDynamicFilter', { property: 'currentPage', value: 1 });
      commit('updateDynamicFilter', { property: 'paginationLimit', value: paginationLimitVal });
      commit('updateStateProperty', { property: 'isAllContentLoaded', value: false })
      const start = state.filters?.dates?.start;
      const end = state.filters?.dates?.end;
      
      const booking = getBookings({
        ParkingId,
        startAt: start && dates ? moment(start).format('YYYY-MM-DD[T]00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
        endAt: end && dates ? moment(end).format('YYYY-MM-DD[T]23:59:59') : null,
        search: search && search.length > 0 ? search : null,
        page: state.filters?.currentPage,
        listLimit: state.filters?.paginationLimit
      });

      commit('updateStateProperty', { property: 'search', value: search })
      return booking.then((promise) => {
        commit('updateBookings', promise);
        commit('decreaseRequests', 'bookings');
        commit('updateDynamicFilter', { property: 'currentPage', value: (state.filters?.currentPage + 1) ?? 1 });
        return promise;
      });
    },


    setSales({ commit, state }, { ParkingId, search, dates = true }) {
      commit('increaseRequests', 'sales');
      const start = state.filters?.dates?.start;
      const end = state.filters?.dates?.end;
      const booking = getBookings({
        ParkingId,
        startAt: start && dates ? moment(start).format('YYYY-MM-DD[T]00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
        endAt: end && dates ? moment(end).format('YYYY-MM-DD[T]23:59:59') : null,
        search: search && search.length > 0 ? search : null
      });

      return booking.then((promise) => {
        const sales = [...promise];
        sales.sort((a, b) => {
          const startA = parseInt(moment(a.createdAt).format('YYYYMMDDHHmm'), 10);
          const startB = parseInt(moment(b.createdAt).format('YYYYMMDDHHmm'), 10);
          if(startA < startB) return 1;
          if(startA > startB) return -1;
          return 0;
        });

        commit('updateSales', sales);
        commit('decreaseRequests', 'sales');
        return promise;
      });
    },
    setBookingsSales({ commit, state }, { ParkingId, search, dates = true }) {
      commit('increaseRequests', 'bookings');
      commit('increaseRequests', 'sales');
      const start = state.filters?.dates?.start;
      const end = state.filters?.dates?.end;
      const booking = getBookings({
        ParkingId,
        startAt: start && dates ? moment(start).format('YYYY-MM-DD[T]00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
        endAt: end && dates ? moment(end).format('YYYY-MM-DD[T]23:59:59') : null,
        search: search && search.length > 0 ? search : null
      });

      return booking.then((promise) => {
        const sales = [...promise];
        sales.sort((a, b) => {
          const startA = parseInt(moment(a.createdAt).format('YYYYMMDDHHmm'), 10);
          const startB = parseInt(moment(b.createdAt).format('YYYYMMDDHHmm'), 10);
          if(startA < startB) return 1;
          if(startA > startB) return -1;
          return 0;
        });

        commit('updateBookings', promise);
        commit('updateSales', sales);
        commit('decreaseRequests', 'bookings');
        commit('decreaseRequests', 'sales');
        return promise;
      });
    },

    setBookingsSalesWithPagination({ commit, state }, { ParkingId, search, dates = true }) {
      commit('increaseRequests', 'bookings');
      commit('increaseRequests', 'sales');
      commit('updateDynamicFilter', { property: 'currentPage', value: 1 });
      commit('updateDynamicFilter', { property: 'paginationLimit', value: paginationLimitVal });
      commit('updateStateProperty', { property: 'isAllContentLoaded', value: false })
      const start = state.filters?.dates?.start;
      const end = state.filters?.dates?.end;
      
      const booking = getBookings({
        ParkingId,
        startAt: start && dates ? moment(start).format('YYYY-MM-DD[T]00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
        endAt: end && dates ? moment(end).format('YYYY-MM-DD[T]23:59:59') : null,
        search: search && search.length > 0 ? search : null,
        page: state.filters?.currentPage,
        listLimit: state.filters?.paginationLimit
      });

      return booking.then((promise) => {
        const sales = [...promise];
        sales.sort((a, b) => {
          const startA = parseInt(moment(a.createdAt).format('YYYYMMDDHHmm'), 10);
          const startB = parseInt(moment(b.createdAt).format('YYYYMMDDHHmm'), 10);
          if(startA < startB) return 1;
          if(startA > startB) return -1;
          return 0;
        });
        commit('updateBookings', promise);
        commit('updateSales', sales);
        commit('decreaseRequests', 'bookings');
        commit('decreaseRequests', 'sales');
        commit('updateDynamicFilter', { property: 'currentPage', value: (state.filters?.currentPage + 1) ?? 1 });
        return promise;
      });
    },
    triggerPageIsLoading({ commit, state }){
      
      if(state.isDataLoaded === false){
        
        commit('updateRequestItem', { property: 'bookings', value: 1 }); 
        commit('updateRequestItem', { property: 'sales', value: 1 });       

        return true;
      }
      return false;
    },
    updateBookingDataForPagination({ commit, state }, { ParkingId, search, dates = true }) {
      if(state.isAllContentLoaded === false){
        
        commit('updateStateProperty', { property: 'isCurrentlyLoading', value: true });
        commit('updateStateProperty', { property: 'isDataLoaded', value: false });
        commit('increaseRequests', 'sales');
        commit('updateDynamicFilter', { property: 'paginationLimit', value: paginationLimitVal });
        const start = state.filters?.dates?.start;
        const end = state.filters?.dates?.end;
        
        const booking = getBookings({
          ParkingId,
          startAt: start && dates ? moment(start).format('YYYY-MM-DD[T]00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
          endAt: end && dates ? moment(end).format('YYYY-MM-DD[T]23:59:59') : null,
          search: search && search.length > 0 ? search : null,
          page: state.filters?.currentPage,
          listLimit: state.filters?.paginationLimit
        });
  
        return booking.then((promise) => {
          const sales = [...promise];
          sales.sort((a, b) => {
            const startA = parseInt(moment(a.createdAt).format('YYYYMMDDHHmm'), 10);
            const startB = parseInt(moment(b.createdAt).format('YYYYMMDDHHmm'), 10);
            if(startA < startB) return 1;
            if(startA > startB) return -1;
            return 0;
          });
          if(promise.length < paginationLimitVal){
            commit('updateStateProperty', { property: 'isAllContentLoaded', value: true })
          }
          commit('addToExistingBookings', promise);
          commit('addToExistingSales', sales);
          commit('decreaseRequests', 'bookings');
          commit('decreaseRequests', 'sales');
          commit('updateDynamicFilter', { property: 'currentPage', value: (state.filters?.currentPage + 1) ?? 1 });
          commit('updateRequestItem', { property: 'bookings', value: 0 });
          commit('updateRequestItem', { property: 'sales', value: 0 });
          commit('updateStateProperty', { property: 'isDataLoaded', value: true });
          commit('updateStateProperty', { property: 'isCurrentlyLoading', value: false });
          
          return promise;
        });
      }
    },
    setStatus({ commit }, ) {
      // 0: {value: "10", label: "Booked"}
      // 1: {value: "21", label: "CheckedIn"}
      // 2: {value: "30", label: "CheckedOut"}
      const status = getStatus();
      const noIncluding = ["40", "20", "0"];
      return status.then((promise) => {
        const filtered = promise.status.filter((p) => noIncluding.indexOf(p.value) === -1 );
        return commit('updateStatus', filtered);
      });
    }
  }
};

export default bookings;
