<template>
  <router-view/>
</template>

<script>
export default {
  beforeMount() {
    const { params, name } = this.$route;
    if (params.id) this.$store.dispatch('booking/getBooking', { id: params.id });

    if (name === 'UpdateDuration') {
      this.$store.dispatch('booking/getSlotsTypes');
    }
  }
}
</script>