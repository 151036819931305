<template>
    <!-- TABLE -->
    <section
      ref="container"
      class="main-container"
      :class="{'no-booking': !bookings.length, 'main-container--loading': loading }"
    >
      <Stack v-if="!isIntegrated"/>
      <Integrated v-else/>

      <div
        v-show="loading"
        class="main-container-loader"
      >
        <loader />
      </div>

      <NoShow @onSuccess="onSuccess"/>
      <Checkout @onSuccess="onSuccess"/>
      <Checkin @onSuccess="onSuccess"/>
      <Reject @onSuccess="onSuccess"/>
      <RemoveBooking @onSuccess="onSuccess"/>
      <!-- <Instructions/>
      <Rules/> -->
    </section>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Stack from '@/components/partials/Dashboard/Stack';
import Integrated from '@/components/partials/Dashboard/Integrated';

import NoShow from '@/components/partials/Popups/NoShow';
import Reject from '@/components/partials/Popups/Reject';
import Checkin from '@/components/partials/Popups/Checkin';
import Checkout from '@/components/partials/Popups/Checkout';
import RemoveBooking from '@/components/partials/Popups/RemoveBooking';

export default {
  name: 'List',
  components: {
    Loader,
    Stack,
    Integrated,
    NoShow,
    Reject,
    Checkin,
    Checkout,
    RemoveBooking
},
  computed: {
    bookings() {
      return this.$store.state.dashboard.items;
    },
    loading() {
      return this.$store.state.dashboard.requests !== 0;
    },
    isIntegrated() {
      return this.$store.state.parkings.isIntegrated;
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) this.$refs.container.scrollTo(0, 0);
    }
  },
  methods: {
    onSuccess() {
      setTimeout(() => {
        this.$store.dispatch('dashboard/getData', { ParkingId: this.$store.state.parkings.selected });
      }, 1000);
    }
  }
}
</script>
