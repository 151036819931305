<template>
  <div
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <section>
      <Breadcrumbs
        :breadcrumbs="breadcrumbs"
        :current="1"
      />

      <form
        v-if="booking"
        novalidate
        @submit.prevent="onNext"
      >
        <fieldset class="container-row container-3-3-3">
          <NewSelect
            ref="slots"
            :name="'slots'"
            :label="$t('labels.freight_type')"
            :options="slots"
            :placeholder="form.slot ? translations[`${form.slot.translationKey}.name`] : $t('booking.update.duration.placeholders.freight_type')"
            :value="form.slot.id"
            :class="'select-input classic'"
            @onChange="slotChange"
          />
        </fieldset>

        <fieldset class="container-row ">
          <PackageSelection
            :packages="packages"
            :selected="form.pckg?.id"
            :loading="loadingPackages"
            @onChange="packageChange"
          />
        </fieldset>

        <fieldset class="container-row container-5-5">
          <PaymentMethodSelection
            :methods="methods"
            :selected="selectedMethods"
            @onChange="paymentChange"
          />
        </fieldset>

        <div class="main-container__form-buttons">
          <button
            type="submit"
            class="button"
            :disabled="disableNext"
          >
            {{ $t('globals.next') }}
          </button>
        </div>
      </form>

      <div
        v-show="loading"
        class="main-container-loader"
      >
        <loader />
      </div>
    </section>
  </div>
</template>

<script>
import Inputs from '@/components/elements/Inputs';
import NewSelect from '@/components/elements/NewSelect';
import Loader from '@/components/elements/Loader';
import Breadcrumbs from '@/components/modules/Breadcrumbs';
import PackageSelection from '@/components/modules/PackageSelection';
import PaymentMethodSelection from '@/components/modules/PaymentMethodSelection';

export default {
  components: {
    // Inputs,
    NewSelect,
    Breadcrumbs,
    Loader,
    PackageSelection,
    PaymentMethodSelection
  },
  beforeMount() {
    this.$store.dispatch('booking/getSlotsTypes');
  },
  mounted() {

  },
  computed: {
    form() {
      const form = this.$store.state.booking.form;
      return form;
    },
    booking() {
      const booking = this.$store.state.booking.data;
      return booking;
    },
    fleet() {
      const fleet = this.$store.state.booking.fleet;
      return fleet;
    },
    packages() {
      const { pckgs } = this.$store.state.booking;
      if (!pckgs) return [];
      return pckgs;
    },
    slots() {
      const slots = this.$store.state.booking.slots;
      if (!slots) return [];

      return slots.map(slot => {
        return {
          value: slot.id,
          label: this.translations[`${slot.translationKey}.name`]
        }
      });
    },
    loading() {
      return this.$store.state.booking.requests.booking !== 0;
    },
    translations() {
      return this.$store.state.translations.keys;
    },
    methods() {
      const methods = [];
      return [
        {
          value: 'paid',
          label: this.$t('bookings.update.duration.payment_methods.methods.paid'),
          sublabel: this.fleet ? this.$t('bookings.update.duration.payment_methods.methods.paid_desc', { name: this.fleet.name }) : null,
          disabled: !this.fleet
        },
        {
          value: 'at_parking',
          label: this.$t('bookings.update.duration.payment_methods.methods.at_parking'),
          sublabel: this.booking.priceDetails.payAtParkingFee > 0 ? this.$t('bookings.update.duration.payment_methods.methods.at_parking_desc', { number: this.booking.priceDetails.payAtParkingFee }) : null,
        }
      ]
    },
    selectedMethods() {
      const { form } = this.$store.state.booking;
      return form.payAtParking ? 'at_parking' : 'paid';
    },
    disableNext() {
      const packages = this.packages.map(p => p.id);
      const index = packages.indexOf(this.form.pckg?.id);
      return index < 0;
    },
    loadingPackages() {
      return this.$store.state.booking.requests.pckgs !== 0;
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  data() {
    return {
      breadcrumbs: [
        { label: this.$t('components.breadcrumbs.details') },
        { label: this.$t('components.breadcrumbs.duration') },
        { label: this.$t('components.breadcrumbs.review') },
      ]
    }
  },
  methods: {
    onNext() {
      this.$store.dispatch('booking/simulateBooking');
      this.$router.push({
        path: `/update/${this.$route.params.id}/review`
      });
    },
    slotChange(slot) {
      const selected = this.$store.state.booking.slots.filter(s => s.id === slot);
      this.$store.commit('booking/updateForm', { field: 'slot', value: selected[0] });
      this.$store.dispatch('booking/getAvailablePackages');
    },
    packageChange(pckg) {
      this.$store.commit('booking/updateForm', { field: 'pckg', value: pckg });
    },
    paymentChange(value) {
      this.$store.commit('booking/updateForm', { field: 'fleetId', value: value.value === 'paid' ? this.fleet.id : null });
      this.$store.commit('booking/updateForm', { field: 'payAtParking', value: value.value === 'at_parking' });
    }
  }
}
</script>

<style lang="scss">
.main-container {
  padding: 32px;
}

.button {
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
}
</style>