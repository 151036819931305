<template>
  <form
    class="authentification-form"
    action="#"
    @submit.prevent="LostPassword"
  >
    <h2 class="authentification__title authentification__titleForm">{{ $t('authentication.lost_password.title') }}</h2>
    <div v-if="!success">

      <!-- Global error -->
      <fieldset v-if="errors.global" class="fieldset-input__message"  :class="{'error': errors.global}">
        <p class="error-msg">{{ errors.global }}</p>
      </fieldset>

      <!-- Username -->
      <Fieldset 
        :type="'email'" 
        :label="$t('labels.email')"
        :name="'email'"
        :required="true"
        :error="errors.email"
        @input="onInput"
      /> 
      <!-- Submit -->
      <button 
        type="submit" 
        @click.prevent="LostPassword" 
        class="button no-spacing"
        :class="{disabled: !form.email}"
      >
        {{ $t('authentication.lost_password.recovery_link_send') }}
      </button>
      
      <!-- Footer -->
      <hr class="hr-h">
      <!-- <a class="small authentification-link authentification-link--blue" @click="loginPage">{{ $t('authentication.lost_password.back') }}</a> -->
      <router-link class="small authentification-link authentification-link--blue" :to="'/'">{{ $t('authentication.lost_password.back') }}</router-link>
      <div class="authentification-footer">
        <!-- <router-link class="small authentification-footer__link" :to="'login-help'">Login help</router-link> -->
        <a href="https://www.weareotra.com/#contact-us" class="small authentification-footer__link" target="_blank">{{ $t('authentication.lost_password.support') }}</a>
        <!-- <a
          v-if="supportLink"
          :href="supportLink"
          target="_blank"
          class="small authentification-footer__link"
        >
          {{ $t('authentication.lost_password.support') }}
        </a> -->
      </div>
    </div>
    <div v-else >
      <Sprite 
        :width="90"
        :height="98"
        type="email-send" 
      />
      <p
        class="success-msg"
        v-html="$t('authentication.lost_password.recovery_link_sent', { email: form.email })"
      />
      <hr class="hr-h">
      <div class="authentification-footer">
        <router-link class="small authentification-link authentification-link--green" :to="'/'">{{ $t('authentication.lost_password.back') }}</router-link>
        <a href="https://www.weareotra.com/#contact-us" class="small authentification-footer__link" target="_blank">{{ $t('authentication.lost_password.support') }}</a>
        <!-- <a
          v-if="supportLink"
          :href="supportLink"
          target="_blank"
          class="small authentification-footer__link"
        >
          {{ $t('authentication.lost_password.support') }}
        </a> -->
      </div>
    </div>
  </form>
</template>

<script>
import Sprite from '@/components/elements/Sprite'
import Fieldset from '@/components/modules/Authentication/Fieldset'

import { getSettings } from '@/calls/settings';

export default {
  name: 'LostPassword',
  components: {
    Sprite,
    Fieldset
  },
  beforeMount() {
    this.getSupportLink();
    
    this.$store.commit('updateFormProperty', {
          form: 'lostPassword',
          property: 'success',
          value: false
        });
  },
  data() {
    return {
      form: {
        email: '',
      },
      supportLink: 'https://www.weareotra.com/#contact-us'
    }
  },
  computed: {
    success() {
      return this.$store.state.authentication.lostPassword.success;
    },
    errors() {
      return this.$store.state.authentication.lostPassword.errors;
    }
  },
  methods: {
    loginPage() {
      this.$router.push('home');
    },
    onInput(evt) {
      const {target} = evt;
      this.form[target.name] = target.value;
    },
    async LostPassword() {
      const form = 'lostPassword';
      const { email } = this.form
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.$store.commit('updateErrors', {
        form, property: 'email', error: !email.trim() ? this.$t('notifications.errors.authentication.email_required') : ''
      });

      if(!this.errors.email) {
        this.$store.commit('updateErrors', {
          form, property: 'email', error: !regex.test(email) ? this.$t('notifications.errors.authentication.email_invalid') : ''
        });
      }

      // Global
      this.$store.commit('updateErrors', { form, property: 'global', error: '' });
      if(this.errors.email) return;
      this.$store.dispatch('lostPassword', { email });
    },
    async getSupportLink() {
      const settings = await getSettings();
      if (settings && settings.websiteContactPage) {
        this.supportLink = settings.websiteContactPage;
      }
    }
  }
}
</script>

<style lang="scss">
  .no-spacing {
    width: 100%;
    margin-top: 0!important;
    font-size: rem(16px);
    text-transform: uppercase;
    padding: 15px 30px;
  }
  .hr-h {
    width: 100%;
    height: 1px;
    margin: 20px 0;
  }
  .button {
    transition: opacity .3s $ease-out-quad;
  }
  .disabled {
    opacity: .5;
    pointer-events: none;
  }
</style>
