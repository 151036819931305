<template>
    <a
      class="download-button"
      href="#"
      @click="$emit('click')"
    >
    <span class="svg"><img src="../../assets/svg/download.svg" /></span>
    <template v-if="label"><span class="download-label"> </span></template>       
    </a>
</template>
  
  <script>
  export default {
    name: 'DownloadButton',
    props: {
        label: {
            type: String,
            default: null
        }  
    }
  }
  </script>

<style lang="scss" scoped>
.download-button {
  margin-top: 2px;
  margin-right: -21px;
}
.download-label {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 100%;
  text-transform: none;
  padding-left: 8px;
}
</style>
  