<template>
  <article class="page">
    <h1 class="page-title container">{{ title }}</h1>
    <h2 class="page-subtitle">{{ subtitle }}</h2>
    <Components :components="components" />
  </article>
</template>

<script>
import Components from '@/components/partials/Components.vue';

export default {
  name:"Terms",
  components: { 
    Components
  },
  data() {
    return {
      title: this.$t(`terms.title`),
      subtitle: this.$t(`terms.subtitle`),
      components: {
        Content: this.terms_content(),
      }
    }
  },
  methods: {
    terms_content() {
      const content = [];

      for (let i = 0; i < 13; i++) {
        content.push({
          title: this.$t(`terms.content.${i}.title`),
          content: this.$t(`terms.content.${i}.content`)
        });
      }

      return content;
    }
  }
}
</script>

<style lang="scss">
  .page {
    padding: 40px;

    &-title {
      font-size: rem(34px);
      letter-spacing: 0;
      font-weight: 400;
      text-align: center;

      // transform: translate(-100px);
    }

    &-subtitle {
      margin-top: 10px;
      font-weight: 400;
      font-size: rem(24px);
      color: $black;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 50px;
    }
  }
</style>
