<template>
  <Popup
    v-if="popup.visible"
    :classes="'popup-pay'"
  >
    <template v-slot:content>
      <template v-if="popup.booking">
        <div class="popup-header">
          <h2 class="h4">{{ popup.booking.driverName ? popup.booking.driverName : $t('globals.no_name') }}</h2>
        </div>

        <table class="popup-table">
          <tbody>
            <tr
              v-for="row in table"
              :key="`popup-${row.property}`"
            >
              <td>{{ row.label(popup.booking) }}</td>
              <td>{{ row.property(popup.booking) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('globals.total') }} :</td>
              <td>{{ fixedDecimal(popup.booking.priceDetails.totalPrice) }}€</td>
            </tr>
          </tfoot>
        </table>

        <div class="popup-actions">
          <button
            class="button button-ghost"
            @click="close"
          >
            {{ $t('globals.cancel') }}
          </button>
          <button
            class="button"
            @click="pay"
          >
            {{ $t('globals.confirm_payment') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';

export default {
  name: 'Pay',
  components: {
    Loader,
    Popup
  },
  data() {
    return {
      table: [
        {
          label: (booking) => this.$t('bookings.popups.pay.duration'),
          property: (booking) => `${Math.floor(booking.totalDurationStay / 60)}h${Math.abs(booking.totalDurationStay) % 60}`
        },
        {
          label: (booking) => this.$t('bookings.popups.pay.extra_hour'),
          property: (booking) => `${Math.floor(booking.extraDurationStay / 60)}h${Math.abs(booking.extraDurationStay) % 60}`
        },
        {
          label: (booking) => this.$t('bookings.popups.pay.price'),
          property: (booking) => `${booking.priceDetails.packagePriceWithoutFee}€`
        },
        {
          label: (booking) => {
            if (booking.priceDetails.extraDurationType === 'Package') return this.$t('bookings.popups.pay.extra_package', { price: booking.priceDetails.extraDurationPackageUnitPrice });
            return this.$t('bookings.popups.pay.extra', { price: booking.packageExtensionPrice });
          },
          property: (booking) => `${booking.priceDetails.extraDurationPriceWithoutFee}€`
        },
        {
          label: (booking) => this.$t('bookings.popups.pay.parking_charge'),
          property: (booking) => `${booking.priceDetails.payAtParkingPriceWithoutFee}€`
        },
        {
          label: (booking) => this.$t('bookings.popups.pay.service_fee', { percent: booking.priceDetails.feePercent }),
          property: (booking) => {
            const { priceDetails } = booking;
            return `${priceDetails.packageFee + priceDetails.extraDurationFee + priceDetails.payAtParkingFee}€`
          }
        }
      ],
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.pay;
    }
  },
  methods: {
    close() {
      this.$store.commit('popups/updatePopup', {
        type: 'pay', property: 'visible', value: false
      });
    },
    async pay() {
      const pay = await this.$store.dispatch('popups/pay');
      if (!pay.hasError) this.$emit('onSuccess');
    },
    fixedDecimal(num) {
      return Math.round(num * 100) / 100
    }
  }
}
</script>