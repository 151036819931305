<template>
  <main class="not-logged">
    <header class="not-logged-header">
      <Sprite 
        :type="'logo'"
        :width="76"
        :height="72"
      />
      <h1 class="not-logged__title">{{ $t('authentication.title') }}</h1>
    </header>
    <section class="not-logged-container">
      <router-view/>
    </section>

    
  </main>
</template>


<script>
import Sprite from '@/components/elements/Sprite.vue';

export default {
  name: 'AuthStateApp',
  components: {
    Sprite
  },
  computed: {
    authState() {
      return this.$store.state.authentication.authState;
    },
    user() {
      return this.$store.state.authentication.tokens;
    }
  },
}
</script>

<style lang="scss">
  .not-logged {
    background-image: url('~@/assets/img/login-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $snap-primary;
    background-attachment: fixed;
    padding-bottom: 50px;

    &-container {
      width: 100%;
      max-width: 948px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 24px;
      padding-right: 24px;
      background: $white;
    }

    &-header {
      position: relative !important;
      width: 380px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $white;
      margin: 0 auto 30px;
      padding-top: 50px;
    }
  
    &-link {
      transition: color .3s $ease-out-quart;
      color: $grey;
      font-family: $secondary;
      font-size: rem(14px);
      line-height: 1;
      cursor: pointer;

      &:hover {
        color: $mediumgreen;
      }

      &--green {
        color: $mediumgreen;
      }

      &--blue {
        color: $snap-primary;
      }
    }

    &-registration {
      transition: color .3s $ease-out-quart;
      font-family: $secondary;
      font-size: rem(16px);
      color: $grey;
      cursor: pointer;

      &:hover {
        color: $mediumgreen;
      }
    }

    &-form {
      background: $white;
      border-radius: 16px;
      padding: 30px;

      text-align: center;
      color: $snap-primary;
    }

    &__title {
      font-size: rem(34px);
      letter-spacing: 0;
      text-transform: none;
    }


    
  }
</style>
