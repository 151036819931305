<template>
  <div
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <Breadcrumbs
      :breadcrumbs="breadcrumbs"
      :current="2"
    />

    <template v-if="booking && form.slot && form.pckg">
      <div class="step-details-section">
        <h2 class="h4">{{ $t('bookings.update.review.titles.details') }}</h2>
        <table cellspacing="0" class="table table-booknow__review">
          <tbody>
            <tr>
              <td>
                <div class="main-left__result result">
                  <address class="result--left">
                    <h3 class="h4">{{ parking.internationalName || parking.localName }}</h3>
                    <p>{{ parking.address.street }} {{ parking.address.zipCode }} {{ parking.address.city }}, {{parking.address.country.name}}</p>
                  </address>
                </div>
              </td>
              <td>
                <button
                  v-if="parking?.securityLevel?.icon && parking.securityLevel.id !== 6"
                  @click="openSecurityLevels"
                  :class="'security-blazon security--'+parking?.securityLevel?.icon"
                  type="button"
                >
                  {{ parking?.securityLevel?.icon }}
                </button>
              </td>
            </tr>
            <!-- Created date -->
            <!-- <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.booking_date') }}</td>
              <td>{{ moment(booking.createdAt).format('DD MMMM[,] YYYY') }}</td>
            </tr> -->
            <!-- Drivername -->
            <tr v-if="booking.drivername">
              <td class="nickname">{{ $t('bookings.update.review.labels.driver') }}</td>
              <td>{{ booking.drivername }}</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.booking_reference') }}</td>
              <td>{{ booking.id }}</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.truck') }}</td>
              <td>{{ form.plate }}</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.date') }}</td>
              <td>{{ moment(booking.startAt).format('DD MMMM[,] YYYY') }}</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.check_in') }}</td>
              <td>{{ formatDate(booking.startAt).hoursDigits }} {{ formatDate(booking.startAt).hoursAmPm }}</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.check_out') }}</td>
              <td>
                {{ moment(booking.startAt).tz(form.pckg.parking.timeZoneId).add(form.pckg.duration, "hours").format("hh:00")}}
                {{ moment(booking.startAt).tz(form.pckg.parking.timeZoneId).add(form.pckg.duration, "hours").format("A") === 'PM' ? 'pm' : 'am' }}
              </td>
                  <!-- {{ moment(booking.startAt).tz(form.pckg.parking.timeZoneId).add(form.pckg.duration, "hours").format("A") === "AM" ? $t('globals.am') : $t('globals.pm')}} -->
              <!-- <td>{{ formatDate(booking.startAt).hoursDigits }} {{ formatDate(booking.endAt).hoursAmPm }}</td> -->
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.place') }}</td>
              <td>{{ translations[`${form.slot.translationKey}.name`] }}</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.duration') }}</td>
              <td>{{ form.pckg.duration }} {{ form.pckg.duration != "1" ? $t('globals.hours').toLowerCase() : $t('globals.hour').toLowerCase() }} </td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.price') }}</td>
              <td>{{ form.pckg.price }} €</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.extension_price') }}</td>
              <td>{{ form.pckg.extensionPrice}}€ / {{ $t('globals.hour') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="step-details-section">
        <h2 class="h4">{{ $t('bookings.update.review.titles.payment') }}</h2>
        <table cellspacing="0" class="table table-booknow__review">
          <tbody>
            <!-- Payment method -->
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.payment') }}</td>
              <td>{{ paymentMethod }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="simulated"
        class="step-details-section"
      >
        <h2 class="h4">{{ $t('bookings.update.review.titles.total') }}</h2>
        <table cellspacing="0" class="table table-booknow__review">
          <tbody>
            <tr v-if="simulated.payAtParking && simulated.priceDetails.payAtParkingFee > 0">
              <td class="nickname">{{ $t('bookings.update.review.labels.parking_fee') }}</td>
              <td>{{ simulated.priceDetails.payAtParkingFee }} €</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.booking_fee', { percentage: booking.priceDetails.feePercent }) }}</td>
              <td>{{ simulated.priceDetails.packageFee }} €</td>
            </tr>
            <tr>
              <td class="nickname">{{ $t('bookings.update.review.labels.total') }}</td>
              <td>{{ simulated.priceDetails.totalPrice }} €</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="button button-big" type="submit" @click="updateBooking">
        {{ $t('globals.confirm') }}
      </button>
    </template>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Sprite from '@/components/elements/Sprite';
import Breadcrumbs from '@/components/modules/Breadcrumbs';
import Loader from '@/components/elements/Loader';
import { formatDate } from '@/helpers/helpers';

export default {
  name: 'Review',
  components: {
    Breadcrumbs,
    Loader
    // Sprite
  },
  computed: {
    form() {
      const form = this.$store.state.booking.form;
      return form;
    },
    booking() {
      const booking = this.$store.state.booking.data;
      return booking;
    },
    simulated() {
      const booking = this.$store.state.booking.simulated;
      return booking;
    },
    parking() {
      return this.$store.state.booking.data.package.parking;
    },
    translations() {
      return this.$store.state.translations.keys;
    },
    paymentMethod() {
      const form = this.$store.state.booking.form;
      return form.fleetId ? this.$t('globals.payment_methods.already_paid') : this.$t('globals.payment_methods.at_parking');
    },
    loading() {
      return this.$store.state.booking.requests.booking !== 0;
    },
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  data() {
    return {
      moment,
      formatDate,
      breadcrumbs: [
        { label: this.$t('components.breadcrumbs.details') },
        { label: this.$t('components.breadcrumbs.duration') },
        { label: this.$t('components.breadcrumbs.review') },
      ]
    }
  },
  beforeMount() {
  },
  methods: {
    updateBooking() {
      this.$store.dispatch('booking/updateBooking');
    },
    openSecurityLevels() {
      const index = this.parking.securityLevel ? 4 - this.parking.securityLevel.id : 0;
      this.$store.commit('securityLevels/updateIndex', index);
      this.$store.commit('securityLevels/toggle');
    }
  }
}
</script>

<style lang="scss" scoped>
  .table {
    margin-top: 30px;
    border: 1px solid $lightestgrey;
    border-top: 0px solid $lightestgrey;
    border-bottom: 0px solid $lightestgrey;

    td {
      width: 200px;
    }
  }

  .security-blazon {
    display: inline-block;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 12px 9px;
    border: 1px solid $mediumgreen;
    border-radius: 3px;
    background-color: $white;
    color: $mediumgreen;
    font-family: $primary;
    font-weight: 600;
    font-size: rem(14px);
    text-transform: uppercase;
    line-height: 1;
    transition: color .3s $ease-out-quart, background-color .3s $ease-out-quart;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $mediumgreen;
    }
  }

  .step-details-section {
    &:not(:first-child) {
      margin-top: 34px;
    }

    + .button {
      margin-top: 30px;
    }
  }

  .step-details-baseline {
    margin-top: 24px;
    margin-bottom: 32px;
    color: $snap-primary;
    font-family: $secondary;
    font-size: rem(18px);
    font-weight: 500;
  }
</style>

<style lang="scss">
.step-details-baseline {
  u {
    cursor: pointer;
    color: $snap-primary;
    font-family: $secondary;
    font-size: rem(18px);
    font-weight: 500;
    text-decoration: underline;
    transition: color .3s $ease-out-quart;

    &:hover {
      color: $mediumgreen;
    }
  }
}
</style>