<template>
  <div ref="container" :class="['input input-time', { 'is-open': isOpen }]">
    <label
      v-if="label"
      class="label small"
    >
      {{ label }}
    </label>

    <div class="input-time-input">
      <input
        type="text"
        :value="inputValue"
      >

      <Sprite
        :type="'arrow-down'"
        :width="16"
        :height="10"
      />

      <button
        type="button"
        class="input-time-toggle"
        @click.prevent.stop="toggleDropdown"
      />


      <div v-if="isOpen" class="input-time-dropdown">
        <h3 class="input-time-title">{{ $t('components.time.title') }}</h3>

        <div class="input-time-slider">
          <h4 class="input-time-slider-title">{{ $t('components.time.hours') }}</h4>
          <div class="input-time-slider-wrapper">
            <button
              type="button"
              class="input-time-slider-button prev"
              @click="onNext('hours')"
            >
              <Sprite
                :type="'chevron-up'"
                :width="15"
                :height="11"
              />
            </button>
            <span class="input-time-slider-value">{{ hoursValue }}</span>
            <button
              type="button"
              class="input-time-slider-button next"
              @click="onPrev('hours')"
            >
              <Sprite
                :type="'chevron-down'"
                :width="15"
                :height="11"
              />
            </button>
          </div>
        </div>
        <span class="input-time-separator">:</span>
        <div class="input-time-slider">
          <h4 class="input-time-slider-title">{{ $t('components.time.minutes') }}</h4>
          <div class="input-time-slider-wrapper">
            <button
              type="button"
              class="input-time-slider-button prev"
              @click="onNext('minutes')"
            >
              <Sprite
                :type="'chevron-up'"
                :width="15"
                :height="11"
              />
            </button>
            <span class="input-time-slider-value">{{ minutesValue }}</span>
            <button
              type="button"
              class="input-time-slider-button next"
              @click="onPrev('minutes')"
            >
              <Sprite
                :type="'chevron-down'"
                :width="15"
                :height="11"
              />
            </button>
          </div>
        </div>

        <div class="input-time-actions">
          <button
            type='button'
            class="button button-ghost"
            @click="reset"
          >
            {{ $t('globals.cancel') }}
          </button>
          <button
            type='button'
            class="button"
            @click="onChange"
          >
            {{ $t('globals.apply') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    value: {
      type: String,
      default: moment().format('HH:mm')
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    formatedValue() {
      return moment(this.value).format('YYYYMMDD');
    },
    inputValue() {
      return this.value;
    },
    hoursValue() {
      const split = this.dropdownValue.split(':');
      const parsed = parseInt(split[0], 10);
      return parsed < 10 ? `0${parsed}` : parsed;
    },
    minutesValue() {
      const split = this.dropdownValue.split(':');
      const parsed = parseInt(split[1], 10);
      return parsed < 10 ? `0${parsed}` : parsed;
    }
  },
  data() {
    return {
      isOpen: false,
      startValue: null,
      dropdownValue: null,
      _documentClick: this.onDocumentClick.bind(this)
    };
  },
  mounted() {
    document.addEventListener('click', this._documentClick);
  },
  unmounted() {
    document.removeEventListener('click', this._documentClick);
  },
  methods: {
    onChange() {
      this.$emit('onChange', this.dropdownValue);
      this.isOpen = false;
    },
    toggleDropdown() {
      this.startValue = this.value;

      if (this.isOpen) {
        this.isOpen = false;
        return;
      }

      this.dropdownValue = this.value;
      this.isOpen = true;
    },
    reset() {
      this.isOpen = !this.isOpen;
    },
    onPrev(type) {
      const split = this.dropdownValue.split(':');
      
      if (type === 'minutes') {
        const minutes = parseInt(split[1], 10);
        const value = this.formatZero(minutes === 0 ? 59 : minutes - 1);
        this.dropdownValue = `${this.formatZero(split[0])}:${this.formatZero(value)}`;
        return;
      }

      const hours = parseInt(split[0], 10);
      const value = hours === 0 ? 23 : hours - 1;
      this.dropdownValue = `${this.formatZero(value)}:${this.formatZero(split[1])}`;
    },
    onNext(type) {
      const split = this.dropdownValue.split(':');
      
      if (type === 'minutes') {
        const minutes = parseInt(split[1], 10);
        const value = minutes === 59 ? 0 : minutes + 1;
        this.dropdownValue = `${this.formatZero(split[0])}:${this.formatZero(value)}`;
        return;
      }

      const hours = parseInt(split[0], 10);
      const value = hours === 23 ? 0 : hours + 1;
      this.dropdownValue = `${this.formatZero(value)}:${this.formatZero(split[1])}`;
    },
    formatZero(value) {
      const val = parseInt(value, 10);
      return val < 10 ? `0${val}`: val;
    },
    onDocumentClick(e) {
      const { target } = e;
      const isDescendant = this.isDescendant(this.$refs.container, target);
      if (!isDescendant && this.isOpen) {
        this.toggleDropdown();
      }
    },
    isDescendant(parent, child) {
      let node = child.parentNode;
      while (node) {
        if (node === parent) return true;
        node = node.parentNode;
      }

      return false;
    }
  }
};
</script>