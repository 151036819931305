const notifications = {
  namespaced: true,
  state: {
    isVisible: false,
    timeout: null,
    items: []
  },
  mutations: {
    updateVisibility(state, flag) {
      state.isVisible = flag;
    },
    reset(state) {
      state.items = [];
      state.isVisible = false;
    },
    remove(state, notif) {
      const index = state.items.indexOf(notif);

      if (index >= 0) {
        state.items.splice(index, 1);
      }

      if (state.items.length === 0) {
        state.isVisible = false;
      }
    },
    add(state, notif) {
      state.items.push(notif);
    },
    updateTimeout(state) {
      if (state.timeout) {
        clearTimeout(state.timeout);
      }
    }
  },
  actions: {
    addNotifs({ commit }, notifs) {
      for (let i = 0; i < notifs.length; i++) {
        const notif = notifs[i];
        commit('add', notif);
      }

      commit('updateVisibility', true);
    }
  }
};

export default notifications;
