<template>
  <!-- FILTERS -->
  <section class="main-filters">
    <div v-if="add || remove" class="main-filters-buttons">
      <button
        v-if="add"
        class="button"
        @click="$emit('onAdd')"
      >
        {{ $t(add) }}
      </button>
      <button
        v-if="remove"
        class="button"
        @click="$emit('onRemove')"
      >
        {{ $t(remove) }}
      </button>
    </div>
    <Stats
      v-if="stats"
      :stats="stats.items"
      :loading="stats.requests !== 0"
    />

    <QuickSearch
      v-show="quickSearch" 
      ref="quickSearch"
      @search="onSearch"
      v-on:keyup.enter="onEnterSearch"
    />
  </section>
</template>

<script>
import QuickSearch from '@/components/modules/QuickSearch';
import Stats from '@/components/partials/Header/Stats';

export default {
  name: 'Filters',
  components: {
    QuickSearch,
    Stats,
  },
  props: {
    add: {
      type: String,
      default: null
    },
    remove: {
      type: String,
      default: null
    },
    quickSearch: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: false,
    },
    stats: {
      type: [Object, Boolean],
      default: null
    }
   
  },
  data(){
    return {
      searchValue: ''
    }
  },
  methods: {
    onSearch(value) {
      const { name } = this.$route;
      const { id } = this.$store.state.parkings.current;
      const { selected } = this.$store.state.parkings;
      
      switch (name) {
        case 'Home':
          this.$store.commit('dashboard/updateFilters', { property: 'search', value });
          this.$store.dispatch('dashboard/getData', { ParkingId: selected });
          break;
        case 'Sale':
          this.$store.commit('bookings/updateSearch', value);
          this.$store.dispatch('bookings/setSales', {
            ParkingId: id,
            search: value
          });
          break;
        case 'LicenseChecker':               
          if (typeof value === 'string')  {
            this.searchValue = value;             
          }              
          break;
        default:
          this.$store.commit('bookings/updateSearch', value);
          this.$store.dispatch('bookings/setBookingsWithPagination', {
            ParkingId: id,
            search: value
          });
          break;
      }
    },

    onEnterSearch() {
      const { name } = this.$route;
      
      if (name === 'LicenseChecker'){
        this.$store.commit('licenseChecker/updateSearch', this.searchValue);        
        this.$store.dispatch('licenseChecker/getLicensePlates');
        this.searchValue = '';
        this.$store.commit('licenseChecker/updateSearch', this.searchValue);
       
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.main-filters {
  .main-filters-buttons {
    display: flex;
    align-items: center;
    margin-right: auto;

    .button {
      margin-right: 24px;
    }
  }

  .button {
    width: auto;
    padding: 18px 18px 16px;
    margin: 0 auto 0 0;
  }

  .stats {
    margin-right: auto;
  }
}
</style>