<template>
  <td :class="body.class">
    <template v-if="!body.condition || body.condition(el)">
      <template v-if="!body.actions">
        <p v-html="body.title(el)" />
        <Sprite 
          v-if="body.icon"
          :width="body.icon.width"
          :height="body.icon.height"
          :type="body.icon.type"
          @click="body.action(el)"
        />
        <button
          v-if="body.button && body.button(el)"
          class="button"
          @click="body.action(el)"
        >
          {{ body.button(el) }}
        </button>
        <template v-if="body.buttons">
          <button
            v-for="(butt, index) in body.buttons(el)"
            :key="`button-${index}-${butt.id}`"
            :class="`button ${butt.classes}`"
            @click="butt.action(el)"
          >
            {{ butt.label }}
          </button>
        </template>
      </template>
      <template v-else>
        <div
          v-if="body.actions(el).length"
          ref="actions"
          :class="['actions', { 'actions--top': last }]"
          @click.stop
        >
          <button
            class="actions-toggle"
            @click.prevent="toggleActions"
          >
            <Sprite
              type="three-dots"
              :width="26"
              :height="26"
            />
          </button>
          <div
            v-if="open"
            class="actions-dropdown"
          >
            <ul class="actions-list">
              <li
                v-for="(action, index) in body.actions(el)"
                :key="`actions-${index}-${action.label}`"
                class="actions-item"
              >
                <button
                  class="actions-action"
                  @click.stop="action.action(el)"
                >
                  {{ action.label }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </template>
  </td>
</template>

<script>
/* eslint-disable */
import Sprite from '@/components/elements/Sprite';

export default {
  name: 'TBody',
  components: {
    Sprite
  },
  props:{
    body: {
      type: [Array, Object]
    },
    el: {
      type: [Array, Object, String]
    },
    last: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      _documentClick: this.onDocumentClick.bind(this)
    }
  },
  methods: {
    toggleActions() {
      this.open = !this.open;

      if (this.open) {
        document.addEventListener('click', this._documentClick);
      } else {
        document.removeEventListener('click', this._documentClick);
      }
    },
    onDocumentClick(e) {
      this.toggleActions();
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  cursor: default;

  &:hover .svg {
    fill: $mediumgreen;
  }

  .svg {
    cursor: pointer;

    &:hover {
      fill: #004E40;
    }
  }
}

// Size are adapted to the 75% zoom
.actions {
  position: relative;
  width: 26px;
  height: 26px;

  &.actions--top {
    .actions-dropdown {
      top: auto;
      bottom: calc(100% + 10px);
    }
  }

  &-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: $mediumgreen;
    transition: background-color .3s $ease-out-quart;

    &:not(:hover) {
      .svg {
        fill: $white !important;
      }
    }

    &:hover {
      background-color: $green;

      .svg {
        fill: $white !important;
      }
    }

    .svg {
      fill: $white;

      &:hover {
        fill: $white !important;
      }
    }
  }

  &-dropdown {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    z-index: 1;
    min-width: 170px;
    border: 1px solid rgba(31, 43, 96, .05);
    background-color: $white;
    box-shadow: 4px 4px 10px rgba(30, 43, 54, .08);
  }

  &-list {
    /* display: flex; */
    padding: 12px 0;
    list-style-type: none;
  }

  &-action {
    display: block;
    width: 100%;
    padding: 4px 19px;
    font-family: $secondary;
    font-size: rem(18px);
    font-weight: 500;
    color: $snap-primary;
    line-height: 1;
    text-align: right;
    cursor: pointer;

    &:hover {
      background-color: rgba(173, 176, 186, .2);
    }
  }
}
</style>
