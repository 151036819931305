<template>
  <div
    ref="container"
    class="main-container main-container-edit-subscription"
    :class="{ 'main-container--loading': loading }"
  >
    <section>
      <form
        novalidate
        @submit.prevent
      >
        <fieldset class="container-row container-6-3">
          <div>
            <div class="container-row container-5-5">
              <Inputs
                ref="name"
                type="text"
                :label="$t('labels.name')"
                :value="form.name"
                :required="true"
                @input="onInput($event, 'name')"
              />

              <Inputs
                ref="maxVacancy"
                type="number"
                :label="$t('labels.parking_slots')"
                :value="form.maxVacancy"
                :required="true"
                @input="onInput($event, 'maxVacancy')"
              />
            </div>

            <div class="container-row container-5-5">
              <Inputs
                ref="startAt"
                type="date"
                :label="$t('labels.start_date')"
                :value="form.startAt"
                :required="true"
                @input="onInput($event, 'startAt')"
              />

              <Inputs
                ref="endAt"
                type="date"
                :label="$t('labels.end_date')"
                :value="form.endAt"
                :required="true"
                @input="onInput($event, 'endAt')"
              />
            </div>

            <div class="container-row container-5-5">
              <Checkbox
                ref="status"
                name="status"
                :label="$t('labels.active')"
                :required="false"
                :value="form.status"
                :big="true"
                @input="handleCheckbox"
              />
            </div>

            <div class="container-row width-100">
              <div class="form-days">
                <label class="label small">{{ $t('labels.active_days') }}</label>

                <Checkbox
                  v-for="day in days"
                  :key="`day-${day.value}`"
                  :name="`day-${day.value}`"
                  :label="day.label"
                  :required="false"
                  :value="form.weekDays.indexOf(day.value) >= 0"
                  @onChange="onDay(day.value)"
                />
              </div>
            </div>

            <div class="container-row container-5-5">
              <Select
                :label="$t('labels.start_time')"
                :placeholder="''"
                :value="getValue('startTimeAt')"
                :options="getAvailableHours('start')"
                :class="'classic'"
                name="day-start"
                @onChange="onTime(`${$event}:00`, 'startTimeAt')"
              />

              <Select
                :label="$t('labels.end_time')"
                :placeholder="''"
                :value="getValue('endTimeAt')"
                :options="getAvailableHours('end')"
                :class="'classic'"
                name="day-end"
                @onChange="onTime(`${$event}:00`, 'endTimeAt')"
              />
            </div>

            <div class="form-submit">
              <button 
                ref="submit"
                class="button"
                type="submit"
                @click.prevent.stop="onSubmit"
              >
                {{ $t('globals.save') }}
              </button>
            </div>
          </div>

          <div class="plates">
            <span class="label small">{{ $t('labels.plates') }}</span>

            <div class="table-container">
              <table
                class="table"
                cellspacing="0"
              >
                <!-- TABLE BODY -->
                <tbody>
                  <tr
                    v-for="plate in form.licensePlateList"
                    :key="plate"
                  >
                    <TBody 
                      v-for="(b, index) in tbodyPlates"
                      :key="index"
                      :body="b" 
                      :el="plate" 
                    />
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              v-if="!loading && !form.licensePlateList.length"
              class="no-result"
            >
              <button
                class="button"
                @click.prevent="toggleAdd"
              >
                {{ $t('globals.layout.add.plates') }}
              </button>
            </div>
          </div>
        </fieldset>
      </form>

      <div
        v-show="loading"
        class="main-container-loader"
      >
        <loader />
      </div>

      <AddPlates @add="onAdd"/>
      <RemovePlates @remove="onRemove"/>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Select from '@/components/elements/NewSelect';
import Inputs from '@/components/elements/Inputs';
import Loader from '@/components/elements/Loader';
import TBody from '@/components/elements/TBody';
import Checkbox from '@/components/elements/Checkbox';
import AddPlates from '@/components/partials/Popups/AddPlates';
import RemovePlates from '@/components/partials/Popups/RemovePlates';
import moment from 'moment';

import hours from '@/data/hours.json';

export default {
  components: {
    Inputs,
    Loader,
    TBody,
    Checkbox,
    AddPlates,
    RemovePlates,
    Select
  },
  computed: {
    form() {
      const form = this.$store.state.subscription.form;
      return form;
    },
    loading() {
      return this.$store.state.subscription.requests !== 0;
    },
    days() {
      return [
        { label: this.$t('globals.days.1'), value: 1 },
        { label: this.$t('globals.days.2'), value: 2 },
        { label: this.$t('globals.days.3'), value: 3 },
        { label: this.$t('globals.days.4'), value: 4 },
        { label: this.$t('globals.days.5'), value: 5 },
        { label: this.$t('globals.days.6'), value: 6 },
        { label: this.$t('globals.days.7'), value: 0 },
      ]
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  data() {
    return {
      hoursList: [...hours],
      tbodyPlates: [
        // Names
        {
          title: plate =>  plate,
          class: 'nickname'
        },
        // Ticket
         { 
          title: plate => '',
          buttons: plate => {
            const buttons = [
              {
                label: this.$t('globals.remove'),
                id: plate,
                action: (plate) => {
                  this.remove(plate);
                }
              }
            ];

            return buttons;
          },
          class: 'align-right no-break'
        },
      ]
    }
  },
  methods: {
    onInput(event, type) {
      const target = event.target;
      this.$store.commit('subscription/updateFormData', { field: type, value: target.value })
    },
    onDay(value) {
      let days = [...this.form.weekDays];
      const index = days.indexOf(value);

      if (index < 0) days.push(value);
      else days.splice(index, 1);

      this.$store.commit('subscription/updateFormData', { field: 'weekDays', value: days });
    },
    onTime(value, type) {
      this.$store.commit('subscription/updateFormData', { field: type, value });
    },
    handleCheckbox(event) {
      if (event.target) return;
      this.$store.commit('subscription/updateFormData', { field: 'status', value: event.value })
    },
    toggleAdd() {
      this.$store.commit('popups/updatePopup', { type: 'addPlates', property: 'visible', value: true });
    },
    onAdd(e) {
      const value = e.replace(/\n|\;/g, ',').split(',').map(p => p.trim());
      this.$store.commit('subscription/addPlates', value);
      this.$store.commit('popups/updatePopup', { type: 'addPlates', property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { type: 'addPlates', property: 'value', value: '' });
      // await this.$store.dispatch('subscription/addPlates');
    },
    onRemove(e) {
      this.$store.commit('subscription/removePlates');
      this.$store.commit('popups/updatePopup', { type: 'removePlates', property: 'visible', value: false });
    },
    remove(plate) {
      this.$store.commit('subscription/removePlate', plate);
    },
    onSubmit() {
      this.$store.dispatch('subscription/updateSubscription');
    },
    getValue(type) {
      return this.form[type].replace(/:00$/, '');
    },
    getAvailableHours(type) {
      if (type === 'start') {
        const hours = this.hoursList
          .filter(h => {
            const hNumber = parseInt(h.replace(':', ''));
            const closeAtNumber = parseInt(this.form.endTimeAt.replace(/:00$/, '').replace(':', ''));
            return hNumber <= closeAtNumber;
          })
          .map(h => {
            return { value: h, label: h }
          });

        return hours;
      }

      if (type === 'end') {
        const hours = this.hoursList
          .filter(h => {
            const hNumber = parseInt(h.replace(':', ''));
            const openAtNumber = parseInt(this.form.startTimeAt.replace(/:00$/, '').replace(':', ''));
            return hNumber >= openAtNumber;
          })
          .map(h => {
            return { value: h, label: h }
          });

        return hours;
      }

      return [];
    },
  }
}
</script>


<style lang="scss" scoped>
.main-container-edit-subscription {
  padding-bottom: 0;
}

.container-row {
  .container-row {
    margin-top: 0;
  }

  + .container-row {
    margin-top: 24px;
  }
}

.form-days {
  display: flex;
  flex-wrap: wrap;

  > .label {
    width: 100%;
  }

  .input {
    + .input {
      margin-top: 0;
      margin-left: 24px;
    }
  }
}

.form-submit {
  .button {
    margin-bottom: 0;
    padding: 18px 30px 16px;
  }
}

.table-container {
  max-height: calc(100vh - 301px);
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 

  &::-webkit-scrollbar {
    display: none;
  }
}

.table {
  margin-bottom: 30px;

  td {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  td:first-child {
    border-left: 1px solid $lightestgrey;
  }

  td:last-child {
    border-right: 1px solid $lightestgrey;
  }
}

.no-result {
  display: flex;
  justify-content: center;

  .button {
    margin: 0;
    padding: 18px 30px 16px;
  }
}
</style>

<style lang="scss">
.main-container-edit-subscription {
  .table {
    th {
      padding-top: 0;
      padding-bottom: 6px;
    }
  }
}
</style>