<template>
  <div class="stats">
    <template v-if="!loading">
      <div
        v-for="(stat, index) in stats"
        :key="`stat-${index}`"
        :data-status="status(stat)"
        class="stat"
      >
        <span class="stat-title">{{ translations[`${stat.slot.translationKey}.name`] }}</span>
        <span class="stat-fraction">
          <span class="stat-number">{{ stat.occupancy }}</span> / {{ stat.otraSlotNumber }}
        </span>
      </div>
    </template>

    <div
      v-else
      class="stats-loader"
    >
      <loader />
    </div>
  </div>
</template>


<script>
import Loader from '@/components/elements/Loader';

export default ({
  components: {
    Loader
  },
  props: {
    stats: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translations() {
      return this.$store.state.translations.keys;
    }
  },
  methods: {
    status(stat) {
      if (stat.totalSlotNumber === 0) return 'success';

      const limits = {
        warning: Math.floor(stat.totalSlotNumber / 5),
        error: Math.floor(stat.totalSlotNumber / 10)
      };

      const diff = stat.totalSlotNumber - stat.occupancy;

      switch (true) {
        case diff <= limits.error:
          return 'error';
        case diff <= limits.warning:
          return 'warning';
        default:
          return 'success';
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.stats {
  display: flex;
  // padding: 16px 32px;
  // min-height: 74px;

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;

    &[data-status="warning"] {
      .stat-number {
        color: $warning;
      }
    }

    &[data-status="error"] {
      .stat-number {
        color: $error;
      }
    }

    &:not(:first-child) {
      margin-left: 30px;
    }

    &-title {
      color: $mediumgrey;
      font-family: $secondary;
      font-size: rem(16px);
      font-weight: $medium;
      line-height: 1.4;
    }

    &-fraction {
      color: $mediumgrey;
      font-family: $secondary;
      font-size: rem(20px);
      font-weight: $medium;
    } 

    &-number {
      color: $success-alternate;
    }
  }

  &-loader {
    .loader {
      width: 40px;
      height: 40px;
      min-height: 40px;
    }
  }
}
</style>
