<template>
  <div 
    class="grid-page"
  >
    <Header />
    <Sidebar/>
    <!-- <Sidebar v-if="hasSidebar"/> -->
    <main 
      class="main"
      :class="[className, {
        'grid--main': quickSearchComponent || addButton,
        'grid--main-nofilter': hasGrid,
      }]"
    >
      <section
        v-if="title"
        class="main-header"
      >
        <h2 class="h4">{{ $t(title) }}</h2>
      </section>

      <Filters 
        v-show="quickSearchComponent || addButton"
        :quickSearch="quickSearchComponent"
        :add="addButton"
        @onAdd="onAdd"
      />

      <router-view/>
    </main>
    <Footer />

    <!-- MODAL -->
    <Companies/>
    <Modal />
    <Notifications />
  </div>
</template>

<script>
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import Sidebar from '@/components/common/Sidebar';
import Filters from '@/components/partials/Header/Filters';
import Companies from '@/components/partials/Companies';

import Modal from '@/components/modules/Modal';
import Notifications from '@/components/common/Notifications';

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    Modal,
    Notifications,
    Sidebar,
    Filters,
    Companies
  },
  beforeMount() {
    this.getViewData();
  },
  computed: {
    className() {
      return `main-${this.$route.name ? this.$route.name.toLowerCase() : ''}`;
    },
    title() {
      return this.$route.meta.titleTemplate;
    },
    dateComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Date : null;
    },
    calendarComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Calendar : null;
    },
    quickSearchComponent() {
      return this.$route.meta.components ? this.$route.meta.components.QuickSearch : null;
    },
    addButton() {
      return this.$route.meta.components ? this.$route.meta.components.AddButton : null;
    },
    availableComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Available : null;
    },
    currentParking() {
      return this.$store.state.parkings.current;
    },
    hasGrid() {
      return this.$route.name === 'Accounting';
    },
    hasSidebar() {
      return this.$route.meta.components ? this.$route.meta.components.Sidebar : null;
    },
    selectedParking() {
      return this.$store.state.parkings.selected;
    }
  },
  watch: {
    selectedParking(newVal, oldVal) {
      this.getViewData(true);
    },
     $route() {
      this.getViewData(true);
    },
  },
  methods: {
    getViewData(forced = false) {
      const { name } = this.$route;
      if (!this.selectedParking) return;

      switch (name) {
        case 'Accounting':
          // Accounting
          if (forced || this.$store.state.accounting.requests === 0) this.$store.dispatch('accounting/getReporting');
          break;
        default:
          // Users
          if (forced || this.$store.state.accounting.requests === 0) this.$store.dispatch('users/getUsers');
          break;
      }
    },
    onPagination(pageNum) {
      this.$store.commit('accounting/updatePage', pageNum - 1);
    },
    onAdd() {
      this.$store.commit('users/updatePopup', {
        popup: 'add', property: 'visible', value: true
      });
    }
  }
}
</script>

<style lang="scss">
  /* SCSS */
</style>
