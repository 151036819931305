<template>
  <div class="hours">
    <div
      v-for="day in days"
      :key="`day-${day.label}`"
      class="hours-item"
    >
      <span class="hours-item-label">{{ day.label }}:</span>
      <span v-if="day.open && day.open24" class="hours-item-value">{{ $t('components.hours.label.open-24') }}</span>
      <span v-else-if="day.open && day.value" class="hours-item-value">{{ day.value }}</span>
      <span v-else class="hours-item-value">{{ $t('globals.closed') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    days: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.hours {
  width: 100%;
  max-width: 280px;

  &-item {
    display: flex;
    justify-content: space-between;
    color: $snap-primary;
    font-size: rem(18px);
    font-family: $secondary;
    font-weight: 400;
    line-height: 1.5;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}
</style>