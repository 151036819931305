<template>
  <!-- Popup Checkout -->
  <popup
    v-if="popup.visible"
    :classes="'popup-checkout'"
  >
    <template v-slot:content>
      <!-- Success -->
      <template v-if="popup.success">
        <h2 class="popup-title">{{ $t('globals.success') }}</h2>
        <div class="popup-main">
          <img class="popup-main-img" src="/img/success.png"/>
          <p class="popup-main-text">{{ $t('components.integrated.popups.checkin.success') }}</p>
        </div>

        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup"
            @click.prevent="close('checkout')"
          >
            {{ $t('globals.ok') }}
          </button>
        </div>
      </template>

      <!-- Infos -->
      <template v-else-if="popup.booking">
        <div class="popup-header">
          <h2 class="popup-title h4">{{ $t('components.integrated.popups.checkin.title') }}</h2>
          <p class="popup-main-text">{{ $t('components.integrated.popups.checkin.text') }}</p>
        </div>

        <form class="popup-form">
          <Calendar 
            :value="popup.fields.date"
            :label="$t('components.integrated.popups.checkin.labels.date')"
            @close="toggleCalendar"
            @onChange="updateDate"
          />

          <Time
            :label="$t('components.integrated.popups.checkin.labels.time')"
            :value="popup.fields.time"
            @onChange="updateTime"
          />

          <div 
            v-if="popup.error"
            class="popup-form-error"
          >
            {{ popup.error }}
          </div>
        </form>

        <div class="popup-actions">
          <button
            class="button button-ghost"
            @click="close()"
          >
            {{ $t('globals.cancel') }}
          </button>
          <button
            class="button"
            @click="checkin"
            :disabled="popup.error"
          >
            {{ $t('globals.confirm') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import moment from 'moment';
import Popup from '@/components/partials/Popup';
import Time from '@/components/elements/Time';
import Loader from '@/components/elements/Loader';
import Calendar from '@/components/elements/Calendar';

export default {
  components: {
    Time,
    Calendar,
    Loader,
    Popup,
  },
  data() {
    return {
      type: 'checkin',
      calendarVisibility: true,
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.checkin;
    }
  },
  methods: {
    // ON
    toggleCalendar() {
      this.calendarVisibility = !this.calendarVisibility;
    },
    close() {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'success', value: false });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'booking', value: null });
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: null });
    },
    checkin(booking) {
      const request = this.$store.dispatch('popups/setCheckin');
      if (!request.hasError) this.$emit('onSuccess');
    },
    updateDate(date) {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: null });
      const formatedDate = moment(date).format('YYYY[-]MM[-]DD')
      const isBefore = this.validCheckinDate(formatedDate, this.popup.fields.time);

      this.$store.commit('popups/updatePopupField', { type: this.type, field: 'date', value: formatedDate });

      if (isBefore) {
        this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: this.$t('components.integrated.popups.checkin.errors.date-before') });
        return;
      }
    },
    updateTime(time) {
      this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: null });
      const isBefore = this.validCheckinDate(this.popup.fields.date, time);
      this.$store.commit('popups/updatePopupField', { type: this.type, field: 'time', value: time });

      if (isBefore) {
        this.$store.commit('popups/updatePopup', { type: this.type, property: 'error', value: this.$t('components.integrated.popups.checkin.errors.date-before') });
        return;
      }
    },
    validCheckinDate(date, time) {
      const tzId = this.$store.state.parkings.current.timeZoneId;
      const checkinDate = moment(`${date} ${time}`).tz(tzId);
      const { startAt } = this.popup.booking;
      const match = startAt.match(/(\+|\-)[0-9]{2}:[0-9]{2}$/g);
      const dateString = match.length > 0 ? startAt.replace(match[0], '') : startAt;
      const endDate = moment(dateString).tz(tzId);
      const isBefore = checkinDate.isBefore(endDate);
      return isBefore;
    }
  }
}
</script>