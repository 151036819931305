<template>
    <LayoutPage v-if="authState === 'signedin' && user && $route.meta.layout === 'page'"/>
    <Layout v-else-if="authState === 'signedin' && user"/>
    <LayoutPageNotLogged v-else-if="$route.meta.layout === 'page'"/>
    <Authentication v-else />
</template>

<script>
import axios from 'axios';
import { Auth, AuthState } from 'aws-amplify';
import Layout from '@/views/Layout.vue';
import LayoutPage from '@/views/Layout-page.vue';
import LayoutPageNotLogged from '@/views/Layout-page-not-logged.vue';
import Authentication from '@/views/Authentication.vue';

export default {
  name: 'AuthStateApp',
  components: {
    Layout,
    LayoutPage,
    LayoutPageNotLogged,
    Authentication,
  },
  mounted() {
    const locale = window.localStorage.getItem('otra-locale');
    if (locale && locale !== 'undefined' && locale !== this.$i18n.locale) {
      this.$i18n.locale = locale;
      axios.defaults.headers['Accept-Language'] = locale;
    }
  },
  computed: {
    authState() {
      return this.$store.state.authentication.authState;
    },
    user() {
      return this.$store.state.authentication.tokens;
    }
  },
  beforeMount() {
    this.$store.dispatch('authCheck');
  }
}
</script>

<style lang="scss">
@import '@/scss/app.scss';
</style>
