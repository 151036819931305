import axios from 'axios';
import { accounting } from '@/formaters/accounting';

const getServicesList = async (type) => {
  // return await axios.get(`/parking-managers`)
  return await axios.get(`/pmng/cleaning-services/select/${type ? type : ''}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

const getService = async (id) => {
  // return await axios.get(`/parking-managers`)
  return await axios.get(`/pmng/cleaning-services/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
}

const getReservations = async (params) => {
  return await axios.get(`/pmng/cleaning-service-bookings`, { params })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const getReservation = async (id) => {
  return await axios.get(`/pmng/cleaning-service-bookings/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log('error :', error);
    return {
      hasError: true,
      error
    }
  });
};

const getReporting = async () => {
  try {
    const request = await axios.get('/pmng/cleaning-service-bookings/company-balance');
    const data = request.data.map(d => {
      d.name = d.serviceName;
      d.id = d.serviceId;
      return d;
    });
    return accounting.from(data);
    // return accounting.from(request.data);
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

export {
  getServicesList,
  getReservation,
  getReservations,
  getService,
  getReporting
}