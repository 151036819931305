import { formContainer } from '@aws-amplify/ui';
import axios from 'axios';

const getParking = (id) => {
  return axios.get(`/pmng/parkings/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    }
  });
};

const getParkings = () => {
  return axios.get('/pmng/parkings')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const getParkingsSelect = () => {
  return axios.get(`/pmng/parkings/select`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const getFilters = () => {
  return axios.get('/pmng/parkings/filters')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const getSlots = () => {
  return axios.get('/pmng/slots')
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const getSlotsByParkingId = (parkingId) => {
  return axios.get(`/pmng/slots/${parkingId}/slot`)
  .then((promise) => {
    if(promise.status === 200){
      return promise.data
    } 
  }).catch(error => {
    console.log(error);
  });
};

const patchSlots = (id, item) => {
  return axios.patch(`/pmng/parkings/${id}/slot`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const patchPrices = (id, item) => {
  return axios.patch(`/pmng/parkings/${id}/package`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const patchParking = (id, item) => {
  return axios.patch(`/pmng/parkings/${id}`, item)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);

    return {
      hasError: true,
      error
    }
  });
};

const getCommodities = (id) => {
  return axios.get(`/pmng/parkings/${id}/commodities`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const patchCommodities = (id, datas) => {
  return axios.patch(`/pmng/parkings/${id}/commodities`, datas)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const setParkingPictures = async (files) => {
  files.map(file => {
    const form = new FormData();
    formContainer.append('file', file);
  });

  try {
    const request = await axios.post('/pmng/parkings/medias', {
      media: files
    });
    return 'success';
  } catch (error) {
    return 'error';
  }
};

const getMedias = async (id) => {
  try {
    const request = await axios.get(`/pmng/parkings/${id}/medias`);
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const uploadParkingMedia = async (id, file) => {
  const form = new FormData();
  form.append('file', file.file);

  try {
    const request = await axios.post(`/pmng/parkings/${id}/media`, form);
    return request.data.id;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
}

const saveParkingMedias = async (id, ids) => {
  try {
    const request = await axios.post(`/pmng/parkings/${id}/media/order`, { ids });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const deleteParkingMedia = async (parkingId, fileId) => {
  try {
    const request = await axios.delete(`/pmng/parkings/${parkingId}/media/${fileId}/delete`);
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const getStats = async (parkingId, date) => {
  try {
    const request = await axios.get(`/pmng/parkings/${parkingId}/slots`, {
      // const request = await axios.get(`/parkings/${parkingId}/occupancy`, {
      params: {
        CurrentDate: date
      }
    });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

export {
  getParking,
  getParkings,
  getParkingsSelect,
  getFilters,
  getSlots,
  getSlotsByParkingId,
  getCommodities,
  patchSlots,
  patchPrices,
  patchParking,
  patchCommodities,
  setParkingPictures,
  uploadParkingMedia,
  saveParkingMedias,
  deleteParkingMedia,
  getMedias,
  getStats
}
