import axios from 'axios';


const getLicensePlates = async (params) => {
 
  try {
    const request = await axios.get(`/pmng/fleets/license-plate-checker`, { params });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};


export {
    getLicensePlates
};
