/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobiledev = {
  "aws_project_region": "eu-central-1",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_kBPiAQiqT",
  "aws_user_pools_web_client_id": "4q71qrb2q8lo0k0uia5pmfvdqk",
  "oauth": {
    "domain": "otra-stage.auth.eu-central-1.amazoncognito.com"
  }
};

const awsmobilepaint = {
  "aws_project_region": "eu-central-1",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_6IpZ1v0qJ",
  "aws_user_pools_web_client_id": "36g2g9e7ggqgq18td5m73g9tij",
  "oauth": {
      "domain": "otra-web-paint.auth.eu-central-1.amazoncognito.com"
  }
};

const awsmobileprod = {
  "aws_project_region": "eu-central-1",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_MSUWGO2JO",
  "aws_user_pools_web_client_id": "6gblv4prccidpmge347fqclm7r",
  "oauth": {
    "domain": "auth.weareotra.com"
  }
};

let awsmobile = location.origin.match('weareotra') ? awsmobileprod : awsmobiledev;
if (location.origin.match('otra-paint')) awsmobile = awsmobilepaint;
export default awsmobile;
