<template>
  <div>
    <button>
      <time @click="onClick" class="h4" datetime="{{today}}">
        {{ today }}
        <Sprite type="arrow" :width="15" :height="10"/>
      </time>
    </button>

    <!-- Calendar -->
    <Calendar 
      @change="handleDates"
      @close="onClick"
      :isVisible="calendarVisibility"
    />
  </div>
</template>

<script>
import moment from 'moment';

import Sprite from '@/components/elements/Sprite.vue';
import Calendar from '@/components/modules/Calendar.vue';

export default {
  name: 'DateCalendar',
  components: {
    Sprite,
    Calendar,
  },
  computed: {
    filtersStored() {
      const { name } = this.$route;
      if (name === 'Home') return this.$store.state.dashboard.filters;
      return this.$store.state.bookings.filters;
    }
  },
  data() {
    return {
      today: moment().format("DD[.]MM[.]YYYY"),
      calendarVisibility: false,
      filters: {}
    }
  },
  watch: {
    $route() {
      this.today = moment().format("DD[.]MM[.]YYYY");
    }
  },
  mounted() {
    this.filters = this.filtersStored;
  },
  methods: {
    // ON
    onClick() {
      this.calendarVisibility = !this.calendarVisibility
    },
    handleDates(dates, end = '') {
      this.filters.dates = dates;

      const start = moment(dates.start).format("DD[.]MM[.]YYYY");
      if(dates.end && dates.start != dates.end) end = `${moment(dates.end).format("DD[.]MM[.]YYYY")}`;
      this.today = start + `${end ? ' - ' + end : ''}`;

      const { name } = this.$route;
      const { selected } = this.$store.state.parkings;

      if (name === 'Home') {
        this.$store.commit('dashboard/updateFilters', {
          property: 'dates',
          value: {
            start: moment(dates.start).format("YYYY[-]MM[-]DD[T]00:00:00"),
            end: end ? moment(dates.end).format("YYYY[-]MM[-]DD[T]23:59:59") : moment(dates.start).format("YYYY[-]MM[-]DD[T]23:59:59")
          }
        });
        this.$store.dispatch('dashboard/getData', { ParkingId: selected });
        return;
      }

      if (name.match(/TCReservations|TWReservations/)) {
        const { namespace } = this.$route.meta;
        this.$store.commit(`${namespace}/updateFilters`, {
          property: 'dates',
          value: {
            start: moment(dates.start).format("YYYY[-]MM[-]DD[T]00:00:00"),
            end: end ? moment(dates.end).format("YYYY[-]MM[-]DD[T]23:59:59") : moment(dates.start).format("YYYY[-]MM[-]DD[T]23:59:59")
          }
        });
        this.$store.dispatch(`${namespace}/getReservations`);
        return;
      }

      this.$store.commit('bookings/updateDynamicFilters', this.filters);
      this.$store.dispatch('bookings/setBookingsSalesWithPagination', { ParkingId: selected });
    },
    // HANDLE
  }
}
</script>

<style lang="scss" scoped>
time {
  margin-bottom: 0;
}
</style>