<template>
  <popup
    v-if="popup.visible"
    :classes="'popup-noshow'"
  >
    <template v-slot:content>
      <template v-if="popup.success">
        <h2 class="popup-title">{{ $t('globals.success') }}</h2>
        <div class="popup-main">
          <img class="popup-main-img" src="/img/success.png"/>
          <p class="popup-main-text">{{ $t('components.stack.popups.noshow.success') }}</p>
        </div>

        <div class="popup-actions">
          <button
            type="button"
            class="button button-popup"
            @click.prevent="close('noshow')"
          >
            {{ $t('globals.ok') }}
          </button>
        </div>
      </template>
      <template v-else-if="popup.booking">
        <div class="popup-header">
          <h2 class="popup-title h4">{{ $t('components.stack.popups.noshow.title') }}</h2>
          <p class="popup-main-text">{{ $t('components.stack.popups.noshow.text') }}</p>
        </div>

        <h3 class="h4 popup-subtitle">{{ $t('components.stack.popups.noshow.subtitle') }}</h3>
        <p class="popup-main-text">{{ popup.booking.driverName }}, {{ plates(popup.booking) }}</p>

        <table class="popup-table">
          <tbody>
            <tr
              v-for="row in table"
              :key="`payPopup-${row.property}`"
            >
              <td>{{ row.label(popup.booking) }}</td>
              <td>{{ row.property(popup.booking) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('globals.total') }} :</td>
              <td>{{ popup.booking.priceDetails.packagePriceWithFee }}€</td>
            </tr>
          </tfoot>
        </table>

        <div class="popup-actions">
          <button
            class="button button-ghost"
            @click="close('noshow')"
          >
            {{ $t('globals.cancel') }}
          </button>
          <button
            class="button"
            @click="noshow"
          >
            {{ $t('globals.confirm') }}
          </button>
        </div>
      </template>

      <div
        v-show="popup.requests !== 0"
        class="popup-loader"
      >
        <loader/>
      </div>
    </template>
  </popup>
</template>

<script>
import Popup from '@/components/partials/Popup';
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Loader,
    Popup
  },
  data() {
    return {
      table: [
        {
          label: (booking) => this.$t('components.stack.popups.noshow.table.booking'),
          property: (booking) => `${booking.priceDetails.packagePriceWithoutFee}€`
        },
        {
          label: (booking) => this.$t('components.stack.popups.noshow.table.fee'),
          property: (booking) => `${booking.priceDetails.packageFee}€`
        }
      ]
    }
  },
  computed: {
    popup() {
      return this.$store.state.popups.noshow;
    }
  },
  methods: {
    plates(booking) {
      return booking.truckLicenseCountry.toUpperCase() + ' ' + booking.truckLicensePlate + `${booking.trailLicensePlate && booking.truckLicensePlate ? ' - ' : ''}` +  booking.trailLicenseCountry.toUpperCase() + ' ' + booking.trailLicensePlate
    },
    close(popup) {
      const type = 'noshow';
      this.$store.commit('popups/updatePopup', { type, property: 'visible', value: false });
      this.$store.commit('popups/updatePopup', { type, property: 'success', value: false });
      this.$store.commit('popups/updatePopup', { type, property: 'booking', value: null });
    },
    async noshow(booking) {
      const request = await this.$store.dispatch('popups/setNoshow');
      if (!request.hasError) this.$emit('onSuccess');
    }
  }
}
</script>