<template>
  <footer class="footer">
    <p class="small">{{ $t('components.footer.copyrights') }}</p>
    <nav class="footer-navigation">
      <ul class="footer-navigation__items">
        <li class="footer-navigation__item">
          <a
            class="small"
            target="_blank"
            :href="`/terms-and-conditions/${$i18n.locale}.pdf`"
            download
          >
            {{ $t('components.footer.terms') }}
          </a>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<style lang="scss">
  .footer-navigation {
    // &__items {}

    &__item {
      a {
        color: $white;
      }
    }
  }
</style>

<script>
export default {
  name: 'Footer'
}
</script>
