// import { useI18n } from 'vue-i18n';
import i18n from '../i18n';
import axios from 'axios';

import {
  getCompanies
} from '@/calls/companies';

import { getParams } from '@/helpers/helpers';

const companies = {
  namespaced: true,
  state: {
    items: [],
    current: null,
    selected: null,
    requests: 0,
    visible: false
  },
  mutations: {
    updateProperty(state, { property, value }) {
      state[property] = value;
    }
  },
  actions: {
    async getCompanies({ commit, state, dispatch }) {
      commit('updateProperty', { property: 'requests', value: state.requests + 1 });

      const { location } = window;
      const params = getParams(location.href) || {};
      if (!params.parking) {
        commit('companies/updateProperty', {
          property: 'visible',
          value: true
        }, { root: true });
      }

      const request = await getCompanies();

      if (!request.hasError) {
        commit('updateProperty', { property: 'items', value: request });

        if (params.parking) {
          let parking = null;
          let company = null;

          request.map(comp => {
            comp.parkings.map(park => {
              if (park.id === parseInt(params.parking, 10)) {
                parking = park;
                company = comp;
              }
            });
          });

          commit('updateProperty', { property: 'selected', value: company});
          commit('parkings/update', { property: 'subscribable', value: parking.isSubscribable }, { root: true });
          commit('parkings/update', { property: 'whitelistable', value: parking.isWhitelistable }, { root: true });
          commit('parkings/updateIsIntegrated', parking.isIntegrated, { root: true });
          commit('parkings/updateSelected', parking.id, { root: true });
          dispatch('applyCompany');
        }
      } else {
        const { t } = i18n.global;
        commit('updateProperty', { property: 'visible', value: false });
        dispatch('notifications/addNotifs', [{
          text: t('notifications.errors.parkings.companies', { message: request.error.message ? request.error.message : request.error }),
          type: 'error'
        }], { root: true });
      }

      commit('updateProperty', { property: 'requests', value: state.requests - 1 });
    },
    applyCompany({ state, commit, rootState }) {
      const { selected } = state;
      axios.defaults.headers['x-admin-company'] = selected.id;

      const parkings = selected.parkings.map(p => {
        return {
          active: true,
          integrated: p.isIntegrated,
          label: p.name,
          value: p.id,
          subscribable: p.isSubscribable,
          whitelistable: p.isWhitelistable
        }
      });

      commit('updateProperty', { property: 'current', value: selected });
      commit('user/updateCompany', selected, { root: true });

      if (parkings.length) {
        const bookingParking = rootState.booking.data?.package?.parking;
        const selectedParking = rootState.parkings.selected;
        let p = null

        if (selectedParking && parkings.filter(p => p.value === selectedParking).length) {
          const parking = parkings.filter(p => p.value === selectedParking);
          p = parking[0];
        } else if (bookingParking && bookingParking.id && parkings.filter(p => p.value === bookingParking.id).length) {
          p = bookingParking;
          p.value = p.id;
        } else {
          p = parkings[0]
        }

        commit('parkings/updateSelected', p.value, { root: true });
        commit('parkings/updateIsIntegrated', p.integrated, { root: true });
        commit('parkings/update', { property: 'subscribable', value: p.subscribable }, { root: true });
        commit('parkings/update', { property: 'whitelistable', value: p.whitelistable }, { root: true });
      }
    }
  }
};

export default companies;
