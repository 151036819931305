import { format } from 'libphonenumber-js';
import moment from 'moment';

const accounting = {
  from: (data) => {
    if (!data || !data.length) return {};

    const formated = {};
    const parkings = [];
    let firstMonth = null;

    data.map(item => {
      const month = parseInt(item.month, 10) < 10 ? `0${item.month}` : item.month;
      const property = `${item.year}-${month}`;

      if (!formated[property]) {
        // Set new month
        formated[property] = {
          id: property,
          label: item.month,
          bookings: 0,
          revenue: 0,
          open: true,
          parkings: [],
        }
      }

      // Stock beginning reporting's month
      if (!firstMonth || firstMonth > property) firstMonth = property;

      // Update value of the month
      formated[property].bookings += item.bookings;
      formated[property].revenue += item.revenue;
      formated[property].parkings.push(item);

      if (formated[property].parkings.length > 1) {
        formated[property].open = false;
      }

      // Stock the parking
      const stocked = parkings.filter(parking => parking.id === item.parkingId);
      if (!stocked.length) {
        parkings.push({
          id: item.parkingId,
          name: item.parkingName
        });
      }
    });

    // Check/add parking that have no activity
    parkings.map(parking => {
      for (const key in formated) {
        const month = formated[key];
        const park = month.parkings.filter(p => p.parkingId === parking.id);

        if (!park.length) {
          month.parkings.push({
            revenue: 0,
            bookings: 0,
            parkingName: parking.name,
            parkingId: parking.id,
            month: parseInt(month.id.split('-')[1], 10),
            year:  parseInt(month.id.split('-')[0], 10)
          });
        }
      }
    });

    // Sort each month parkings by name
    for (const key in formated) {
      formated[key].parkings.sort((a, b) => {
        if(a.parkingName < b.parkingName) return -1;
        if(a.parkingName > b.parkingName) return 1;
        return 0;
      });
    }

    // Fill all the month from starting month
    const firstDate = firstMonth.split('-');
    const startDate = moment().date(1).month(firstDate[1] - 1).year(firstDate[0]);
    const now = moment();
    const months = [];

    while (now > startDate || startDate.format('M') === now.format('M')) {
      months.push(startDate.format('YYYY-MM'));
      startDate.add(1, 'month');
    };

    for (let i = 0; i < months.length; i++) {
      const month = months[i];
      const splited = month.split('-');
      const number = parseInt(splited[1], 10);

      if (!formated[month]) {
        formated[month] = {
          id: month,
          bookings: 0,
          revenue: 0,
          open: false,
          parkings: [],
          label: number,
        }
      }
    }

    return formated;
  }
};

export {
  accounting
};
