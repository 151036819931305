<template>
   <section 
      class="main-container"
      :class="{ 'no-booking': reservations && reservations.length === 0 , 'main-container--loading': loading }"
    >
      <table
        v-if="reservations && reservations.length"
        class="table"
        cellspacing="0"
      >
         <!-- TABLE HEAD -->
        <Thead :head="theadR" />
        
        <!-- TABLE BODY -->
        <tbody>
          <tr 
            v-for="reservation in reservations"
            :key="reservation.id"
          > 
            <TBody 
              v-for="(r, index) in tbodyR"
              :key="index"
              :body="r" 
              :el="reservation" 
            />
          </tr>
        </tbody>
      </table>
      <!-- NO RESULTS -->
      <NoResult v-else >
        <slot>{{ $t('services.list.no-result') }}</slot>
      </NoResult>

      <div
        v-show="loading"
        class="main-container-loader"
      >
        <loader />
      </div>

      <Reservation />
    </section>
</template>

<script>
import NoResult from '@/components/partials/Bookings/NoResult';
import Thead from '@/components/elements/THead';
import TBody from '@/components/elements/TBody';
import Loader from '@/components/elements/Loader';
import Reservation from '@/components/partials/Popups/Reservation';

import {
  formatDate,
  utcTo
} from '@/helpers/helpers';

export default {
  components: {
    NoResult,
    Thead,
    TBody,
    Loader,
    Reservation
  },
  data() {
    return {
      tbodyR: [
         // Id
        {
          title: reservation => reservation.id, 
          class: 'id-col'
        },
        // Creation
        {
          title: reservation => {
            const d = utcTo(reservation.createdAt, this.timeZone);
            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
          },
        },
        // Driver
        {
          title: reservation => {
            if (!reservation?.description) return '-';
            const { driver } = reservation.description;
            return driver?.name ? driver.name : '-';
          },
          class: "nickname"
        },
        // Truck
        {
           title: reservation => {
            if (!reservation?.description) return '-';
            const { truck } = reservation.description;
            return truck?.licensePlate ? `${truck.licenseCountry ? truck.licenseCountry + ' ' : ''}${truck.licensePlate}` : '';
          },
        },
        // Package
        {
          title: reservation => reservation.tankCleaningBooking?.package?.label ? reservation.tankCleaningBooking.package.label : '-',
        },
        // Arrival
        {
          title: reservation => {
            const d = utcTo(reservation.startAt, this.timeZone);
            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
          },
        },
        {
          title: reservation => {
            const { status } = reservation;
            if (!status) return '-';
            return this.$t(`bookings.status.${status.toLowerCase()}`);
          },
        },
        // Actions
       { 
          title: (reservation) => `<span class="u-visually-hidden">${this.$t('globals.ticket')}</span>`,
          class: "icon width-36 vertical-center", 
          icon: { type: 'accounting', width: 23, height: 18 },
          action: (reservation) => {
            this.$store.commit('popups/updatePopup', { type: 'reservation', property: 'id', value: reservation.id });
            this.$store.dispatch('popups/getReservation');
            this.$store.commit('popups/updatePopup', { type: 'reservation', property: 'visible', value: true });
          }
        },
      ]
    }
  },
  computed: {
    reservations() {
      return this.$store.state.tc.reservations;
    },
    loading() {
      return this.$store.state.tc.requests.reservations !== 0;
    },
    timeZone() {
      if (!this.$store.state.parkings?.current?.timeZoneId) return 'Europe/Brussels';
      return this.$store.state.parkings.current.timeZoneId;
    },
    theadR() {
      return [
        { title: this.$t('services.list.heads.id'), class: "small head id-col", },
        { title: this.$t('services.list.heads.creation'), class: "small head" },
        { title: this.$t('services.list.heads.driver-name'), class: "small head" },
        { title: this.$t('services.list.heads.truck-plate'), class: "small head" },
        { title: this.$t('services.list.heads.program'), class: "small head" },
        { title: this.$t('services.list.heads.arrival'), class: "small head" },
        { title: this.$t('services.list.heads.status'), class: "small head" },
        {
          title: this.$t('home.thead.actions'),
          class: "head-hidden"
        },
      ]
    },
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  }
}
</script>