<template>
  <div 
    v-if="appLoaded"
    class="grid-dashboard"
    :class="{
      'grid--small': sidebar === false,
      'grid--nosubbar': this.$route.meta.parent !== 'parking'
    }"
  >
    <Header />
    <!-- <Sidebar /> -->
    <SidebarNew />
    <main :class="`main ${className}`">
      <!-- :class="[className, {
        'grid--main': quickSearchComponent || addComponent,
        'grid--main-stats': stats,
        'grid--main-nofilter': !quickSearchComponent && !addComponent,
        'grid--main-nofooter': !quickSearchComponent && !addComponent,
      }]" -->
      <Subbar v-if="$route.meta?.namespace?.match(/parking|tw|tc/) && !$route.name?.match(/Accounting|LicenseChecker|TWReporting|TCReporting/)" />
      <section class="main-header">
        <h2 v-if="title" class="h4">{{ $t(title) }}</h2>

        <!-- <Time v-if="timeComponent" /> -->
        <Date v-show="dateComponent" />
        <Available v-if="availableComponent" />
        
        <div
          v-if="calendarComponent || refreshComponent"
          class="main-header-right"
        >
          <DateCalendar v-show="calendarComponent" />
          <Refresh v-if="refreshComponent" />
        </div>
        <DownloadButton v-if="downloadComponent"
          :label="downloadSetting == null ? '' : downloadSetting.label"
          @click="downloadSetting == null ? null : downloadSetting.onClick($event)"  />
      </section>

      <Filters 
        v-show="quickSearchComponent || addComponent" 
        :quickSearch="quickSearchComponent"
        :add="addComponent"
        :remove="removeComponent"
        @onAdd="addClick"
        @onRemove="removeClick"
      />
        <!-- :stats="stats" -->

      <router-view/>
    </main>
    <Footer />

    <!-- MODAL -->
    <Notifications />

    <div 
      v-if="validContext() === false"
      class="ribbon"
    >
      {{ $t('globals.layout.warning') }}
    </div>

    <!-- Popups -->
    <Companies />
    <Pay @onSuccess="onPay"/>
    <Detail/>
    <Ticket/>
    <Instructions/>
    <Rules/>
    <Service/>
  </div>
  <div
    v-else
    class="app-loader"
  >
    <h1 class="app-loader-title h4">{{ $t('globals.layout.loader') }}</h1>
    <Loader/>
  </div>
</template>

<script>
import moment from 'moment';

import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import Sidebar from '@/components/common/Sidebar';
import SidebarNew from '@/components/common/SidebarNew';

// import Time from '@/components/partials/Header/Time';
import Date from '@/components/partials/Header/Date';
import Refresh from '@/components/partials/Header/Refresh';
import DateCalendar from '@/components/partials/Header/DateCalendar';
import Available from '@/components/partials/Header/Available';

import Loader from '@/components/elements/Loader';
import Filters from '@/components/partials/Header/Filters';

import Pay from '@/components/partials/Popups/Pay';
import Detail from '@/components/partials/Popups/Detail';
import Ticket from '@/components/partials/Popups/Ticket';
import Rules from '@/components/partials/Popups/Rules';
import Service from '@/components/partials/Popups/Service';
import Instructions from '@/components/partials/Popups/Instructions';

import Companies from '@/components/partials/Companies';
import Notifications from '@/components/common/Notifications';
import Subbar from '@/components/partials/Subbar.vue';

import DownloadButton from '@/components/elements/DownloadButton.vue';

import { getParams } from '@/helpers/helpers';

export default {
  name: 'Layout',
  components: {
    Subbar,
    Header, Footer, Loader, Date, DateCalendar, Available, Refresh,
    // Time,
    // Sidebar,
    SidebarNew,
    Filters,
    Notifications, Companies,
    Service,
    Pay, Detail, Ticket, Rules, Instructions,
    DownloadButton
},
  data() {
    return {
      hasValidContext: true,
      // Boolean to determine if user has access to a parking
      hasAccess: true,
    }
  },
  mounted() {
    this.getViewData();
  },
  computed: {
    sidebar() {
      return this.$store.state.user.sidebar;
    },
    className() {
      return `main-${this.$route.name ? this.$route.name.toLowerCase() : ''}`;
    },
    title() {
      return this.$route.meta.title;
    },
    refreshComponent() {
      return this.$route.name === 'Home';
    },
    dateComponent() {
      if(this.$route.name === 'Home' && !this.$store.state.parkings.isIntegrated) return null;
      return this.$route.meta.components ? this.$route.meta.components.Date : null;
    },
    calendarComponent() {
      if(this.$route.name === 'Home' && !this.$store.state.parkings.isIntegrated) return null;
      return this.$route.meta.components ? this.$route.meta.components.Calendar : null;
    },
    quickSearchComponent() {
      if(this.$route.name === 'Home' && !this.$store.state.parkings.isIntegrated) return null;
      return this.$route.meta.components ? this.$route.meta.components.QuickSearch : null;
    },
    availableComponent() {
      return this.$route.meta.components ? this.$route.meta.components.Available : null;
    },
    downloadComponent() {
      return this.$store.state.accounting.download != null;
    },
    addComponent() {
      // if(this.$route.name === 'Home' && !this.$store.state.parkings.isIntegrated) return 'globals.layout.add.instructions';
      if (this.$route.name === 'Whitelist') return 'globals.layout.add.plate';
      if (this.$route.name === 'Subscriptions') return 'globals.layout.add.subscription';
      if (this.$route.name === 'EditSubscription' || this.$route.name === 'CreateSubscription') return 'globals.layout.add.plates';
      return null;
    },
    removeComponent() {
      if (this.$route.name === 'EditSubscription') return 'globals.layout.remove.plates';
      return null;
    },
    // stats() {
    //   if(this.$route.name === 'Home' && !this.$store.state.parkings.isIntegrated) return null;
    //   if (!this.$route.meta.components || !this.$route.meta.components.Stats) return false;

    //   return this.$store.state.dashboard.stats;
    // },
    currentParking() {
      return this.$store.state.parkings.current;
    },
    selectedParking() {
      return this.$store.state.parkings.selected;
    },
    context() {
      return this.$store.state.settings.context;
    },
    appLoaded() {
      const { context } = this.$store.state.settings;
      return context;
    },
    downloadSetting() {
      var setting = this.$store.state.accounting.download;

      return setting ? setting : null;
    }
  },
  watch: {
    selectedParking(newVal, oldVal) {
      const redirection = this.handleRedirect(newVal);

      if (!redirection) {
        this.getViewData(true);
      }
    },
    $route(firstroute, secondroute) {
      this.$store.commit('bookings/updateDynamicFilters', {
        dates: {
          start: this.$route.name === 'Home' ? moment().startOf('hour').format() : null,
          end: this.$route.name === 'Home' ? moment().add(1, 'h').startOf('hour').format() : null,
        },
        search: null
      });

      this.$store.commit('bookings/updateCurrentBookingList', null);

      this.$store.commit('user/updatePagination', { per: 10, page: 1 });
      this.getViewData();
    },
    context() {
      this.validContext();
    },
    appLoaded(newVal, oldVal) {
      if (newVal && !oldVal) this.setSettings();
    },
    hasValidContext(value, oldValue) {
      if (value === false && oldValue === true) {
        this.$store.dispatch('notifications/addNotifs', [{
          text: 'You are using an account that does not correspond to this platform.',
          type: 'error',
          signout: true
        }], { root: true });
      }
    }
  },
  methods: {
    getViewData(forced = false) {

      if (!this.hasAccess) return;
      if (!this.selectedParking && this.$route.meta.namespace === 'parking') return;

      this.$store.dispatch('translations/getTranslations');

      const { name, params } = this.$route;

      switch (name) {
        // Parking
        // case 'updateParking':
        case 'SlotsAndPrices':
          this.$store.dispatch('parkings/setParking');
          break;
        case 'Sale':
          if (forced || this.$store.state.bookings.requests.sales === 0) {
            this.$store.dispatch('parkings/setParking');
            this.$store.dispatch('bookings/setSales', { ParkingId: this.selectedParking, dates: false });
          }
          break;
        case 'Booking':
          if (forced || this.$store.state.bookings.requests.bookings === 0) {
            this.$store.dispatch('parkings/setParking');
            this.$store.dispatch('bookings/setBookingsWithPagination', { ParkingId: this.selectedParking, dates: false });
          }
          break;
        case 'Users':
          if (forced || this.$store.state.users.requests === 0) {
            this.$store.dispatch('users/getUsers');
          }
        case 'Home':
          // Home
          if (forced || this.$store.state.bookings.requests.home === 0) {
            this.$store.dispatch('dashboard/getData', { ParkingId: this.selectedParking });
            this.$store.dispatch('parkings/setParking', this.selectedParking);
            // if (!this.$store.state.parkings.isIntegrated) {}
          }
          break;
        case 'Whitelist':
          if (forced || this.$store.state.plates.requests === 0) {
            this.$store.dispatch('plates/getPlates', { id: this.selectedParking });
          }
          break;
        case 'Subscriptions':
          if (forced || this.$store.state.subscriptions.requests === 0) {
            this.$store.dispatch('subscriptions/getSubscriptions', this.selectedParking);
          }
          break;
        case 'EditSubscription':
          if (forced || this.$store.state.subscription.requests === 0) {
            this.$store.commit('subscription/updateId', this.$route.params.id);
            this.$store.dispatch('subscription/getSubscription');
          }
          break;
        case 'Accounting':
          // Accounting
          if (forced || this.$store.state.accounting.requests === 0) this.$store.dispatch('accounting/getReporting');
          break;
        case 'LicenseChecker':
          // LicenseChecker        
          if (forced || this.$store.state.licenseChecker.requests === 0) this.$store.dispatch('licenseChecker/getLicensePlates');
          break;

        case 'TWReservations':
          // Accounting
          this.$store.commit('tw/updateSelected', params.serviceId);
          if (forced || this.$store.state.tw.requests.reservations === 0) this.$store.dispatch('tw/getReservations');
          break;
        case 'TCReservations':
          // Accounting
          this.$store.commit('tc/updateSelected', params.serviceId);
          if (forced || this.$store.state.tc.requests.reservations === 0) this.$store.dispatch('tc/getReservations');
          break;
        case 'TWReporting':
          // Accounting
          if (forced || this.$store.state.accounting.requests === 0) this.$store.dispatch('accounting/getServiceReporting', 1);
          break;
        case 'TCReporting':
          // Accounting
          if (forced || this.$store.state.accounting.requests === 0) this.$store.dispatch('accounting/getServiceReporting', 2);
          break;
        default:
          break;
      }
    },
    async setSettings() {
      if(this.selectedParking) return;
      this.$store.dispatch('settings/setCountries');
      this.$store.dispatch('settings/setSettings');

      this.$store.dispatch('parkings/setFilters');
      // this.$store.dispatch('bookings/setStatus');
      this.$store.dispatch('parkings/setSlots');

      const user = this.$store.dispatch('user/setProfile');

      const parkings = await this.$store.dispatch('parkings/setParkings');
      const services = await this.$store.dispatch('services/getServicesList');
      const errorText = this.$t('notifications.errors.global_access');

      const { context } = this.$store.state.settings;
      if (context && context.isAdmin) return;
      if (!context || !context.isParkingManager) {
        this.hasAccess = false;
        this.$store.dispatch('notifications/addNotifs', [{
          text: errorText,
          type: 'error'
        }], { root: true });
        return;
      }

      if (!this.$store.state.parkings.parkings.length) {
        // If no parking, check for services to redirect
        const { tc, tw } = this.$store.state.services;
        if (tc.length) {
          if (this.$route.meta.namespace === 'parking') {
            this.$router.push({ name: 'TCReservations', params: { serviceId: tc[0].value } });
          }
          return;
        }

        if (tw.length) {
          if (this.$route.meta.namespace === 'parking') {
            this.$router.push({ name: 'TWReservations', params: { serviceId: tw[0].value } });
          }
          return;
        }

        this.$store.dispatch('notifications/addNotifs', [{
          text: errorText,
          type: 'error'
        }], { root: true });
        return;
      }

      const current = this.$route.params.id ? this.$route.params.id : parkings[0].value;
      const parking = this.$store.state.parkings.parkings.filter(p => parseInt(p.value, 10) === parseInt(current))[0];
      this.$store.commit('parkings/updateSelected', current);
      this.$store.commit('parkings/updateIsIntegrated', parking.integrated);


      // parkings.then((promise) => {
      //   const { context } = this.$store.state.settings;
      //   if (context && context.isAdmin) return;
      //   if (context && context.isParkingManager) {
      //     if (!promise) {
      //       // if (!promise || !promise.length) {
      //       this.hasAccess = false;
      //       this.$store.dispatch('notifications/addNotifs', [{
      //         text: errorText,
      //         type: 'error'
      //       }], { root: true });

      //       return;
      //     }

      //     if (!promise.length) {
      //       if (this.$route.meta.namespace === 'parking') {
      //         this.$router.push({ name: 'TWReservations' });
      //         return;
      //       }
      //     }

      //     const current = this.$route.params.id ? this.$route.params.id : promise[0].value;
      //     const parking = this.$store.state.parkings.parkings.filter(p => parseInt(p.value, 10) === parseInt(current))[0];
      //     this.$store.commit('parkings/updateSelected', current);
      //     this.$store.commit('parkings/updateIsIntegrated', parking.integrated);
      //   }
      // })
    },
    addClick() {
      const { name } = this.$route;
      if (name === 'Home') {
        this.$store.dispatch('parkings/getInstructions');
        this.$store.commit('parkings/updatePopup', { type: 'instructions', property: 'visible', value: true});
      }

      if (name === 'Whitelist') {
        this.$store.commit('popups/updatePopup', { type: 'addPlate', property: 'visible', value: true});
      }

      if (name === 'EditSubscription' || name === 'CreateSubscription') {
        this.$store.commit('popups/updatePopup', { type: 'addPlates', property: 'visible', value: true});
      }

      if (this.$route.name === 'Subscriptions') {
        this.$router.push({ name: 'CreateSubscription' })
      }
    },
    removeClick() {
      const { name } = this.$route;
      if (name === 'EditSubscription') {
         this.$store.commit('popups/updatePopup', { type: 'removePlates', property: 'visible', value: true});
      }
    },
    validContext() {
      if(this.context) {
        this.hasValidContext = this.context.isAdmin || this.context.isParkingManager;
        return this.context.isAdmin || this.context.isParkingManager;
      }

      return null;
    },
    onPay() {
      const { isIntegrated } = this.$store.state.parkings;
      const type = isIntegrated ? 'Integrated' : 'Stack';
      this.$store.dispatch(`dashboard/set${type}`, { ParkingId: this.$store.state.parkings.selected });
    },
    handleRedirect(id) {
      if (!id) return;
      if (this.$route.meta?.namespace?.match(/tc|tw/)) return;

      const { query, name } = this.$route;
      if ((!query.parking || Number(query.parking) !== id)) {
        const { subscribable, whitelistable, isIntegrated } = this.$store.state.parkings;

        // Whitelist
        if (name.match(/Whitelist/) && (!whitelistable || !isIntegrated)) {
          this.$router.push({ name: 'Home', query: { ...query, parking: id } });
          return true;
        }

        // Subscriptions
        if (name.match(/Subscriptions/) && !subscribable) {
          this.$router.push({ name: 'Home', query: { ...query, parking: id } });
          return true;
        }

        if (name.match(/EditSubscription|CreateSubscription/)) {
          if (!subscribable) this.$router.push({ name: 'ParkingIndex'})
          else this.$router.push({ name: 'Subscriptions'})
          return true;
        }

        this.$router.push({ name, query: { ...query, parking: id } });
        return true;
      }

      return false;
    }
  }
}
</script>

<style lang="scss">
  /* SCSS */
  .app-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .app-loader-title {
      margin-bottom: 24px;
      margin-top: -24px;
      letter-spacing: 0;
    }
  }
</style>