import axios from 'axios';

const getBooking = (id) => {
  return axios.get(`/pmng/bookings/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
};

const getBookings = (params) => {
  return axios.get(`/pmng/bookings`, {
    params
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};
const getStatus = () => {
  return axios.get(`/pmng/bookings/status`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
  });
};

const payBooking = (booking) => {
  return axios.patch(`/pmng/bookings/${booking.id}/paid`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
};

const updateBooking = async (id, params) => {
  try {
    const request = await axios.patch(`/pmng/bookings/${id}`, params);
    return request.data;
  } catch (error) {
    console.log(error);
    return {
      hasError: true,
      error
    };
  }
};

const simulateBooking = async (id, params) => {
  try {
    const request = await axios.post(`/pmng/bookings/${id}/dryrun`, params);
    return request.data;
  } catch (error) {
    console.log(error);
    return {
      hasError: true,
      error
    };
  }
};


const setNoshow = (booking) => {
  return axios.patch(`/pmng/bookings/${booking.id}/noshow`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
};

const setCheckin = (booking, date) => {
  return axios.patch(`/pmng/bookings/${booking.id}/checkin`, { checkinDate: date })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
}

const setCheckout = (booking, date) => {
  return axios.patch(`/pmng/bookings/${booking.id}/checkout`, { checkoutDate: date })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
};

const setReject = (booking, params) => {
  return axios.patch(`/pmng/bookings/${booking.id}/reject`, { ...params })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
};

const getCheckoutBooking = (id, date) => {
  return axios.get(`/pmng/bookings/${id}/checkout`, {
    params: {
      checkoutDate: date
    }
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
};

const getStackBookings = (params) => {
  return axios.get(`/pmng/bookings/dashboard`, {
    params
  })
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    return {
      hasError: true,
      error
    }
  });
};

const getAvailablePackages = async (id, slotId) => {
  try {
    const request = await axios.get(`/pmng/bookings/${id}/packages`, {
      params: { slot: slotId }
    });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    }
  }
}

const removeBooking = async (id) => {
  return axios.delete(`/pmng/bookings/${id}`)
  .then((promise) => {
    if(promise.status === 200) return promise.data
  }).catch(error => {
    console.log(error);
    return {
      hasError: true,
      error
    };
  });
}


// Get Bookings for non intagrated parkings
// GET
// Param :
// order : createdAt / startAt / endAt / ID
// dir : asc / desc

// No show => si endAt est passée + n'est pas checkout => V
// Reload data when changing hour => V

// Griser booking checkedOut => X

// No booking on stack
// Slot_type => - ?
// Title

export {
  getBooking,
  getBookings,
  getStatus,
  payBooking,
  simulateBooking,
  updateBooking,
  setNoshow,
  setReject,
  setCheckin,
  setCheckout,
  getCheckoutBooking,
  getStackBookings,
  getAvailablePackages,
  removeBooking
}
