import axios from 'axios';

const getSubscriptions = async (id) => {
  try {
    const request = await axios.get('/pmng/subscriptions', {
      params: { parkingId: id }
    });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const getSubscription = async (id) => {
  try {
    const request = await axios.get(`/pmng/subscriptions/${id}`);
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const postSubscription = async (params) => {
  try {
    const request = await axios.post(`/pmng/subscriptions/`, { ...params });
    return request.data;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const patchSubscription = async (id, params) => {
  try {
    const request = await axios.patch(`/pmng/subscriptions/${id}`, { ...params });
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};

const removeSubscription = async (id) => {
  try {
    const request = await axios.delete(`/pmng/subscriptions/${id}`);
    return request;
  } catch (error) {
    return {
      hasError: true,
      error
    };
  }
};


export {
  getSubscriptions,
  getSubscription,
  postSubscription,
  patchSubscription,
  removeSubscription
};
