<template>
  <router-view  v-if="$route.name !== 'Booking' " />
  <!-- TABLE -->
  <section
    v-else
    ref="container"
    class="main-container"
    :class="{ 'no-booking': tmpBooking && tmpBooking.length === 0 , 'main-container--loading': loading }"
    @scroll="handleScrollEvent()"
  >
    <!-- :class="tmpBooking && tmpBooking.length === 0 ? 'no-booking' : ''" -->
    <table
      class="table" 
      cellspacing="0" 
      v-if="tmpBooking && tmpBooking.length"
    >
      <!-- TABLE HEAD -->
      <Thead :head="theadBooking" />
      
      <!-- TABLE BODY -->
      <tbody>
        <tr
          v-for="booking in tmpBooking"
          :key="booking.id"
        >
          <TBody 
            v-for="(b, index) in tbodyBooking"
            :key="index"
            :body="b" 
            :el="booking" 
          />
        </tr>
      </tbody>
    </table>
    <!-- NO RESULTS -->
    <NoResult v-else >
      <slot>{{message}}</slot>
    </NoResult>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import { formatDate } from '@/helpers/helpers';
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";

import NoResult from '@/components/partials/Bookings/NoResult.vue';
import Thead from '@/components/elements/THead.vue';
import TBody from '@/components/elements/TBody.vue';
import Loader from '@/components/elements/Loader';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'List',
  components: {
    NoResult,
    Thead,
    TBody,
    Loader
  },
  data() {
    return {
      tmpBooking: [],
      currentList: [],
      isTableVisible: false,
      isScrolling: 0,
      tbodyBooking: [
        // Names
        {
          title: (booking) =>  this.getDriverName(booking),
          class: 'nickname'
        },
        // Arrival
        {
          title: (booking) => {            
            if (booking.checkinAt) {
              const d = formatDate(booking.checkinAt, this.timeZone);
                return `
                  ${d.date}
                  <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
                `
            }
            
            const d = formatDate(booking.startAt, this.timeZone);

            return `
              ${d.date}
              <span class="legend small">${d.hoursDigits}${this.$t(`globals.${d.hoursAmPm}`)}</span>
            `
              // <span class="legend small">${d.hours}</span>
            // this.dateFormat(booking.startAt)
          }
        },
        // Stay
        { 
          title: (booking) => `${booking.package.duration}${this.$t('globals.hours_stay')}`
        },
        // Price
        { 
          title: (booking) => {
            const value = booking.packagePrice + booking.priceDetails.extraDurationPriceWithoutFee;
            const round = Math.round((value + Number.EPSILON) * 100) / 100;
            return `${round}€`;
          }
        },
        // Plate
        {
          title: (booking) => booking.truckLicenseCountry.toUpperCase() + ' ' + booking.truckLicensePlate + `${booking.trailLicensePlate && booking.truckLicensePlate ? ' - ' : ''}` +  booking.trailLicenseCountry.toUpperCase() + ' ' + booking.trailLicensePlate
        },
        // Phone
        { 
          title: (booking) => {
            return `<span class="u-visually-hidden">${this.$t('globals.call')} ${booking.driverName}</span>`;
          }, 
          class: "icon",
          action: (booking) => this.phoneClick(booking), 
          icon: { type: 'message', width: 28, height: 28 }
        },
        // Ticket
        { 
          title: () => {
            return `<span class="u-visually-hidden">${this.$t('globals.ticket')}</span>`;
          }, 
          class: "icon",
          icon: { type: 'accounting', width: 23, height: 18 },
          action: (booking) => this.ticketClick(booking)
        }
      ]
    }
  },
  computed: {
    timeZone() {
      if (!this.$store.state.parkings?.current?.timeZoneId) return 'Europe/Brussels';
      return this.$store.state.parkings.current.timeZoneId;
    },
    current() {
      return this.$store.state.parkings.current;
    },
    bookings() {
      return this.$store.state.bookings.bookings
    },
    title() {
      return this.$t(this.$route.meta.titleTemplate);
    },
    filters() {
      return this.$store.state.bookings.filters;
    },
    slots() {
      return this.$store.state.parkings.slots;
    },
    opt() {
      return this.$store.state.user.opt;
    },
    loading() {
      return this.$store.state.bookings.requests.bookings !== 0;
    },
    message() {
      return this.$t('bookings.no_result');
    },
    theadBooking() {
      return [
        { title: this.$t('bookings.list.thead.driver'), class: "small head" },
        { title: this.$t('bookings.list.thead.arrival'), class: "small head" },
        { title: this.$t('bookings.list.thead.duration'), class: "small head" },
        { title: this.$t('bookings.list.thead.revenue'), class: "small head" },
        { title: this.$t('bookings.list.thead.plates'), class: "small head" },
        { title: this.$t('bookings.list.thead.call'), class: "small small-col head-hidden" },
        { title: this.$t('bookings.list.thead.ticket'), class: "small small-col head-hidden" },
      ]
    }
  },
  watch: {
    bookings(arr) {
      if (!arr) {
        arr = [];
      }
      arr.forEach( b => b.slotValue = this.slots[b.package.slotId]);
      arr = arr.sort((a,b) => moment(a.startAt).format('YYYYMMDDHHmm') - moment(b.startAt).format('YYYYMMDDHHmm'))

      this.setTab(arr)
      return arr;
    },
    tmpBooking(arr) {
      return arr;
    },
    // currentList(arr) {
    //   return arr;
    // },
    opt() {
      this.handleView();
    },
    filters: {
      deep: true,
      handler(filters) {
        return;
      }
    },
    $route() {
      this.tmpBooking = this.bookings
      this.currentList = this.tmpBooking;
      this.setTab(this.bookings)
    },
    loading() {      
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  mounted() {
    this.tmpBooking = this.bookings
    this.currentList = this.tmpBooking;
    this.setTab(this.bookings)
  },
  beforeRouteUpdate (to, from, next) {
    this.tmpBooking = this.bookings
    // this.currentList = this.tmpBooking;
    next();
  },
  methods: {
    setTab(arr) {
      this.tmpBooking = arr;
      this.currentList = arr;
      this.$store.commit('bookings/updateCurrentBookingList', arr);
      // this.handleView();
      return arr;
    },
    updateListingView(){
      this.$store.dispatch('bookings/updateBookingDataForPagination', { ParkingId: this.$store.state.parkings.selected });
    },
    async handleLoading(){
      return this.$store.dispatch('bookings/triggerPageIsLoading');
    },
    handleView() {
      const from = (this.opt.page - 1) * this.opt.per;
      const to = this.opt.page * this.opt.per;
      if(this.tmpBooking) this.tmpBooking = [...this.currentList].slice(from, to);
    },
    phoneClick(booking) {
      this.$store.dispatch('popups/getPopupBooking', { popup: 'detail', booking });
      this.$store.commit('popups/updatePopup', {
        type: 'detail', property: 'visible', value: true
      });
    },
    ticketClick(booking) { 
      this.$store.dispatch('popups/getPopupBooking', { popup: 'ticket', booking });
      this.$store.commit('popups/updatePopup', {
        type: 'ticket', property: 'visible', value: true
      });
    },
    async handleScrollEvent(){
      const containerElement = document.querySelector('.main-container');
      if(containerElement != null){
        //This calc is used to load the data when there is roughly 10 elements left to scroll
        if(Math.abs(containerElement.scrollHeight - containerElement.scrollTop) <= containerElement.clientHeight * 3){          
          if(this.$store.state.bookings.isCurrentlyLoading === false){
            this.updateListingView();
          }
        }
        //This calc is used to check whether the data from API has been retrieved when it reaches the bottom of the table
        if(Math.abs(containerElement.clientHeight - (containerElement.scrollHeight - containerElement.scrollTop)) < 1) {
          const isPageLoading = await this.handleLoading();
          // Intended to be a failsafe just in case it is reaching the bottom and code is not calling the API to retrieve the data
          if(isPageLoading === false && this.$store.state.bookings.isAllContentLoaded === false){
            this.updateListingView();
          }
        }
      }
    },
    getDriverName(booking) {      
       
       if (booking?.fleetName){
         return booking?.fleetName
       }
       if (booking?.driverNameCognito && booking?.driverNameCognito !== 'n.a.'){
         return booking?.driverNameCognito
       }
       
       return this.$t('globals.unknow')

   },
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  .table {
    td {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
}
</style>
