<template>
  <section class="subbar">
      <!-- :options="parkings" -->
    <NewSelect
      v-show="selected"
      :options="parkings"
      :value="selected"
      :placeholder="'Choose a parking'"
      :name="'parking-select'"
      :hasSearch="true"
      :class="'select-input classic'"
      @onChange="onChange"
    />

      <!-- v-if="$route.name === 'Home'" -->
    <div class="subbar-actions">
      <template v-if="$route.meta.namespace === 'parking'">
        <button
          v-if="!$store.state.parkings.isIntegrated"
          class="button"
          @click="onInstructions"
        >
        {{$t('globals.layout.add.instructions')}}
        </button>

        <button
          class="button"
          @click="onRules"
        >
          {{$t('globals.layout.add.rules')}}
        </button>
      </template>

      <template v-if="$route.meta.namespace.match(/tw|tc/)">
        <button
          class="button"
          @click="onServiceDetail"
        >
          {{$t('globals.layout.add.detail')}}
        </button>
      </template>
    </div>
  </section>
</template>

<script>
import NewSelect from '@/components/elements/NewSelect.vue';
import moment from 'moment';

export default {
  name: 'Subbar',
  components: {
    // Select,
    NewSelect,
  },
  data() {
    return {
      value: ""
    }
  },
  computed: {
    parkings() {
      const { namespace } = this.$route.meta;
      if (namespace.match(/tc|tw/)) {
        const services = this.$store.state.services[namespace];
        return services;
      }

      if (!this.$store.state.parkings.parkings || !this.$store.state.parkings.parkings.length) return [];
      const { context } = this.$store.state.settings;

      let parkings = null;
      if (context && context.isAdmin) {
        if (!this.$store.state.user.company) return [];
        parkings = [...this.$store.state.user.company.parkings.map(c => {
          return {
            label: c.name,
            value: c.id,
            integrated: c.isIntegrated,
            subscribable: c.isSubscribable,
            whitelistable: c.isWhitelistable,
          }
        })];
      } else {
        parkings = [...this.$store.state.parkings.parkings.map(c => {
          return {
            label: c.label,
            value: c.id || c.value,
            integrated: c.integrated,
            subscribable: c.subscribable,
            whitelistable: c.whitelistable,
          }
        })];
      }

      parkings = parkings.sort((a, b) => {
        if(a.label < b.label) return -1;
        if(a.label > b.label) return 1;
        return 0;
      });

     

      return parkings;
    },
    current() {
      return this.$store.state.parkings.current;
    },
    selected() {
      const { namespace } = this.$route.meta;
      let selected = namespace.match(/tc|tw/) ? this.$store.state[namespace].selected : this.$store.state.parkings.selected;
      
      return selected;
    }
  },
  watch: {
    selected(newValue, oldValue) {
      const parking = this.parkings.filter(p => parseInt(p.value, 10) === parseInt(newValue))[0];
      if (parking)
      {
        this.$store.commit('parkings/updateIsIntegrated', parking.integrated);
        this.$store.commit('parkings/update', { property: 'subscribable', value: parking.subscribable });
        this.$store.commit('parkings/update', { property: 'whitelistable', value: parking.whitelistable });

        // Whitelist
        if (this.$route.name.match(/Whitelist/) && !parking.whitelistable) {
          this.$router.push({ name: 'Home', query: { parking: parking.id } });
          return true;
        }

        // Subscriptions
        if (this.$route.name.match(/Subscriptions/) && !parking.subscribable) {
          this.$router.push({ name: 'Home', query: { parking: parking.id } });
          return true;
        }
      }
     
    },
  },
  methods: {
    onChange(value) {
      const { namespace } = this.$route.meta;

      if (namespace.match(/tw|tc/)) {
        // this.$store.
        this.$router.push({ name: this.$route.name, params: { serviceId: value } });
        return;
      }

      this.$store.commit('parkings/updateSelected', value);

      this.$store.commit('dashboard/updateFilters', {
          property: 'dates',
          value: {
            start: moment().format("YYYY[-]MM[-]DD[T]00:00:00"),
            end:  moment().format("YYYY[-]MM[-]DD[T]23:59:59")
          }
        });

      // if (this.$route.name.match(/EditSubscription|CreateSubscription/)) {
      //   if (!parking.subscribable) {
      //     this.$router.push({ name: 'ParkingIndex'})
      //   } else {
      //     this.$router.push({ name: 'Subscriptionsn'})
      //   }
      //   return;
      // }

      // this.$router.push({ name: this.$route.name, params: { id: value } })
    },
    onInstructions() {
      this.$store.dispatch('parkings/getInstructions');
      this.$store.commit('parkings/updatePopup', { type: 'instructions', property: 'visible', value: true});
    },
    onRules() {
      this.$store.dispatch('parkings/getRules');
      this.$store.commit('parkings/updatePopup', { type: 'rules', property: 'visible', value: true});
    },
    onServiceDetail() {
      this.$store.commit('popups/updatePopup', { type: 'service', property: 'id', value: this.$store.state[this.$route.meta.namespace].selected});
      this.$store.dispatch('popups/getService');
      this.$store.commit('popups/updatePopup', { type: 'service', property: 'visible', value: true});
    }
  }
}
</script>

<style lang="scss" scoped>
.subbar {
  position: relative;
  z-index: 10;
  width: 100%;
  // min-height: 78px;
  padding: 0 22px;
  border-bottom: 1px solid $lightestgrey;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .select {
    width: auto;
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;

    .button {
      width: auto;
      margin: 0 0 0 16px;
    }
  }
}
</style>

<style lang="scss">
.subbar {
  .select.classic {
    min-width: 300px;

    .custom-select {
      padding: 18px 16px 16px;
      border-color: $white;

      .selected,
      .custom-select-search {
        font-size: rem(24px);
      }
    }


    &.select--open {
      .custom-select {
        border-color: $lightestgrey;
        border-bottom: 0;

        .selected {
          .selected-text {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>