<template>
  <div class="methods">
    <p class="label">{{ $t('bookings.update.duration.payment_methods.title') }}</p>
    <div class="methods-list">
      <button
        v-for="method in methods"
        :key="`${method.value}`"
        :class="['method', { 'selected': method.value === selected}]"
        :disabled="method.disabled"
        type="button"
        @click.prevent="onClick(method)"
      >
        <span class="method-round"></span>
        <span class="method-text">
          {{ method.label }}
          <small v-if="method.sublabel">{{ method.sublabel }}</small>
        </span>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    methods: {
      type: Array,
      default: () => []
    },
    selected: {
      type: [Number, String]
    }
  },
  methods: {
    onClick(method) {
      this.$emit('onChange', method);
    }
  }
}
</script>

<style lang="scss" scoped>
.methods {
  &-list {
    display: flex;
  }
}

.method {
  display: flex;
  width: 323px;
  padding: 35px 30px;
  border: 1px solid $lightestgrey;
  border-radius: 11px;
  transition: background-color .3s $ease-out-quart;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    background-color: rgba($snap-primary, .03);
  }

  &:not(:first-child) {
    margin-left: 16px;
  }

  &[disabled] {
    pointer-events: none;

    .method-round {
      border-color: $mediumgrey;
    }

    .method-text {
      color: $mediumgrey;
    }
  }

  &.selected:not([disabled]) {
    .method-round {
      &::after {
        content: '';
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $mediumgreen;
      }
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: $secondary;
    font-weight: 500;
    color: $snap-primary;
    font-size: rem(18px);
    line-height: 1.12;

    small {
      display: block;
      color: $black;
      font-size: rem(14px);
      line-height: 1;
      margin-top: 4px;
      text-align: left;
    }
  }

  &-round {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid $mediumgreen;
    margin-right: 20px;
  }
}
</style>