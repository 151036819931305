<template>
  <form
    class="authentification-form"
    action="#"
    @submit.prevent="signIn"
  >
    <h2 class="authentification__title authentification__titleForm">{{ $t('authentication.login.title') }}</h2>
    <fieldset
      v-if="errors.global"
      class="fieldset-input__message"
      :class="{'error': errors.global}"
    >
      <p class="error-msg">
        {{ errors.global }} 
        <router-link
          v-if="errors.link"
          class="error-msg-link"
          :to="{ name: errors.link.to, query: errors.link.query}"
        >
          {{ errors.link.label }}
        </router-link>
        <!-- <a class="error-msg-link" v-if="error.link" :href="error.link.url">{{error.link.label}}</a> -->
      </p>
    </fieldset>
    

    <!-- Username -->
    <Fieldset 
      :type="'email'" 
      :label="$t('labels.email')"
      :name="'email'"
      :required="true"
      :error="errors.email"
      @input="onInput"
    /> 

     <Fieldset 
      :type="'password'" 
      :label="$t('labels.password')"
      :name="'password'"
      :required="true"
      :error="errors.password"
      @input="onInput"
    /> 

    <a class="authentification-link" @click="reset">{{ $t('authentication.login.forgot') }}</a>

    <button
      type="submit"
      class="button button-big"
      @click.prevent="signIn"
    >
      {{ $t('authentication.login.submit') }}
    </button>

    <a
      href="https://www.weareotra.com"
      class="authentification-registration"
      target="_blank"
    >
      {{ $t('authentication.login.no_account') }}
    </a>

    <!-- <hr class="hr-h"> -->
    <div class="authentification-footer">
      <a
        :href="policies[$i18n.locale]"
        target="_blank"
        class="authentification-footer__link"
      >
        {{ $t('authentication.login.privacy') }}
      </a>
      <a
        class="authentification-footer__link"
        target="_blank"
        :href="`/terms-and-conditions/${$i18n.locale}.pdf`"
        download
      >
        {{ $t('authentication.login.terms') }}
      </a>
      <!-- <router-link class="authentification-footer__link" target="_blank" :to="'terms'">{{ $t('authentication.login.terms') }}</router-link> -->
    </div>
  </form>
</template>

<script>
import { Auth } from 'aws-amplify'
import Fieldset from '@/components/modules/Authentication/Fieldset.vue'

export default {
  name: 'Login',
  components: {
    Fieldset
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      error: {
        email: '',
        password: '',
        global: '',
      },
      policies: {
        en: 'https://www.weareotra.com/wp-content/uploads/privacy-policy-for-otra.pdf?en',
        es: 'https://www.weareotra.com/wp-content/uploads/privacy-policy-for-otra.pdf?es'
      }
    }
  },
  computed: {
    errors() {
      return this.$store.state.authentication.login.errors;
    }
  },
  methods: {
    onInput(evt) {
      const { target } = evt;
      this.form[target.name] = target.value;
    },
    async signIn() {
      const form = 'login';
      const { email, password } = this.form
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // Email
      this.$store.commit('updateErrors', {
        form, property: 'email', error: !email.trim() ? this.$t('notifications.errors.authentication.email_required') : ''
      });

      if(!this.errors.email) {
        this.$store.commit('updateErrors', {
          form, property: 'email', error: !regex.test(email) ? this.$t('notifications.errors.authentication.email_invalid') : ''
        });
      }

      // Password
      this.$store.commit('updateErrors', {
        form, property: 'password', error: !password ? this.$t('notifications.errors.authentication.wrong_password') : ''
      });

      // Global
      this.$store.commit('updateErrors', {
        form, property: 'global', error: ''
      });
      
      if(this.errors.email ||this.errors.password) return;
      this.$store.dispatch('signin', { email, password });
    },
    reset() {
      this.$store.commit('updateAuthState', 'lost-password');
      this.$router.push({ name: 'LostPassword' });
    },
  }
}
</script>

<style lang="scss">
.error-msg-link {
  text-decoration: underline;
  color: $error;
  transition: color .3s $ease-out-quad;
  &:hover {
    color: $snap-primary;
  }
}
</style>