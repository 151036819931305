<template>
  <div
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <table class="table" cellspacing="0" v-if="users && users.length">
      <THead :head="theadUsers" />
      <tbody>
        <tr
          v-for="user in users"
          :key="user.id"
        >
          <TBody 
            v-for="(b, index) in tbodyUsers"
            :key="index"
            :body="b" 
            :el="user" 
          />
        </tr>
      </tbody>
    </table>
    <NoResult v-else >
      <slot>{{ $t('users.no_result') }}</slot>
    </NoResult>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>

    <popup
      v-if="popups.update.visible"
      :classes="'popup-detail'"
    >
      <template v-slot:content>
        <template v-if="popups.update.success">
          <h2 class="popup-title">{{ $t('notifications.success.default') }}</h2>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('notifications.success.users.updated') }}</p>
            <button
              class="button button-big"
              @click="close('update')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="popup-title">{{ $t('users.popups.update.title') }}</h2>
          <form
            class="popup-form"
            novalidate
            @submit.prevent="update"
          >
            <inputs
              :type="'text'"
              :placeholder="$t('labels.first_name')"
              :label="$t('labels.first_name')"
              :value="popups.update.fields.firstname"
              :error="popups.update.errors.firstname"
              @input="onInput($event, 'firstname')"
            />

            <inputs
              :type="'text'"
              :placeholder="$t('labels.last_name')"
              :label="$t('labels.last_name')"
              :value="popups.update.fields.lastname"
              :error="popups.update.errors.lastname"
              @input="onInput($event, 'lastname')"
            />

            <inputs
              :type="'email'"
              :placeholder="`${$t('labels.email')}*`"
              :label="`${$t('labels.email')}*`"
              :value="popups.update.fields.email"
              :error="popups.update.errors.email"
              @input="onInput($event, 'email')"
            />

            <div class="popup-actions popup-actions--center">
              <button
                class="button button-border"
                type="button"
                @click="close('update')"
              >
                {{ $t('globals.close') }}
              </button>
              <button
                type="submit"
                class="button"
                :disabled="updateDisabled"
              >
                {{ $t('globals.update') }}
              </button>
            </div>
          </form>
        </template>

        <div
          v-show="popups.update.requests !== 0"
          class="popup-loader"
        >
          <loader/>
        </div>
      </template>
    </popup>

    <popup
      v-if="popups.revoke.visible"
      :classes="'popup-revoke'"
    >
      <template v-slot:content>
        <template v-if="popups.revoke.success">
          <h2 class="popup-title">{{ $t('notifications.success.default') }}</h2>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('notifications.success.users.revoked') }}</p>
            <button
              class="button button-big"
              @click="close('revoke')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="popup-title">{{ $t('users.popups.revoke.title') }}</h2>
          <div class="popup-main">
            <p class="popup-main-text">{{ $t('user.popups.revoke.text', {
                user: popups.revoke.user.user.firstname || popups.revoke.user.user.lastname ? `${popups.revoke.user.user.firstname} ${popups.revoke.user.user.lastname}` : 'this user'
            }) }}</p>
          </div>
          <div class="popup-actions popup-actions--center">
            <button
              class="button button-border"
              type="button"
              @click="close('revoke')"
            >
              {{ $t('globals.close') }}
            </button>
            <button
              type="button"
              class="button"
              @click="revoke"
            >
              {{ $t('globals.revoke') }}
            </button>
          </div>
        </template>
      </template>
    </popup>

    <popup
      v-if="popups.add.visible"
      :classes="'popup-detail'"
    >
      <template v-slot:content>
        <template v-if="popups.add.success">
          <h2 class="popup-title">{{ $t('notifications.success.default') }}</h2>
          <div class="popup-main">
            <img class="popup-main-img" src="/img/success.png"/>
            <p class="popup-main-text">{{ $t('notifications.success.users.add') }}</p>
            <button
              class="button button-big"
              @click="close('add')"
            >
              {{ $t('globals.ok_maj') }}
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="popup-title">{{ $t('users.popups.add.title') }}</h2>
          <form
            class="popup-form"
            novalidate
            @submit.prevent="add"
          >
            <inputs
              :type="'text'"
              :placeholder="`${$t('labels.first_name')}*`"
              :label="`${$t('labels.first_name')}*`"
              :value="popups.add.fields.firstname"
              :error="popups.add.errors.firstname"
              @input="onInput($event, 'firstname', 'add')"
            />

            <inputs
              :type="'text'"
              :placeholder="`${$t('labels.last_name')}*`"
              :label="`${$t('labels.last_name')}*`"
              :value="popups.add.fields.lastname"
              :error="popups.add.errors.lastname"
              @input="onInput($event, 'lastname', 'add')"
            />

            <inputs
              :type="'email'"
              :placeholder="`${$t('labels.email')}*`"
              :label="`${$t('labels.email')}*`"
              :value="popups.add.fields.email"
              :error="popups.add.errors.email"
              @input="onInput($event, 'email', 'add')"
            />

            <Select
              :label="`${$t('labels.language')}*`"
              :value="popups.add.fields.locale"
              :name="'companies'"
              :options="languages"
              :placeholder="language ? language : $t('labels.language')"
              :class="'select-input classic'"
              @onChange="onLocale($event)"
            />
            <!-- :error="popups.add.errors.locale" -->

            <div class="popup-actions popup-actions--center">
              <button
                class="button button-border"
                type="button"
                @click="close('add')"
              >
                {{ $t('globals.close') }}
              </button>
              <button
                type="submit"
                class="button"
                :disabled="addDisabled"
              >
                {{ $t('globals.add') }}
              </button>
            </div>
          </form>
        </template>

        <div
          v-show="popups.add.requests !== 0"
          class="popup-loader"
        >
          <loader/>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader';
import THead from '@/components/elements/THead';
import TBody from '@/components/elements/TBody';
import Inputs from '@/components/elements/Inputs';
import Select from '@/components/elements/NewSelect';
import Popup from '@/components/partials/Popup';
import NoResult from '@/components/partials/Bookings/NoResult.vue';

export default {
  components: {
    Loader,
    Inputs,
    THead,
    TBody,
    Popup,
    Select,
    NoResult
  },
  // beforeMount() {
  //   this.$store.dispatch('users/getUsers');
  // },
  data() {
    return {
      tbodyUsers: [
        { title: (user) => user?.user?.firstname ? user.user.firstname : '-' },
        { title: (user) => user?.user?.lastname ? user.user.lastname : '-' },
        { title: (user) => user?.user?.email ? user.user.email : '-' },
        {
          title: (user) => {
            if (!user?.user?.cognitoStatus) return '-';
            return this.$t(`users.status.${user.user.cognitoStatus.toLowerCase()}`);
          },
        // Revoke button
        // { 
        //   title: (user) => '',
        //   button: (user) => 'Revoke',
        //   class: "td-button",
        //   action: (user) => this.revokeUser(user), 
        // },
        },
        { 
          title: (user) => '',
          button: (user) => this.$t('globals.update'),
          class: "td-button", 
          action: (user) => this.editUser(user), 
        },
      ]
    }
  },
  computed: {
    users() {
      return this.$store.state.users.items;
    },
    popups() {
      return this.$store.state.users.popups;
    },
    updateDisabled() {
      const { fields, user } = this.$store.state.users.popups.update;
      return fields.firstname === user.user.firstname && fields.lastname === user.user.lastname && fields.email === user.user.email;
    },
    addDisabled() {
      const { fields } = this.$store.state.users.popups.add;
      return !fields.firstname || !fields.firstname.length || !fields.lastname || !fields.lastname.length  || !fields.email || !fields.email.length ;
    },
    loading() {
      return  this.$store.state.users.requests !== 0;
    },
    theadUsers() {
      return [
        { title: this.$t('labels.first_name'), class: "small head" },
        { title: this.$t('labels.last_name'), class: "small head" },
        { title: this.$t('labels.email'), class: "small head" },
        { title: this.$t('labels.status'), class: "small head" },
        { title: this.$t('labels.actions'), class: "small small-col head-hidden" },
      ]
    },
    language() {
      let { locale } = this.$store.state.users.popups.add.fields;

      if (!locale) locale = 'en';
      const language = this.$store.state.settings.languages.filter(l => l.code === locale);

      return language[0];
    },
    languages() {
      return this.$store.state.settings.languages.map(l => {
        return {
          value: l.code,
          label: l.name
        }
      });
    }
  },
  watch: {
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  },
  methods: {
    onInput(event, field, popup = 'update') {
      if (!event) return;
      const { value } = event?.target;
      if (value === undefined) return;

      this.$store.commit('users/updatePopupField', { popup, field, value });
    },
    onLocale(value) {
      this.$store.commit('users/updatePopupField', { popup: 'add', field: 'locale', value });
    },
    editUser(user) {
      this.$store.commit('users/updatePopup', {
        popup: 'update', property: 'user', value: user
      });
      this.$store.commit('users/updatePopupField', {
        popup: 'update', field: 'firstname', value: user.user.firstname
      });
      this.$store.commit('users/updatePopupField', {
        popup: 'update', field: 'lastname', value: user.user.lastname
      });
      this.$store.commit('users/updatePopupField', {
        popup: 'update', field: 'email', value: user.user.email
      });

      this.$store.commit('users/updatePopup', {
        popup: 'update', property: 'visible', value: true
      });
    },
    revokeUser(user) {
      this.$store.commit('users/updatePopup', {
        popup: 'revoke', property: 'user', value: user
      });
      this.$store.commit('users/updatePopup', {
        popup: 'revoke', property: 'visible', value: true
      });
    },
    update() {
      this.$store.dispatch('users/updateUser');
    },
    revoke() {
      this.$store.dispatch('users/revokeUser');
    },
    add() {
      this.$store.dispatch('users/addUser');
    },
    close(popup) {
      this.$store.commit('users/updatePopup', {
        popup, property: 'visible', value: false
      });

      this.$store.commit('users/updatePopup', {
        popup, property: 'success', value: false
      });

      if(popup === 'update' || popup === 'add') {
        this.$store.commit('users/resetErrors', popup);
        this.$store.commit('users/resetFields', popup);
      }
    }
  }
}
</script>