<template>
  <li class="question" >
    <h3 class="question-title">
      {{ question.title }}
      <Sprite :type="'chevron'"/>
    </h3>
    <div
      class="question-content"
      v-html="question.content"
    />
  </li>
</template>

<script>
import Sprite from '@/components/elements/Sprite'
export default {
  name: "Question",
  components: {
    Sprite
  },
  props: {
    question: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.question {
  &-content {
    a {
      color: $mediumgreen;
    }

    p + {
      * {
        margin-top: 10px;
      }
    }

    ul {
      + * {
        margin-top: 10px;
      }

      li {
        position: relative;
        padding-left: 15px;

        &::before {
          content: '';
          position: absolute;
          top: 7px;
          left: 0;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: $snap-primary;
        }
      }
    }
  }
}
</style>